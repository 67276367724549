//@ts-check
import React from 'react'
import Select from '../Select'

const Options = ['Madre', 'Padre', 'Familiar', 'Otro']

/** @param {Partial<import('../Select').SelectProps>} props */
function TutorSelect({
  name = 'role',
  label = 'Tutor',
  placeholder = 'Relación con el alumno',
  options = Options,
  ...rest
}) {
  return (
    <Select
      {...rest}
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      transformList
    />
  )
}
export default TutorSelect
