//@ts-check
import React from 'react'
import FeelingGoodColored from 'assets/svg/FeelingGoodColored'
import { H2, Paragraph } from 'components/typography'
import styles from './Video.module.css'

function ClassroomVideo({ learningVideo, isVisible }) {
  return (
    <div className={styles.blackboard} hidden={!isVisible}>
      <div className={styles.innerContainer}>
        {!learningVideo && (
          <div className={styles.step1}>
            <FeelingGoodColored className={styles.svg} />
            <H2>
              A veces es mejor verlo para <br /> comprenderlo
            </H2>
            <Paragraph type='body1Bold'>
              Todavía no te han compartido ningún video
            </Paragraph>
          </div>
        )}
        {learningVideo && (
          <iframe
            title='Video de YouTube'
            className={styles.video}
            src={learningVideo + '?rel=0'}
            frameBorder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        )}
      </div>
    </div>
  )
}

export default ClassroomVideo
