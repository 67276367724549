//@ts-check
import React from 'react'
import { noop } from 'utils'
import styles from './TextArea.module.css'

function TextArea({
  className = '',
  name = 'description',
  placeholder = '',
  value = '',
  rows = 4,
  disabled = false,
  error = false,
  required = false,
  autoFocus = false,
  onChange = noop,
  onKeyDown = noop,
  onPaste = noop
}) {
  return (
    <textarea
      className={[
        styles.textarea,
        disabled ? styles.disabled : '',
        error ? styles.error : '',
        className
      ].join(' ')}
      name={name}
      placeholder={placeholder}
      value={value}
      rows={rows}
      disabled={disabled}
      required={required}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onPaste={onPaste}
      autoFocus={autoFocus}
    />
  )
}

export default TextArea
