import * as React from 'react'

function Info(props) {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z'
        stroke={props.color || 'var(--dark-grey-40)'}
        strokeWidth='2'
      />
      <path
        d='M11.0897 17V10.4545H12.905V17H11.0897ZM12.0016 9.6108C11.7317 9.6108 11.5002 9.52131 11.307 9.34233C11.1167 9.16051 11.0215 8.94318 11.0215 8.69034C11.0215 8.44034 11.1167 8.22585 11.307 8.04688C11.5002 7.86506 11.7317 7.77415 12.0016 7.77415C12.2715 7.77415 12.5016 7.86506 12.6919 8.04688C12.8851 8.22585 12.9817 8.44034 12.9817 8.69034C12.9817 8.94318 12.8851 9.16051 12.6919 9.34233C12.5016 9.52131 12.2715 9.6108 12.0016 9.6108Z'
        fill={props.color || 'var(--dark-grey-40)'}
      />
    </svg>
  )
}

export default Info
