import * as React from 'react'

const Pencil = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M18.189 1.811C16.92.543 14.61.79 13.039 2.36L2.533 12.866a.563.563 0 0 0-.146.255l-1.368 5.173a.562.562 0 0 0 .687.688l5.173-1.369a.561.561 0 0 0 .254-.146L17.64 6.961c1.571-1.571 1.817-3.881.549-5.15ZM3.225 14.352l2.423 2.423-3.294.872.871-3.294Zm14.632-10.15a3.433 3.433 0 0 1-1.012 1.964L6.735 16.274l-3.009-3.01L13.835 3.155a3.434 3.434 0 0 1 1.962-1.012 1.866 1.866 0 0 1 1.596.464c.396.43.567 1.02.464 1.596Z'
      fill={props.color || '#fff'}
      stroke={props.color || '#fff'}
    />
  </svg>
)

export default Pencil
