//@ts-check
import React, { useMemo } from 'react'
import { isEmpty } from 'ramda'
import { SubscriptionCodeLabels, SubscriptionCodes } from 'utils/constants'
import { H4, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import Vector from 'assets/icons/Vector'
import styles from './AgoraProposal.module.css'

/**
 * @typedef AgoraProposalProps
 * @property {Budget} budget
 * @property {React.MouseEventHandler<HTMLButtonElement>} onClick
 */

const buttonTextByStatus = {
  pending: 'Contratar',
  reviewing: 'Revisando',
  accepted: 'Ver detalles'
}
/** @param {Partial<AgoraProposalProps>} props */
function AgoraProposal({ budget, onClick }) {
  const formattedData = useMemo(() => {
    if (!budget || isEmpty(budget)) return {}
    const config = budget.config
    return {
      name: SubscriptionCodeLabels[config?.code] || '??',
      isPack: config?.code === SubscriptionCodes.PACK,
      subjectsLimit: budget.selectedSubjectIds?.length,
      hours: Number(config?.hours),
      price: budget.totalPrice + '€'
    }
  }, [budget])
  return (
    <div className={styles.card}>
      <H4 className={styles.title}>{formattedData.name}</H4>
      <div className={styles.textInfo}>
        <Paragraph type='body2'>
          {formattedData.subjectsLimit} asignaturas
        </Paragraph>
        <Paragraph type='body2'>{formattedData.hours} horas</Paragraph>
        {!formattedData.isPack && (
          <Paragraph type='body2'>Duración mensual</Paragraph>
        )}
      </div>
      <Vector className={styles.vector} color='var(--seadapted)' />
      <Paragraph className={styles.total}>{formattedData.price}</Paragraph>
      <Button
        label={buttonTextByStatus[budget.status]}
        size='small'
        onClick={onClick}
      />
    </div>
  )
}

export default AgoraProposal
