import React, { useState } from 'react'
import { noop } from 'utils'
import EyeOpenIcon from 'assets/icons/EyeOpenIcon'
import EyeClosedIcon from 'assets/icons/EyeClosedIcon'
import styles from './PasswordInput.module.css'

function PasswordInput({
  className = '',
  name = '',
  placeholder = '',
  value = '',
  disabled = false,
  error = false,
  required = false,
  onChange = noop
}) {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  return (
    <div className={styles.passwordInputContainer}>
      <input
        className={[
          styles.input,
          disabled ? styles.disabled : '',
          error ? styles.error : '',
          className
        ].join(' ')}
        name={name}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        required={required}
        onChange={onChange}
      />
      <div className={styles.eyeIcon} onClick={togglePasswordVisibility}>
        {showPassword ? <EyeClosedIcon /> : <EyeOpenIcon />}
      </div>
    </div>
  )
}

export default PasswordInput
