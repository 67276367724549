import * as React from 'react'

const Doubt = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 33 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.14 32.935c8.914 0 16.14-7.226 16.14-16.14 0-8.914-7.226-16.14-16.14-16.14C7.226.655 0 7.881 0 16.795c0 8.914 7.226 16.14 16.14 16.14Z'
      fill={props.color || '#FF0'}
    />
    <path
      d='M27.296 6.353 24.116 0l-3.04 5.985c-.163.24-.296.505-.398.78l-.065.128h.018a3.605 3.605 0 1 0 7.034 1.113 3.893 3.893 0 0 0-.369-1.653Z'
      fill='#8080F8'
    />
    <path
      d='m22.844 15.785-1.451 1.451a2.256 2.256 0 0 0-3.185 0l-1.452-1.451a4.31 4.31 0 0 1 6.088 0ZM8.917 15.994l1.452 1.452a2.256 2.256 0 0 1 3.185 0l1.451-1.452a4.302 4.302 0 0 0-6.088 0ZM9.782 23.81l3.014-3.013a4.68 4.68 0 0 0 6.606 0l3.014 3.014c-3.485 3.48-9.149 3.48-12.634 0Z'
      fill='#000'
    />
  </svg>
)

export default Doubt
