//@ts-check
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { sendNotebookPageChange } from 'api/sockets'
import { clear, drawLine, handleDownload } from 'utils/canvas'
import ChevronLeft from 'assets/icons/ChevronLeft'
import ChevronRight from 'assets/icons/ChevronRight'
import useNotebookBackground from './hooks/useNotebookBackground'
import DownloadButton from 'components/buttons/Download'
import Button from 'components/buttons/Button'
import { classroomTabIds } from './Tabs'
import styles from './Notebook.module.css'

function ClassroomNotebook({
  socket,
  selectedTool,
  onToolChange,
  zoom,
  isActive,
  isVisible,
  teacher,
  notebookPdf,
  canvasContext,
  onSaveCanvasContext,
  onClearCanvasContext,
  onZoomChange,
  onNotebookUrlChange,
  onPdfPageChange
}) {
  const [pdfIsRendering, setPdfIsRendering] = useState(false)
  const [isCanvasReady, setIsCanvasReady] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const [showPdfModal, setShowModal] = useState(false)
  const notebookCanvasRef = useRef(null)
  const canvasRef = useRef(null)

  const { backgroundType, bgdStyle, numberOfLines, numberOfColumns } =
    useNotebookBackground({ socket, canvas: canvasRef })

  const handlePageChange = useCallback(
    newPage => {
      const pages = notebookPdf.source && notebookPdf.source.numPages
      if (!pages) return
      const page = Math.min(Math.max(1, notebookPdf.page + newPage), pages)
      onPdfPageChange(page)
    },
    [notebookPdf, onPdfPageChange]
  )
  const handleSaveContext = useCallback(
    () =>
      canvasRef.current &&
      canvasRef.current.width &&
      canvasRef.current.height &&
      onSaveCanvasContext(
        [
          canvasRef.current
            .getContext('2d', { willReadFrequently: true })
            .getImageData(
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            )
        ],
        classroomTabIds.notebook
      ),

    [onSaveCanvasContext]
  )
  const handleRestoreCanvasContext = useCallback(() => {
    canvasContext?.map(imageData =>
      canvasRef.current
        .getContext('2d', { willReadFrequently: true })
        .putImageData(imageData, 0, 0)
    )
  }, [canvasContext])
  const handleClearCanvasContext = useCallback(() => {
    onClearCanvasContext(classroomTabIds.notebook)
    clear(canvasRef)
  }, [onClearCanvasContext])

  const renderDocument = useCallback(() => {
    if (!notebookPdf.source || !isCanvasReady) return
    setPdfIsRendering(true)
    clear(notebookCanvasRef)
    notebookPdf.source
      .getPage(notebookPdf.page)
      .then(page => {
        const defaultViewport = page.getViewport({ scale: 1 })

        const blackboard = notebookCanvasRef.current
        const canvasContext = blackboard.getContext('2d', {
          willReadFrequently: true
        })
        const { devicePixelRatio = 1 } = window
        const dpr = Math.min(2, devicePixelRatio)
        const widthRatio = blackboard.clientWidth / defaultViewport.width
        const heightRatio = blackboard.clientHeight / defaultViewport.height
        const scale = Math.max(widthRatio, heightRatio)
        const viewport = page.getViewport({
          scale
        })

        notebookCanvasRef.current.width =
          notebookCanvasRef.current.getBoundingClientRect().width * dpr
        notebookCanvasRef.current.height =
          notebookCanvasRef.current.getBoundingClientRect().height * dpr

        canvasContext.scale(dpr, dpr)

        const render = page.render({
          canvasContext,
          viewport,
          transform: [
            blackboard.clientWidth / viewport.width,
            0,
            0,
            blackboard.clientHeight / viewport.height,
            0,
            0
          ]
        })
        render.promise.then(() => {
          setPdfIsRendering(false)
          sendNotebookPageChange({
            socket,
            teacher,
            page: notebookPdf.page
          })
        })
      })
      .catch(e => console.error('Error rendering pdf: ', e))
      .finally(() => {
        setPdfIsRendering(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCanvasReady, notebookPdf, socket, teacher])

  useEffect(() => {
    if (!socket) return
    if (
      !canvasRef ||
      !canvasRef.current ||
      !notebookCanvasRef ||
      !notebookCanvasRef.current ||
      !isCanvasReady
    )
      return
    socket.on(
      'classroom:teachers:notebook-pdf-line',
      ({ x0, y0, x1, y1, color, lineWidth }) => {
        const { devicePixelRatio = 1 } = window
        const dpr = Math.min(2, devicePixelRatio)
        const { width, height } = notebookCanvasRef.current
        const startX = (x0 * width) / dpr
        const startY = (y0 * height) / dpr
        const endX = (x1 * width) / dpr
        const endY = (y1 * height) / dpr
        drawLine(
          notebookCanvasRef,
          startX,
          startY,
          endX,
          endY,
          color,
          lineWidth
        )
      }
    )
    socket.on(
      'classroom:teachers:notebook-blackboard-line',
      ({ x0, y0, x1, y1, color, lineWidth, saveContext }) => {
        const { width, height } = canvasRef.current
        const startX = x0 * width
        const startY = y0 * height
        const endX = x1 * width
        const endY = y1 * height
        drawLine(canvasRef, startX, startY, endX, endY, color, lineWidth)
        saveContext && handleSaveContext()
      }
    )
    socket.on('classroom:teachers:notebook-clear', () => {
      handleClearCanvasContext()
      handleSaveContext()
      handlePageChange(0)
    })
    socket.on('classroom:teachers:notebook-change', ({ url }) =>
      onNotebookUrlChange(url)
    )
    socket.on('classroom:teachers:notebook-page-change', ({ page }) => {
      console.log('Teacher on page', page)
    })
    socket.on('classroom:teachers:notebook-page-sync', ({ page }) => {
      onPdfPageChange(page)
    })

    return () => {
      if (!socket) return
      socket.off('classroom:teachers:notebook-pdf-line')
      socket.off('classroom:teachers:notebook-blackboard-line')
      socket.off('classroom:teachers:notebook-change')
      socket.off('classroom:teachers:notebook-page-change')
      socket.off('classroom:teachers:notebook-page-sync')
    }
  }, [
    handleSaveContext,
    onNotebookUrlChange,
    notebookPdf,
    socket,
    onPdfPageChange,
    isCanvasReady,
    handleClearCanvasContext,
    handlePageChange
  ])

  useLayoutEffect(() => {
    if (pdfIsRendering || isCanvasReady) return
    canvasRef.current.width = canvasRef.current.getBoundingClientRect().width
    canvasRef.current.height = canvasRef.current.getBoundingClientRect().height
    notebookCanvasRef.current.width =
      notebookCanvasRef.current.getBoundingClientRect().width
    notebookCanvasRef.current.height =
      notebookCanvasRef.current.getBoundingClientRect().height
    setIsCanvasReady(true)
    setIsResizing(false)
  }, [isCanvasReady, pdfIsRendering])

  useEffect(() => {
    renderDocument()
  }, [renderDocument])

  useLayoutEffect(() => {
    const resize = () => {
      setIsResizing(true)
      setIsCanvasReady(false)
    }
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  useLayoutEffect(() => {
    if (isVisible && !isResizing) {
      const needResize =
        canvasRef.current.width !=
          canvasRef.current.getBoundingClientRect().width ||
        canvasRef.current.height !=
          canvasRef.current.getBoundingClientRect().height
      if (needResize) {
        canvasRef.current.width =
          canvasRef.current.getBoundingClientRect().width
        canvasRef.current.height =
          canvasRef.current.getBoundingClientRect().height
      }
      handleRestoreCanvasContext()
    }
  }, [
    handleRestoreCanvasContext,
    handleSaveContext,
    isResizing,
    isVisible,
    renderDocument
  ])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <div className={styles.blackboard} hidden={!isVisible && isMounted}>
      <div className={styles.innerContainer}>
        <div className={styles.notebookArea}>
          <div
            className={styles.notebookActions}
            onClick={() => handlePageChange(-1)}
          >
            <ChevronLeft className={styles.notebookChevrons} />
          </div>
          <canvas
            ref={notebookCanvasRef}
            className={[
              styles.notebookCanvas,
              !notebookPdf.source ? styles.notebookCanvasDisabled : ''
            ].join(' ')}
            onClick={() => notebookPdf.source && setShowModal(true)}
          />
          <div
            className={styles.notebookActions}
            onClick={() => handlePageChange(1)}
          >
            <ChevronRight className={styles.notebookChevrons} />
          </div>
        </div>
        <canvas ref={canvasRef} className={styles.canvas} style={bgdStyle} />
      </div>
      <div className={styles.tools}>
        <DownloadButton
          onClick={() =>
            handleDownload(
              canvasRef,
              backgroundType,
              numberOfLines,
              numberOfColumns
            )
          }
          disabled={canvasContext.length === 0}
        />
      </div>
      {showPdfModal && (
        <ZoomPage
          source={notebookPdf.source}
          page={notebookPdf.page}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  )
}

export default ClassroomNotebook

function ZoomPage({ source, page, onClose }) {
  const [zoomToScreen, setZoomToScreen] = useState(false)
  const modalRef = useRef(null)
  const canvasRef = useRef(null)

  const handleKeyDown = useCallback(
    e => {
      e.preventDefault()
      if (e.key === 'Escape') onClose()
    },
    [onClose]
  )

  useLayoutEffect(() => {
    if (!source || !page) return
    source.getPage(page).then(page => {
      const { devicePixelRatio = 1 } = window
      const canvas = canvasRef.current
      canvas.width = canvas.getBoundingClientRect().width
      canvas.height = canvas.getBoundingClientRect().height
      const defaultViewport = page.getViewport({ scale: 1 })
      const canvasContext = canvas.getContext('2d', {
        willReadFrequently: true
      })
      const dpr = Math.min(2, devicePixelRatio)
      const widthRatio = canvas.clientWidth / (defaultViewport.width / dpr)
      const heightRatio = canvas.clientHeight / (defaultViewport.height / dpr)
      const scale = Math.max(widthRatio, heightRatio)

      canvasRef.current.width = Math.floor(defaultViewport.width * scale)
      canvasRef.current.height = Math.floor(defaultViewport.height * scale)
      const viewport = page.getViewport({ scale })
      page.render({ canvasContext, viewport })
    })
  }, [page, source])

  useEffect(() => {
    modalRef.current?.focus()
  }, [])

  return (
    <div
      ref={modalRef}
      className={styles.modal}
      onKeyDown={handleKeyDown}
      tabIndex={100}
    >
      <div className={styles.modalWrapper}>
        <div className={styles.modalContent}>
          <canvas
            className={[
              styles.zoomPageCanvas,
              zoomToScreen ? styles.adjusted : ''
            ].join(' ')}
            ref={canvasRef}
          />
        </div>
        <div className={styles.modalActions}>
          <Button
            type='secondary'
            size='small'
            label='Cerrar'
            onClick={onClose}
          />
          <Button
            size='small'
            label='Cambiar Zoom'
            onClick={() => setZoomToScreen(v => !v)}
          />
        </div>
      </div>
    </div>
  )
}
