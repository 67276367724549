//@ts-check
import { useCallback, useEffect, useState } from 'react'
import { assoc, assocPath, lensPath, pipe, set } from 'ramda'
import * as PdfJsLib from 'pdfjs-dist/legacy/build/pdf.js'
import PdfJsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry'
import {
  leaveTeacherClass,
  sendChatMessage,
  sendLightbulbChange,
  sendSelectedTabChange
} from 'api/sockets'
import { classroomTabIds } from 'components/academy/classroom/Tabs'
import { classroomToolbarIds } from 'components/academy/classroom/Toolbar'
import {
  lightbulbStatusId,
  lightbulbId
} from 'components/academy/classroom/Lightbulb'
import { toTimeString } from 'utils/date'

const initialSelectedToolState = {
  [classroomTabIds.blackboard]: classroomToolbarIds.draw,
  [classroomTabIds.notebook]: classroomToolbarIds.draw,
  [classroomTabIds.photo]: classroomToolbarIds.draw,
  [classroomTabIds.video]: classroomToolbarIds.draw
}

const initialZoomState = {
  [classroomTabIds.blackboard]: 0,
  [classroomTabIds.notebook]: 0,
  [classroomTabIds.photo]: 0,
  [classroomTabIds.video]: 0
}

const initialCanvasState = {
  [classroomTabIds.blackboard]: [],
  [classroomTabIds.notebook]: [],
  [classroomTabIds.photo]: [],
  [classroomTabIds.video]: []
}

const initialState = {
  lightbulbStatus: { id: lightbulbId, status: lightbulbStatusId.OFF },
  isActive: false,
  selectedTab: classroomTabIds.blackboard,
  selectedTabByTeacher: classroomTabIds.blackboard,
  toolBySection: initialSelectedToolState,
  zoomBySection: initialZoomState,
  canvasBySection: initialCanvasState,
  learningVideo: '',
  photo: null,
  photoRotationDegree: 0,
  notebookUrl: '',
  notebookPdf: {},
  teacherIsReconnecting: false,
  messages: []
}
function makeInitialState(isReconnecting, startAsActive) {
  return {
    ...initialState,
    isActive: startAsActive,
    selectedTab: isReconnecting
      ? classroomTabIds.none
      : initialState.selectedTab
  }
}

export default function useClassroom({
  socket,
  identity,
  teacher = '',
  isReconnecting,
  startAsActive
}) {
  const [state, setState] = useState(
    makeInitialState(isReconnecting, startAsActive)
  )
  const {
    lightbulbStatus,
    isActive,
    selectedTab,
    selectedTabByTeacher,
    toolBySection,
    zoomBySection,
    canvasBySection,
    learningVideo,
    notebookUrl,
    notebookPdf,
    photo,
    photoRotationDegree,
    teacherIsReconnecting,
    messages
  } = state

  const handleTabChange = tabId => {
    setState(assoc('selectedTab', tabId))
    sendSelectedTabChange({ socket, tabId, teacher }).catch()
  }

  const handleToolChange = useCallback(
    toolId => {
      setState(assocPath(['toolBySection', selectedTab], toolId))
    },
    [selectedTab]
  )
  const handleZoomChange = useCallback(
    value => {
      setState(assocPath(['zoomBySection', selectedTab], value))
    },
    [selectedTab]
  )

  const handleLightbulbClick = useCallback(
    id => {
      if (isActive) return
      const status = {
        id,
        status:
          lightbulbStatus.status === lightbulbStatusId.ON &&
          lightbulbStatus.id === id
            ? lightbulbStatusId.OFF
            : lightbulbStatusId.ON
      }
      sendLightbulbChange({ socket, teacher, ...status })
        .then(() =>
          setState(prevState => ({
            ...prevState,
            lightbulbStatus: status
          }))
        )
        .catch(() =>
          console.error('Ha habido un error inesperado con la bombilla')
        )
    },
    [isActive, lightbulbStatus, socket, teacher]
  )

  const handleSaveCanvasContext = useCallback((partialContext, tab) => {
    const tabLens = lensPath(['canvasBySection', tab])
    setState(state => set(tabLens, partialContext, state))
  }, [])
  const handleClearCanvasContext = useCallback(tab => {
    const tabLens = lensPath(['canvasBySection', tab])
    setState(set(tabLens, []))
  }, [])

  const handleSendMessage = message => {
    if (message.trim()) {
      sendChatMessage({ socket, teacher, message })
      setState(prevState => ({
        ...prevState,
        messages: [
          ...prevState.messages,
          {
            text: message,
            timestamp: toTimeString(new Date()),
            type: 'sent'
          }
        ]
      }))
    }
  }
  const handleCleanNewMessage = useCallback(
    () =>
      setState(prevState => ({
        ...prevState,
        messages: prevState.messages.map(m => {
          delete m.isNew
          return m
        })
      })),
    []
  )
  useEffect(() => {
    if (!socket) return
    socket.on('classroom:teachers:chat-message', ({ message }) => {
      setState(prevState => ({
        ...prevState,
        messages: [
          ...prevState.messages,
          {
            text: message,
            timestamp: toTimeString(new Date()),
            type: 'received',
            isNew: true
          }
        ]
      }))
    })
    return () => {
      if (!socket) return
      socket.off('classroom:teachers:chat-message')
    }
  }, [socket])
  //Notebooks
  const handleNotebookUrlChange = notebookUrl =>
    setState(assoc('notebookUrl', notebookUrl))

  useEffect(() => {
    if (!socket) return
    socket.on('classroom:teachers:select-student', activeStudentId => {
      const active = identity.id === activeStudentId
      setState(assoc('isActive', active))
      if (active)
        setState(prevState => ({
          ...prevState,
          lightbulbStatus: {
            id: prevState.lightbulbStatus.id,
            status: lightbulbStatusId.OFF
          }
        }))
    })
    return () => {
      if (!socket) return
      socket.off('classroom:teachers:select-student')
    }
  }, [socket, identity])

  const handlePdfPageChange = page =>
    setState(assocPath(['notebookPdf', 'page'], page))

  useEffect(() => {
    if (!socket) return
    socket.on('classroom:teachers:video-select', ({ teacherId, url }) => {
      if (teacher === teacherId) setState(assoc('learningVideo', url))
    })
    socket.on('classroom:teachers:video-remove', ({ teacherId }) => {
      if (teacher === teacherId) setState(assoc('learningVideo', null))
    })
    return () => {
      if (!socket) return
      socket.off('classroom:teachers:video-select')
      socket.off('classroom:teachers:video-remove')
    }
  }, [socket, teacher])

  useEffect(() => {
    return () => {
      if (!socket) return
      leaveTeacherClass({ socket, teacher })
    }
  }, [socket, teacher])
  useEffect(() => {
    if (isReconnecting && isActive)
      setState(
        pipe(
          assoc('isActive', false),
          assoc('selectedTab', classroomTabIds.none)
        )
      )
  }, [isActive, isReconnecting])
  useEffect(() => {
    if (isReconnecting && selectedTab !== classroomTabIds.none)
      setState(assoc('selectedTab', classroomTabIds.none))
    if (!isReconnecting && selectedTab === classroomTabIds.none)
      setState(assoc('selectedTab', classroomTabIds.blackboard))
  }, [isReconnecting, selectedTab])
  useEffect(() => {
    if (!socket) return
    socket.on('classroom:teachers:reconnecting', teacherId => {
      if (teacher === teacherId) setState(assoc('teacherIsReconnecting', true))
    })
    return () => {
      if (!socket) return
      socket.off('classroom:teachers:reconnecting')
    }
  }, [socket, teacher])

  useEffect(() => {
    if (!socket) return
    socket.on('classroom:teachers:reconnected', teacherId => {
      if (teacher === teacherId) setState(assoc('teacherIsReconnecting', false))
    })
    return () => {
      if (!socket) return
      socket.off('classroom:teachers:reconnected')
    }
  }, [socket, teacher])

  useEffect(() => {
    if (teacherIsReconnecting) {
      setTimeout(() => {
        setState(assoc('teacherIsReconnecting', false))
      }, 10000)
    }
  }, [teacherIsReconnecting])

  //notebooks pdf
  useEffect(() => {
    // @ts-ignore
    PdfJsLib.GlobalWorkerOptions.workerSrc = PdfJsWorker
  }, [])

  useEffect(() => {
    if (!notebookUrl) return
    PdfJsLib.getDocument(notebookUrl)
      .promise.then(source =>
        setState(assoc('notebookPdf', { source, page: 1 }))
      )
      .catch(() => {
        setState(assoc('notebookPdf', { source: null, page: 1 }))
      })
  }, [notebookUrl])

  //photo
  useEffect(() => {
    if (!socket) return
    socket.on('classroom:teachers:image-select', ({ image }) => {
      setState(pipe(assoc('photo', image), assoc('photoRotationDegree', 0)))
    })
    socket.on('classroom:teachers:image-rotation', ({ rotationDegree }) => {
      setState(assoc('photoRotationDegree', rotationDegree))
    })
    return () => {
      if (!socket) return
      socket.off('classroom:teachers:image-rotation')
      socket.off('classroom:teachers:image-select')
    }
  }, [socket])

  useEffect(() => {
    if (!socket) return
    socket.on('classroom:teachers:selected-tab', tabId => {
      setState(assoc('selectedTabByTeacher', tabId))
    })
    return () => {
      if (!socket) return
      socket.off('classroom:teachers:selected-tab')
    }
  }, [socket])

  return {
    lightbulbStatus,
    isActive,
    selectedTab,
    selectedTabByTeacher,
    selectedTool: toolBySection[selectedTab],
    zoom: zoomBySection[selectedTab],
    canvasBySection,
    learningVideo,
    notebookPdf,
    photo,
    photoRotationDegree,
    messages,
    handleTabChange,
    handleToolChange,
    handleZoomChange,
    handleLightbulbClick,
    handleSaveCanvasContext,
    handleClearCanvasContext,
    handleNotebookUrlChange,
    handlePdfPageChange,
    handleSendMessage,
    handleCleanNewMessage
  }
}
