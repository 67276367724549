//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { assoc, isEmpty, propEq, without } from 'ramda'
import { getSubscriptionById } from 'api/subscriptions'
import { findAttendanceDTOs } from 'api/attendances'
import {
  getMonthlyOptionsBy,
  getWeeklyOptionsBy,
  makeMonthlyHoursByTeacher,
  makeWeeklyHoursByTeacher,
  saveMonthlyOptions,
  saveWeeklyOptions
} from 'api/calendar'
import { useNotificationActions } from 'context/NotificationProvider'
import { SubscriptionCodeLabels, SubscriptionCodes } from 'utils/constants'
import { DAY_NAME_BY_NUMBER, addDaysTo, dateToString } from 'utils/date'
import useSubjects from 'hooks/useSubjects'
import Back from 'assets/icons/Back'
import Info from 'assets/icons/Info'
import Cross from 'assets/icons/Cross'
import Modal from 'components/modals/Modal'
import { H2, H4, H5, Label, Paragraph } from 'components/typography'
import RingsSpinner from 'components/spinners/RingsSpinner'
import Button from 'components/buttons/Button'
import Radio from 'components/inputs/Radio'
import Slider from 'components/inputs/Slider'
import Select from 'components/selects/Select'
import MonthlyCalendar from 'components/calendars/MonthlyCalendar'
import WeeklyCalendar from 'components/calendars/WeeklyCalendar'
import AttendanceCalendar from 'components/calendars/AttendanceCalendar'
import AttendanceEditModal from 'components/modals/AttendanceEditModal'
import VerticalTecherCard from 'components/cards/teacher/VerticalTecherCard'
import styles from './SubscriptionDetails.module.css'

function SubscriptionDetails() {
  const { subscriptionId } = useParams()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({ subscription: {}, budget: {} })
  const [state, setState] = useState({
    step: 0,
    teachers: [],
    selectedSubjectId: null,
    subscriptions: [],
    monthlyOptions: {},
    weeklyOptions: {},
    attendances: [],
    selectedAttendanceId: null,
    hoursByTeacherId: {},
    sortedTeacherIds: [],
    selectedTeacherToShowInfo: null,
    isSearchingOptions: false,
    isSearchingTeachers: false,
    isSavingCalendarOptions: false,
    showModal: false
  })
  const [monthlyHoursByDay, setMonthlyHoursByDay] = useState({})
  const [weeklyHoursByDate, setweeklyHoursByDate] = useState({})

  const { setSuccessMessage, setErrorMessage, setWarningMessage } =
    useNotificationActions()
  const {
    subjectsById,
    subjects,
    isFetching: isFetchingSubjects,
    fetchSubjects
  } = useSubjects({
    fetchOnMount: false
  })

  const {
    step,
    teachers,
    selectedSubjectId,
    subscriptions,
    monthlyOptions,
    weeklyOptions,
    attendances,
    selectedAttendanceId,
    hoursByTeacherId,
    sortedTeacherIds,
    selectedTeacherToShowInfo,
    isSearchingOptions,
    isSearchingTeachers,
    isSavingCalendarOptions,
    showModal
  } = state

  const isPack = data.budget?.config?.code === SubscriptionCodes.PACK

  const selectedAttendance = useMemo(() => {
    if (!selectedAttendanceId) return {}
    return attendances.find(propEq('id', selectedAttendanceId))
  }, [attendances, selectedAttendanceId])

  const fetchData = useCallback(() => {
    getSubscriptionById(subscriptionId)
      .then(setData)
      .catch(error => {
        setErrorMessage({ message: error.message })
        console.error('Error al obtener detalles de la propuesta:', error)
      })
      .finally(() => setLoading(false))
  }, [subscriptionId, setErrorMessage])

  const handleSubjectChange = e => {
    const selectedSubjectId = e.target.value
    setState(state => ({
      ...state,
      selectedSubjectId,
      monthlyOptions: {},
      weeklyOptions: [],
      isSearchingOptions: true
    }))
    setMonthlyHoursByDay({})
    setweeklyHoursByDate({})
  }
  const toggleShowAttendanceCalendar = () =>
    setState(state => ({
      ...state,
      selectedSubjectId: null
    }))
  const handleMonthlyHoursByDayChange = (hour, dayNumber) => {
    setMonthlyHoursByDay(state => {
      const currentHours = state[dayNumber] || []
      let newHours = []
      if (currentHours.includes(hour)) newHours = without([hour], currentHours)
      else newHours = currentHours.concat(hour)

      let newState = { ...state, [dayNumber]: newHours }
      if (isEmpty(newHours)) delete newState[dayNumber]
      return newState
    })
  }

  const handleWeeklyHoursByDateChange = (hour, targetStringDate) => {
    setweeklyHoursByDate(state => {
      const currentHours = state[targetStringDate] || []
      let newHours = []
      if (currentHours.includes(hour)) newHours = without([hour], currentHours)
      else newHours = currentHours.concat(hour)
      return { ...state, [targetStringDate]: newHours }
    })
  }

  const handleSearchTeacherInfo = useCallback(() => {
    setState(assoc('isSearchingTeachers', true))
    if (isPack) {
      const enjoymentDays = 20 //TODO extract from common object or subsconfig
      const startDate = new Date(data.subscription.startDate)
      const endDate = addDaysTo(startDate, enjoymentDays)
      makeWeeklyHoursByTeacher({
        subscriptionId,
        subjectId: selectedSubjectId,
        weeklyHoursByDate,
        startDate,
        endDate
      })
        .then(({ hoursByTeacherId, sortedTeacherIds, teachers }) => {
          setState(state => ({
            ...state,
            hoursByTeacherId,
            sortedTeacherIds,
            teachers,
            isSearchingTeachers: false,
            step: 2
          }))
        })
        .catch(e => {
          console.error('Error searching target teachers: ', e)
          setErrorMessage({
            message: 'No se han podido encontrar ningún profesor'
          })
          setState(assoc('isSearchingTeachers', false))
        })
      return
    } else {
      const date = new Date(data.subscription.startDate)
      const month = date.getMonth()
      const year = date.getFullYear()
      return makeMonthlyHoursByTeacher({
        subscriptionId,
        subjectId: selectedSubjectId,
        monthlyHoursByDay,
        month,
        year
      })
        .then(({ hoursByTeacherId, sortedTeacherIds, teachers }) => {
          setState(state => ({
            ...state,
            hoursByTeacherId,
            sortedTeacherIds,
            teachers,
            isSearchingTeachers: false,
            step: 2
          }))
        })
        .catch(e => {
          console.error('Error searching target teachers: ', e)
          setErrorMessage({
            message: 'No se han podido encontrar ningún profesor'
          })
          setState(assoc('isSearchingTeachers', false))
        })
    }
  }, [
    data.subscription,
    monthlyHoursByDay,
    selectedSubjectId,
    setErrorMessage,
    subscriptionId,
    isPack,
    weeklyHoursByDate
  ])
  const toggleShowModal = useCallback(() => {
    setState(s => ({
      ...s,
      showModal: !s.showModal,
      selectedAttendanceId: !isEmpty(selectedAttendance)
        ? null
        : s.selectedAttendanceId
    }))
  }, [selectedAttendance])

  const handleEdit = useCallback(
    attendanceId => {
      setState(assoc('selectedAttendanceId', attendanceId))
      toggleShowModal()
    },
    [toggleShowModal]
  )
  const fetchAttendances = useCallback(() => {
    findAttendanceDTOs({ subscriptionId })
      .then(attendances => setState(assoc('attendances', attendances)))
      .catch(e => {
        console.error('Error fetching attendances: ' + e)
        //TODO: block editor?
      })
  }, [subscriptionId])

  const handleSaveCalendarOptions = useCallback(() => {
    setState(assoc('isSavingCalendarOptions', true))
    if (isPack) {
      const enjoymentDays = 20 //TODO extract from common object or subsconfig
      const startDate = new Date(data.subscription.startDate)
      const endDate = addDaysTo(startDate, enjoymentDays)
      saveWeeklyOptions({
        subscriptionId,
        budgetId: data.budget.id,
        subjectId: selectedSubjectId,
        teacherId: selectedTeacherToShowInfo,
        startDate,
        endDate,
        weeklyHoursByDate
      })
        .then(data => {
          const { failed = [], success = [] } = data
          const noData = !failed.length && !success?.length
          if (noData) {
            setState(assoc('isSavingCalendarOptions', false))
            return setWarningMessage({
              title: 'Revisa tu selección',
              message: 'No existen opciones aplicables desde la fecha actual'
            })
          }

          if (failed.length && !success.length) {
            setState(assoc('isSavingCalendarOptions', false))
            return setErrorMessage({
              title: 'UPSS!',
              message: `Parece que no se ha podido guardar tu configuración. Revísala y asegúrate de que estás dentro del rango de disfrute.`
            })
          }
          setSuccessMessage({})

          fetchAttendances()
          setState(state => ({
            ...state,
            isSavingCalendarOptions: false,
            selectedSubjectId: null,
            weeklyOptions: {},
            selectedTeacherToShowInfo: null,
            step: 1
          }))
          setMonthlyHoursByDay({})
        })
        .catch(e => {
          console.error('Error saving monthly options: ', e)
          setErrorMessage({
            message: 'No se han podido guardar tu configuración'
          })
          setState(assoc('isSavingCalendarOptions', false))
        })
      return
    }
    saveMonthlyOptions({
      subscriptionId,
      budgetId: data.budget.id,
      subjectId: selectedSubjectId,
      teacherId: selectedTeacherToShowInfo,
      monthlyHoursByDay
    })
      .then(data => {
        const { failed = [], success = [] } = data
        const noData = !failed.length && !success?.length
        if (noData) {
          setState(assoc('isSavingCalendarOptions', false))
          return setWarningMessage({
            title: 'Revisa tu selección',
            message: 'No existen opciones aplicables desde la fecha actual'
          })
        }

        if (failed.length && !success.length) {
          setState(assoc('isSavingCalendarOptions', false))
          return setErrorMessage({
            title: 'UPSS!',
            message: `Parece que no se ha podido guardar tu configuración. Revísala y asegúrate de que estás dentro del rango de disfrute.`
          })
        }
        setSuccessMessage({})

        fetchAttendances()
        setState(state => ({
          ...state,
          isSavingCalendarOptions: false,
          selectedSubjectId: null,
          monthlyOptions: {},
          selectedTeacherToShowInfo: null,
          step: 1
        }))
        setMonthlyHoursByDay({})
      })
      .catch(e => {
        console.error('Error saving monthly options: ', e)
        setErrorMessage({
          message: 'No se han podido guardar tu configuración'
        })
        setState(assoc('isSavingCalendarOptions', false))
      })
  }, [
    isPack,
    subscriptionId,
    data,
    selectedSubjectId,
    selectedTeacherToShowInfo,
    monthlyHoursByDay,
    weeklyHoursByDate,
    setSuccessMessage,
    fetchAttendances,
    setWarningMessage,
    setErrorMessage
  ])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  useEffect(() => {
    if (data.budget.selectedSubjectIds?.length > 0)
      fetchSubjects({
        _id: { $in: data.budget.selectedSubjectIds },
        active: '_both_'
      })
  }, [data, fetchSubjects])

  useEffect(() => {
    if (selectedSubjectId) {
      if (isPack) {
        const enjoymentDays = 20 //TODO extract from common object or subsconfig
        const startDate = new Date(data.subscription.startDate)
        const endDate = addDaysTo(startDate, enjoymentDays)
        getWeeklyOptionsBy({
          startDate: startDate.getTime(),
          endDate: endDate.getTime(),
          subjectId: selectedSubjectId
        })
          .then(weeklyOptions =>
            setState(state => ({
              ...state,
              weeklyOptions,
              isSearchingOptions: false
            }))
          )
          .catch(e => {
            console.error('Error fetching weeklyHours:', e)
            setErrorMessage({
              message: 'Ha ocurrido un error buscando opciones para tu clase'
            })
            setState(state => ({ ...state, isSearchingOptions: false }))
          })
      } else {
        const startDate = new Date(data.subscription.startDate)
        const month = startDate.getMonth()
        const year = startDate.getFullYear()
        getMonthlyOptionsBy({
          subjectId: selectedSubjectId,
          month,
          year
        })
          .then(monthlyOptions => {
            setState(state => ({
              ...state,
              monthlyOptions,
              isSearchingOptions: false
            }))
          })
          .catch(e => {
            console.error('Error fetching monthlyHours:', e)
            setErrorMessage({
              message: 'Ha ocurrido un error buscando opciones para tu clase'
            })
            setState(state => ({ ...state, isSearchingOptions: false }))
          })
      }
    }
  }, [data.subscription, isPack, selectedSubjectId, setErrorMessage])

  useEffect(() => {
    if (step === 1) fetchAttendances()
  }, [fetchAttendances, , step])

  const disabledChooseTeacher =
    isSearchingOptions ||
    isSearchingTeachers ||
    (isPack ? isEmpty(weeklyHoursByDate) : isEmpty(monthlyHoursByDay))

  const step0 = (
    <>
      <H2>
        <Link to='/subscriptions'>
          <Back color='var(--sandground)' className={styles.backIcon} />
        </Link>
        Tu suscripción 📃
      </H2>
      <div className={styles.card}>
        <div className={styles.subscriptionDetails}>
          <H4 className={styles.proposalName}>
            {SubscriptionCodeLabels[data.budget?.config?.code]}
          </H4>
          <Paragraph>{data.budget?.config?.hours}/horas</Paragraph>
        </div>

        <div className={styles.subjectsContainer}>
          {data.budget.selectedSubjectIds?.map((selectedSubjectId, index) => {
            return (
              <div className={styles.subject} key={index}>
                <Paragraph>{subjectsById[selectedSubjectId]?.label}</Paragraph>

                <Paragraph>
                  {makeText(data.budget.config, selectedSubjectId)}
                </Paragraph>
              </div>
            )
          })}
        </div>
        <div className={styles.divider} />
        <div className={styles.totalContainer}>
          <Paragraph className={styles.totalLabel}>Total </Paragraph>
          <Paragraph className={styles.totalPrice}>
            {data.budget.totalPrice}€/mes
          </Paragraph>
        </div>
        <div className={styles.buttonActions}>
          <Button
            label='Configurar horario'
            size='small'
            onClick={() => setState(assoc('step', 1))}
          />
          <Button label='Añadir horas' size='small' />
        </div>

        <div className={styles.conditionsContainer}>
          <H5>Condiciones</H5>
          {data.budget?.config?.conditions?.map((condition, index) => (
            <Paragraph key={index} className={styles.conditionText}>
              * {condition}
            </Paragraph>
          ))}
        </div>
        <Paragraph className={styles.dateText}>
          * Configurado para el{' '}
          <strong>
            {('0' + (data.budget?.config?.projection?.month + 1)).slice(-2)}/
            {data.budget?.config?.projection?.year}
          </strong>
        </Paragraph>
      </div>
    </>
  )
  const step1 = (
    <>
      <div className={styles.topSection}>
        <H2>
          <Back
            color='var(--sandground)'
            className={styles.backIcon}
            onClick={() => setState(assoc('step', 0))}
          />
          Configura tu horario {' - '}
          {`${data.budget.config?.name} (${dateToString(
            data.subscription.startDate
          )})`}
        </H2>
        <Paragraph>
          Selecciona la asignatura y el horario que mejor te convenga.
          <br />
          Podrás modificarla en cualquier momento.
        </Paragraph>

        <div className={styles.toolbar}>
          {!isEmpty(data.budget.selectedSubjectIds) && (
            <Select
              customStyles={{ container: styles.subjectSelect }}
              value={selectedSubjectId}
              options={subjects.filter(
                ({ id }) => true
                // !attendances.map(({ subjectId }) => subjectId).includes(id)
              )}
              placeholder='Asignatura'
              isLoading={isFetchingSubjects}
              onChange={handleSubjectChange}
            />
          )}
          {!isEmpty(attendances) && selectedSubjectId && (
            <Button
              label='Ver asistencias'
              size='small'
              onClick={toggleShowAttendanceCalendar}
            />
          )}
        </div>
      </div>
      {selectedSubjectId ? (
        <div className={styles.calendarSection}>
          {isSearchingOptions && (
            <Paragraph>Buscando horas disponibles...</Paragraph>
          )}
          {!isPack && (
            <MonthlyCalendar
              values={monthlyHoursByDay}
              options={monthlyOptions}
              onChange={handleMonthlyHoursByDayChange}
              disabled={
                isSearchingOptions ||
                isSavingCalendarOptions ||
                isSearchingTeachers
              }
            />
          )}
          {isPack && (
            <WeeklyCalendar
              startDate={new Date(data.subscription.startDate)}
              values={weeklyHoursByDate}
              options={weeklyOptions}
              onChange={handleWeeklyHoursByDateChange}
              disabledPast
            />
          )}
          <div className={styles.saveButton}>
            <Button
              loading={isSearchingTeachers}
              disabled={disabledChooseTeacher}
              onClick={handleSearchTeacherInfo}
            />
          </div>
        </div>
      ) : (
        <div className={styles.attendanceCalendar}>
          <AttendanceCalendar attendances={attendances} onEdit={handleEdit} />
        </div>
      )}
      {showModal && (
        <AttendanceEditModal
          attendance={selectedAttendance}
          onClose={toggleShowModal}
          onUpdate={fetchAttendances}
        />
      )}
    </>
  )

  const getTargetTeacher = teacherId => teachers.find(propEq('id', teacherId))

  const step2 = (
    <>
      <div className={styles.topSection}>
        <H2>
          <Back
            color='var(--sandground)'
            className={styles.backIcon}
            onClick={() =>
              setState(state => ({
                ...state,
                step: 1
              }))
            }
          />
          Configura tu horario por asignatura
        </H2>
        <Paragraph>
          Selecciona el profesor que prefieras y más se ajuste a tu horario.
        </Paragraph>
      </div>
      <div className={styles.teacherList}>
        {sortedTeacherIds?.map((teacherId, index) => {
          const teacher = getTargetTeacher(teacherId)
          if (!teacher) return null
          const commonClick = () =>
            setState(state => ({
              ...state,
              selectedTeacherToShowInfo: teacherId
            }))
          return (
            <div key={teacherId} className={styles.teacherInfo}>
              <VerticalTecherCard
                teacher={teacher}
                subjectsById={subjectsById}
                onClick={commonClick}
                buttonLabel='Agendar clases'
              />
              <Info className={styles.icon} onClick={commonClick} />
            </div>
          )
        })}
        {selectedTeacherToShowInfo && (
          <Modal
            okText='Agendar clases'
            onOk={handleSaveCalendarOptions}
            isLoading={isSavingCalendarOptions}
            hideCancelButton
          >
            <div className={styles.teacherModalInfo}>
              <Cross
                className={styles.closeIcon}
                onClick={() =>
                  !isSavingCalendarOptions &&
                  setState(state => ({
                    ...state,
                    selectedTeacherToShowInfo: null
                  }))
                }
              />
              <H4>{getTargetTeacher(selectedTeacherToShowInfo)?.name}</H4>
              <Paragraph className={styles.paragraph}>
                Tu profe tiene las siguientes horas disponibles:
              </Paragraph>
              {isPack ? (
                <WeeklyInfo
                  data={hoursByTeacherId[selectedTeacherToShowInfo]}
                />
              ) : (
                <MonthlyInfo
                  data={hoursByTeacherId[selectedTeacherToShowInfo]}
                />
              )}
            </div>
          </Modal>
        )}
      </div>
    </>
  )

  return (
    <section className={styles.section}>
      {loading ? (
        <RingsSpinner />
      ) : (
        <>
          {step === 0 && step0}
          {step === 1 && step1}
          {step === 2 && step2}
        </>
      )}
    </section>
  )
}

export default SubscriptionDetails
function makeText(config = {}, selectedSubjectId) {
  switch (config?.code) {
    case SubscriptionCodes.NORMAL:
      return makeNormalText(config.projection, selectedSubjectId)
    case SubscriptionCodes.MORNINGS:
      return makeMorningsText(config.projection, selectedSubjectId)
    case SubscriptionCodes.OTHERS:
    case SubscriptionCodes.PACK:
      return '-'
    default: {
      console.warn('Unknown code: ' + config?.code)
      return ''
    }
  }
}

function makeNormalText(projection = {}, selectedSubjectId) {
  const hour = projection.week[selectedSubjectId]
  return `${hour}h/semana`
}
function makeMorningsText(projection = {}, selectedSubjectId) {
  const hour = projection.hoursBySubjectId[selectedSubjectId]
  return `${hour}h/mes`
}

function MonthlyInfo({ data }) {
  const dayNumbers = Object.keys(data).sort()
  return (
    <>
      <div>
        {dayNumbers.map(dayNumber => {
          const { label } = DAY_NAME_BY_NUMBER.find(
            propEq('day', Number(dayNumber))
          )
          const hours = data[dayNumber]
          return (
            <Paragraph key={dayNumber} className={styles.paragraph}>
              <span className={styles.dayLabel}>{label}</span>:{' '}
              {hours.join(', ')}
            </Paragraph>
          )
        })}
      </div>

      <Label className={styles.advice}>
        Puede darse el caso de que alguna hora no pueda añadirse debido al nivel
        de ocupación
      </Label>
    </>
  )
}
function WeeklyInfo({ data }) {
  const dates = Object.keys(data).sort()
  return (
    <>
      <div>
        {dates.map(date => {
          const targetDate = new Date(Number(date))
          const label = `${
            DAY_NAME_BY_NUMBER.find(propEq('day', targetDate.getDay()))?.label
          } - ${dateToString(targetDate)}`
          const hours = data[date]
          return (
            <Paragraph key={date} className={styles.paragraph}>
              <span className={styles.dayLabel}>{label}</span>:{' '}
              {hours.join(', ')}
            </Paragraph>
          )
        })}
      </div>

      <Label className={styles.advice}>
        Puede darse el caso de que alguna hora no pueda añadirse debido al nivel
        de ocupación
      </Label>
    </>
  )
}

function SubscriptionModal({ onOk, onCancel }) {
  const [shouldBeAddedToRate, setShouldBeAddedToRate] = useState(false)
  const [hours, setHours] = useState(0)
  return (
    <Modal showActions={false}>
      <div className={styles.contentModal}>
        <H2>{`¿Quieres contratar más horas de XYZ?`}</H2>
        <Paragraph>
          Estas horas se añadirán de forma puntual contratando un{' '}
          <span className={styles.bold}>Pack de Horas</span>.<br />
          <span className={styles.bold}>
            Si lo deseas puedes añadirlos a tu tarifa mensual.
          </span>
        </Paragraph>
        <div className={styles.columns}>
          <div className={styles.leftColumn}>
            <Button label='Matemáticas' type='accent-primary' size='tiny' />
            <Radio
              label='Quiero añadir a mi tarifa mensual'
              onCheck={v => setShouldBeAddedToRate(v)}
              checked={shouldBeAddedToRate}
            />
          </div>
          <Slider value={hours} onChange={setHours} />
        </div>
        <div className={styles.actions}>
          <Button onClick={onOk} />
          <div />
          <Button
            label='Cancelar'
            size='small'
            type='accent-tertiary'
            onClick={onCancel}
          />
        </div>
      </div>
    </Modal>
  )
}
