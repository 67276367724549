//@ts-check
import React, { useState } from 'react'
import Button from 'components/buttons/Button'
import RingsSpinner from 'components/spinners/RingsSpinner'
import styles from './HighlightCard.module.css'

/**
 * @typedef HighlightCardProps
 * @property {string} imgUrl
 * @property {string} title
 * @property {Partial<import('components/buttons/Button').ButtonProps & {href: string}>} buttonProps
 * */

/**
 *
 * @param {Partial<HighlightCardProps>} param0
 */
function HighlightCard({
  imgUrl = '',
  title = '¿Eres nuevo por aquí o no?',
  buttonProps
}) {
  const [isLoading, setIsLoading] = useState(true)
  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: 'url(' + imgUrl + ')',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <>
        <img
          src={imgUrl}
          onLoad={() => setIsLoading(false)}
          onError={() => setIsLoading(false)}
          hidden
        />
        {isLoading ? (
          <RingsSpinner />
        ) : (
          <div className={styles.content}>
            <p className={styles.title}>{title}</p>
            <a href={buttonProps?.href ?? ''} target='_blank'>
              <Button
                size='small'
                type='ghost'
                label='Entrar'
                {...(buttonProps || {})}
              />
            </a>
          </div>
        )}
      </>
    </div>
  )
}

export default HighlightCard
