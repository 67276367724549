import adapter from 'webrtc-adapter'

export const isSupported = () => {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) return true
  const getUserMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia
  if (!getUserMedia) return false
  return true
}

/**
 *
 * @param {string} [audioSourceId]
 * @param {string} [videoSourceId]
 */
export const getConstraints = (audioSourceId, videoSourceId) => {
  const commonVideoConstraints = videoSourceId
    ? {
        deviceId: videoSourceId ? { exact: videoSourceId } : undefined
      }
    : {
        facingMode: 'user'
      }

  if (adapter.browserDetails.browser === 'firefox')
    return {
      audio: audioSourceId ? { deviceId: { exact: audioSourceId } } : true,
      video: {
        width: 240,
        height: 240,
        ...commonVideoConstraints
      }
    }
  return {
    audio: audioSourceId ? { deviceId: { exact: audioSourceId } } : true,
    video: {
      width: {
        min: 200,
        max: 240,
        ideal: 240
      },
      height: {
        min: 200,
        max: 240,
        ideal: 240
      },
      frameRate: {
        max: 15
      },
      ...commonVideoConstraints
    }
  }
}

const getLocalStream = constraints => {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    return navigator.mediaDevices.getUserMedia(constraints)
  } else {
    const getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia
    if (!getUserMedia) return Promise.reject(new Error('Webcam not supported'))
    else
      return new Promise((resolve, reject) => {
        getUserMedia.call(navigator, constraints, resolve, reject)
      })
  }
}
export default getLocalStream
