import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { findSubscriptions } from 'api/subscriptions'
import useCustomNavigate from 'hooks/useCustomNavigate'
import Button from 'components/buttons/Button'
import { H2, Paragraph } from 'components/typography'
import SubscriptionCard from 'components/cards/subscriptions/SubscriptionCard'
import RingsSpinner from 'components/spinners/RingsSpinner'
import styles from './Subscriptions.module.css'

function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  const navigate = useCustomNavigate()

  useEffect(() => {
    setIsFetching(true)
    findSubscriptions()
      .then(setSubscriptions)
      .catch(err => {
        console.error('Error fetching subscriptions: ', err)
      })
      .finally(() => setIsFetching(false))
  }, [])

  const handleButtonClick = subscriptionId => {
    navigate(subscriptionId)
  }

  if (isFetching) return <RingsSpinner />
  return (
    <section className={styles.section}>
      <div className={styles.toolbar}>
        <H2>{subscriptions.length > 1 ? 'Tus planes' : 'Tu plan'}</H2>
        <Link to='create'>
          <Button size='small' label='Añadir' />
        </Link>
      </div>
      <div className={styles.container}>
        {subscriptions.length > 0 ? (
          subscriptions.map(subscription => (
            <SubscriptionCard
              key={subscription.id}
              subscription={subscription}
              onClick={() => handleButtonClick(subscription.id)}
            />
          ))
        ) : (
          <Paragraph>Actualmente no tienes ningún plan contratado.</Paragraph>
        )}
      </div>
    </section>
  )
}

export default Subscriptions
