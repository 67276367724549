//@ts-check
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AccessTypes } from 'utils/constants'
import { useAuth } from 'context/AuthProvider'
import useFormManager from 'hooks/useFormManager'

const REQUIRED_FIELDS_PERSONAL_DATA = [
  'name',
  'email',
  'dni',
  'phoneNumber.cleanedPhone',
  'dateOfBirth'
]

function AccountLayout() {
  const { student } = useAuth()
  const { fastCheck } = useFormManager()

  const redirectToPersonalData = fastCheck(
    REQUIRED_FIELDS_PERSONAL_DATA,
    student
  )
  if (student.accessType === AccessTypes.INTERNAL) return <Navigate to='/' />
  return (
    <Outlet
      context={{
        redirectToPersonalData
      }}
    />
  )
}

export default AccountLayout
