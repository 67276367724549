import * as React from 'react'

const Vector = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 79 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#Vector)'>
      <path
        d='M3.625 17.335c1.539-1.648 3.073-3.296 4.611-4.944l2.305-2.472c.343-.366 1.124-1.536 1.588-1.51.685.039 1.877 1.475 2.38 1.91.736.633 1.46 1.277 2.17 1.935a83.096 83.096 0 0 1 4.786 4.818c.77.838 2.255.824 3.03 0l6.151-6.57c1.103-1.18 2.21-2.36 3.312-3.536.474-.504.946-1.011 1.42-1.515.168-.18 1.192-1.159 1.017-1.133-.303-.015-.207.079.29.291.335.277.67.558 1.002.842a112.96 112.96 0 0 1 4.625 4.148 115.396 115.396 0 0 1 6.166 6.325c.821.903 2.184.738 3.03 0 4.068-3.55 8.111-7.141 12.211-10.657.329-.28.807-.91 1.267-.91.336 0 .95.799 1.185 1.008.953.842 1.91 1.68 2.862 2.522 2.08 1.835 4.164 3.666 6.245 5.5 2.066 1.821 5.106-1.219 3.03-3.054C75.706 8.041 73.1 5.75 70.5 3.458c-1.992-1.752-4.072-4.242-6.991-3.21-1.292.457-2.337 1.548-3.347 2.429-1.239 1.083-2.477 2.162-3.715 3.245-2.655 2.317-5.31 4.634-7.962 6.951h3.03a116.297 116.297 0 0 0-7.362-7.44 105.67 105.67 0 0 0-3.929-3.454C39.097 1.044 37.92.02 36.364.025c-1.446.008-2.563.9-3.53 1.875-1.3 1.306-2.527 2.698-3.786 4.044l-7.57 8.088h3.03a83.762 83.762 0 0 0-5.98-5.904c-1.688-1.5-3.822-3.933-6.217-4.091-2.54-.165-4.153 2.166-5.702 3.828-1.999 2.14-3.997 4.285-5.995 6.426-1.906 2.011 1.117 5.073 3.011 3.044Z'
        fill={props.color || 'white'}
      />
    </g>
    <defs>
      <clipPath id='Vector'>
        <path fill='#fff' d='M0 0h79v18H0z' />
      </clipPath>
    </defs>
  </svg>
)

export default Vector
