import * as React from 'react'

const Alert = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 43 43'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.753 7.904a1.433 1.433 0 0 0-2.506 0l-14.333 25.8c-.531.956.16 2.13 1.253 2.13h28.666a1.433 1.433 0 0 0 1.253-2.13l-14.333-25.8ZM9.603 32.967 21.5 11.552l11.897 21.415H9.603Zm10.464-14.333a1.433 1.433 0 1 1 2.866 0v5.733a1.433 1.433 0 1 1-2.866 0v-5.733Zm1.433 8.6c-.792 0-1.433.641-1.433 1.433V30.1a1.433 1.433 0 1 0 2.866 0v-1.433c0-.792-.641-1.433-1.433-1.433Z'
      fill={props.color || '#000'}
    />
    <mask
      id='alert'
      style={{
        maskType: 'alpha'
      }}
      maskUnits='userSpaceOnUse'
      x={5}
      y={7}
      width={33}
      height={29}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.753 7.904a1.433 1.433 0 0 0-2.506 0l-14.333 25.8c-.531.956.16 2.13 1.253 2.13h28.666a1.433 1.433 0 0 0 1.253-2.13l-14.333-25.8ZM9.603 32.967 21.5 11.552l11.897 21.415H9.603Zm10.464-14.333a1.433 1.433 0 1 1 2.866 0v5.733a1.433 1.433 0 1 1-2.866 0v-5.733Zm1.433 8.6c-.792 0-1.433.641-1.433 1.433V30.1a1.433 1.433 0 1 0 2.866 0v-1.433c0-.792-.641-1.433-1.433-1.433Z'
        fill='#fff'
      />
    </mask>
    <g mask='url(#alert)'>
      <path fill={props.color || '#FA9F89'} d='M0 0h43v43H0z' />
    </g>
  </svg>
)

export default Alert
