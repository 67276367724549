import * as React from 'react'

const Understood = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 33 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M16.054 32.108c8.867 0 16.054-7.187 16.054-16.054C32.108 7.188 24.921 0 16.054 0 7.188 0 0 7.188 0 16.054c0 8.867 7.188 16.054 16.054 16.054Z'
      fill={props.color || '#FF0'}
    />
    <path
      d='M17.131 26.078v-4.523a4.962 4.962 0 0 0 4.957-4.956h4.523c0 5.225-4.255 9.479-9.48 9.479ZM11.68 9.92H7.159v7.202h4.523V9.92ZM18.763 9.92H14.24v7.202h4.523V9.92Z'
      fill='#000'
    />
  </svg>
)

export default Understood
