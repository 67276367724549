//@ts-check
import React from 'react'
import PrivateRoute from '../PrivateRoute'
import Header from './Header'
import LateralMenu from './LateralMenu'
import styles from './Layout.module.css'

function Layout({ context = {} }) {
  return (
    <main className={styles.main}>
      <Header />
      <div className={styles.containerWithMenu}>
        <div className={styles.lateral}>
          <LateralMenu />
        </div>
        <div className={styles.content}>
          <PrivateRoute context={context} />
        </div>
      </div>
    </main>
  )
}

export default Layout
