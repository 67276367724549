import React from 'react'
import StarIcon from 'assets/icons/Star'
import BinIcon from 'assets/icons/Bin'
import { getExpirationDate } from 'utils/date'
import styles from './CreditCard.module.css'

function CreditCard({
  paymentMethod,
  favourite = false,
  onFavourite = noop,
  onDelete = noop
}) {
  return (
    <div
      className={`${styles.creditCard} ${
        favourite ? styles['creditCardFavourite'] : ''
      }`}
    >
      <div className={styles.cardTop}>
        <span
          className={[
            styles.creditCardBrandIcon,
            styles[`brand--${paymentMethod.card.brand}`] || styles.defaultImage
          ].join(' ')}
          title={paymentMethod.card.brand}
        />

        <div className={styles.cardIconAction}>
          <StarIcon
            className={styles.starIcon}
            stroke='var(--seadapted-50)'
            fill={!favourite ? 'var(--bright)' : 'var(--seadapted-50)'}
            onClick={() => !favourite && onFavourite(paymentMethod.id)}
          />
          <BinIcon
            className={styles.binIcon}
            color='var(--error-color)'
            stroke='var(--error-color)'
            onClick={() => onDelete(paymentMethod.id)}
          />
        </div>
      </div>
      <div className={styles.cardBottom}>
        <p className={styles.cardNumber}>
          **** **** **** {paymentMethod.card.last4}
        </p>
        <div className={styles.cardInfo}>
          <p className={styles.cardHolder}>
            {paymentMethod.billing_details.name}
          </p>
          <p className={styles.expiryDate}>
            {getExpirationDate(
              paymentMethod.card.exp_month,
              paymentMethod.card.exp_year
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CreditCard
