import * as React from 'react'

const Blackboard = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 37 37'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M27.608 0a4.789 4.789 0 0 0-3.322 1.322l-12.05 11.61C10.8 14.306 9.994 16.177 10 18.127v2.787c0 1.35.556 2.643 1.547 3.597A5.386 5.386 0 0 0 15.283 26h2.893a7.713 7.713 0 0 0 5.394-2.154l12.057-11.603c.88-.848 1.374-2 1.373-3.199 0-2.398-.99-4.698-2.75-6.394C32.487.953 30.1 0 27.61 0h-.002Zm-7.36 20.652a2.994 2.994 0 0 1-2.072.826h-2.894a.576.576 0 0 1-.587-.566v-2.786c0-.748.308-1.466.857-1.995L27.608 4.52c1.245 0 2.44.477 3.32 1.325a4.438 4.438 0 0 1 1.376 3.197l-12.057 11.61Z'
      fill={props.color || '#fff'}
    />
    <path
      d='M10.587 36.656c2.674.441 5.691.332 8.4.332 2.708 0 5.141.109 7.814-.332a12.227 12.227 0 0 0 6.574-3.375 11.84 11.84 0 0 0 3.348-6.496c.1-.607.187-1.208.263-1.815.073-.65-.14-1.3-.586-1.785a2.356 2.356 0 0 0-1.749-.757 2.361 2.361 0 0 0-1.564.577 2.277 2.277 0 0 0-.77 1.455 43.116 43.116 0 0 1-.257 1.718 7.158 7.158 0 0 1-2.004 3.9 7.391 7.391 0 0 1-3.932 2.049 45.47 45.47 0 0 1-7.43.607 45.741 45.741 0 0 1-7.342-.573 7.392 7.392 0 0 1-4.023-2.015A7.157 7.157 0 0 1 5.27 26.2a43.024 43.024 0 0 1 0-14.504 7.154 7.154 0 0 1 2.09-3.881 7.387 7.387 0 0 1 3.998-1.97 62.811 62.811 0 0 1 1.751-.252 2.347 2.347 0 0 0 1.48-.757c.38-.423.59-.969.586-1.533a2.27 2.27 0 0 0-.77-1.714 2.365 2.365 0 0 0-1.816-.577c-.618.075-1.231.155-1.844.252a12.245 12.245 0 0 0-6.628 3.283 11.855 11.855 0 0 0-3.446 6.45 47.49 47.49 0 0 0 0 15.912 11.7 11.7 0 0 0 3.354 6.444 12.085 12.085 0 0 0 6.562 3.302Z'
      fill={props.color || '#fff'}
    />
  </svg>
)

export default Blackboard
