import * as React from 'react'

const Type = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 17 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2.498 13h1.456a.995.995 0 0 0 .694-.266c.192-.176.316-.422.347-.69a1.099 1.099 0 0 0-.256-.816.975.975 0 0 0-.743-.346H2.498c-.276 0-.5-.237-.5-.53V6.648c0-.292.224-.53.5-.53h1.456a.995.995 0 0 0 .694-.265c.192-.177.316-.422.347-.69a1.1 1.1 0 0 0-.256-.816A.974.974 0 0 0 3.996 4H2.498c-.663 0-1.298.279-1.766.775A2.729 2.729 0 0 0 0 6.647v3.706c0 .702.263 1.375.732 1.872A2.428 2.428 0 0 0 2.498 13ZM14.502 4h-1.456a.995.995 0 0 0-.694.266 1.111 1.111 0 0 0-.347.69c-.028.298.065.595.256.816.19.222.46.347.743.346h1.499c.132 0 .259.056.353.155.093.1.146.234.146.374v3.706c0 .14-.053.275-.146.374a.486.486 0 0 1-.354.155h-1.456a.995.995 0 0 0-.694.266 1.112 1.112 0 0 0-.347.69c-.028.298.065.595.256.816.19.222.46.348.743.346h1.499c.662 0 1.297-.279 1.765-.775A2.73 2.73 0 0 0 17 10.353V6.647a2.73 2.73 0 0 0-.732-1.871A2.428 2.428 0 0 0 14.502 4Z'
      fill={props.color || '#fff'}
    />
    <path
      d='M10.833 2.125c.417 0 .802-.202 1.01-.531a.98.98 0 0 0 0-1.063A1.192 1.192 0 0 0 10.834 0C9.971 0 9.14.292 8.5.818A3.682 3.682 0 0 0 6.167 0c-.417 0-.802.203-1.01.531a.981.981 0 0 0 0 1.063c.208.329.593.531 1.01.531.309 0 .606.112.825.311.218.2.341.47.341.751v10.626c0 .281-.123.551-.341.75-.22.2-.516.312-.825.312-.417 0-.802.203-1.01.531a.981.981 0 0 0 0 1.063c.208.328.593.531 1.01.531.862 0 1.693-.292 2.333-.818.64.526 1.471.818 2.333.818.417 0 .802-.203 1.01-.531a.98.98 0 0 0 0-1.063 1.191 1.191 0 0 0-1.01-.531c-.309 0-.606-.112-.825-.311-.218-.2-.341-.47-.341-.752V3.188c0-.281.123-.552.341-.75.22-.2.516-.312.825-.312Z'
      fill={props.color || '#fff'}
    />
  </svg>
)

export default Type
