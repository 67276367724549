import React, { useState } from 'react'
import Check from 'assets/icons/Check'
import styles from './Stepper.module.css'

/**
 * @typedef StepperProps
 * @property {{title:string, component?: React.ReactNode }[]} steps
 * @property {number} [activeStep]
 * @property {React.ReactNode} [bottomToolbar]
 * @property {(index: number) => void} [onChange]
 */

/** @param {StepperProps} */
function Stepper({
  steps = [
    { title: 'Paso 1', component: <div>Fill with your component</div> },
    { title: 'Paso 2' },
    { title: 'Paso 3' }
  ],
  activeStep,
  bottomToolbar = null,
  onChange
}) {
  const [currentStep, setCurrentStep] = useState(0)
  const targetStep = activeStep ?? currentStep
  const handleStepClick = index => {
    if (onChange) onChange(index)
    setCurrentStep(index)
  }
  return (
    <div className={styles.stepper}>
      <div className={styles.stepperContainer}>
        {steps.map(({ title }, index) => (
          <div
            key={index}
            className={[
              styles.step,
              index === targetStep ? styles.active : ''
            ].join(' ')}
            onClick={() => handleStepClick(index)}
          >
            <div className={styles.circle}>
              {targetStep > index ? (
                <Check className={styles.checkIconWrapper} />
              ) : (
                index + 1
              )}
            </div>
            {title}
          </div>
        ))}
      </div>
      <div className={styles.stepContent}>{steps[targetStep]?.component}</div>
      {bottomToolbar}
    </div>
  )
}

export default Stepper
