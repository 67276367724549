//@ts-check
import React from 'react'
import Phone from 'react-phone-input-2'
import styles from './PhoneInput.module.css'

/**
 * @typedef CustomPhoneInputStyles
 * @property {string} phoneContainer
 * @property {string} inputClass
 * @property {string} buttonClass
 * @property {string} dropdownClass
 */
/**
 * @typedef PhoneInputProps
 * @property {string} [name] string can contain dots
 * @property {import('utils').PhoneNumberEvent['fullPhoneNumber']} value
 * @property {boolean} [disabled]
 * @property {boolean} [error]
 * @property {Partial<CustomPhoneInputStyles>} [customPhoneInputStyles]
 * @property {(path: string, value: string, data: {} | import('react-phone-input-2').CountryData,
 * event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => void} onPhoneNumberChange
 */

/**
 *
 * @param {PhoneInputProps} params
 */
function PhoneInput({
  name = 'phoneNumber',
  value,
  disabled,
  error,
  customPhoneInputStyles,
  onPhoneNumberChange
}) {
  return (
    <Phone
      containerClass={[
        styles.phoneContainer,
        customPhoneInputStyles?.phoneContainer || ''
      ].join(' ')}
      inputClass={[
        styles.phoneInput,
        disabled ? styles.phoneInputDisabled : '',
        error ? styles.phoneInputError : '',
        customPhoneInputStyles?.inputClass || ''
      ].join(' ')}
      buttonClass={[
        styles.phoneButton,
        disabled ? styles.phoneButtonDisabled : '',
        customPhoneInputStyles?.buttonClass || ''
      ].join(' ')}
      dropdownClass={[
        styles.phoneDropdown,
        customPhoneInputStyles?.dropdownClass || ''
      ].join(' ')}
      country='es'
      localization={{ es: 'España' }}
      preferredCountries={['es']}
      placeholder='Número de teléfono'
      specialLabel=''
      value={value}
      countryCodeEditable={false}
      disabled={disabled}
      onChange={(...phoneNumberEvent) =>
        onPhoneNumberChange(name, ...phoneNumberEvent)
      }
    />
  )
}

export default PhoneInput
