import * as React from 'react'

const Image = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M22.997 1h-9.994C6.385 1 1 6.384 1 13.003v9.994C1 29.616 6.384 35 13.003 35h9.994C29.615 35 35 29.616 35 22.997v-9.994C35 6.385 29.616 1 22.997 1Zm-9.994 2.59h9.994c5.19 0 9.413 4.223 9.413 9.414v9.993a9.41 9.41 0 0 1-.179 1.82l-5.274-3.101c-1.454-.852-3.603-.786-5.001.153l-4.396 2.962c-.47.316-1.267.242-1.67-.153l-6.173-6.1c-1.287-1.264-3.453-1.447-4.93-.417l-1.196.83v-5.988c0-5.19 4.222-9.413 9.413-9.413Zm9.994 28.82h-9.994c-5.19 0-9.413-4.223-9.413-9.413v-.85l2.676-1.86c.457-.318 1.237-.251 1.633.137l6.173 6.1c1.279 1.255 3.445 1.456 4.933.457l4.396-2.962c.566-.38 1.657-.413 2.244-.07l5.714 3.36c-1.567 3.026-4.726 5.1-8.363 5.1Z'
      fill={props.color || '#fff'}
      stroke={props.color || '#fff'}
      strokeWidth={2}
    />
    <path
      d='M23.972 15.944a3.976 3.976 0 0 0 3.972-3.972A3.976 3.976 0 0 0 23.972 8 3.977 3.977 0 0 0 20 11.972a3.976 3.976 0 0 0 3.972 3.972Zm0-5.353c.762 0 1.382.62 1.382 1.382 0 .761-.62 1.381-1.382 1.381-.762 0-1.382-.62-1.382-1.381 0-.762.62-1.382 1.382-1.382Z'
      fill={props.color || '#fff'}
    />
  </svg>
)

export default Image
