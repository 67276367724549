import React from 'react'
import styles from './Tag.module.css'

const Tag = ({ label = '', className = '' }) => {
  return (
    <div className={[styles.tag, className].join(' ')}>
      <span className={styles.span}>{label}</span>
    </div>
  )
}
export default Tag
