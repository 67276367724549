//@ts-check
import React, { useEffect, useMemo } from 'react'
import { identity } from 'ramda'
import useSubjects from 'hooks/useSubjects'
import Select from '../Select'

/**
 * @typedef SelectSubjectProps
 * @property {boolean} showLength
 * @property {(subjects: array) => array} filter
 * @property {(subjectsById: object) => void} extractSubjectsById
 * @property {boolean} useExternalApi
 */

/** @param {Partial<import('../Select').SelectProps & SelectSubjectProps>} props */
function SubjectSelect({
  name = 'subjectId',
  label = 'Asignaturas',
  placeholder = 'Asignaturas',
  showLength = false,
  filter = identity,
  extractSubjectsById = identity,
  useExternalApi = false,
  ...rest
}) {
  const {
    subjects = [],
    subjectsById,
    isFetching
  } = useSubjects({ useExternalApi })
  const targetSubjects = useMemo(() => {
    return filter(subjects)
  }, [filter, subjects])
  useEffect(() => {
    extractSubjectsById(subjectsById)
  }, [extractSubjectsById, subjectsById])
  return (
    <Select
      {...rest}
      name={name}
      label={label}
      placeholder={
        showLength ? placeholder + ` (${targetSubjects.length})` : placeholder
      }
      options={targetSubjects}
      isLoading={isFetching}
    />
  )
}

export default SubjectSelect
