//@ts-check
import React from 'react'
import Select from '../Select'
import { MONTHS } from 'utils/date'

/** @param {Partial<import('../Select').SelectProps>} props */
function MonthSelect({
  name = 'month',
  value,
  label = 'Mes',
  placeholder = 'Mes',
  options = MONTHS,
  onChange,
  ...rest
}) {
  const handleChange = e => {
    if (onChange) {
      const selectedIndex = options.findIndex(month => month === e.target.value)
      onChange({
        target: {
          ...e.target,
          value: selectedIndex + 1
        }
      })
    }
  }

  return (
    <Select
      {...rest}
      name={name}
      value={options[value - 1]}
      label={label}
      placeholder={placeholder}
      options={options}
      onChange={handleChange}
      transformList
    />
  )
}

export default MonthSelect
