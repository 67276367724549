//@ts-check
import React from 'react'
import Lightbulb from 'assets/icons/Lightbulb'
import styles from './Lightbulb.module.css'

export const lightbulbId = 'lightbulb'

export const lightbulbStatusId = {
  OFF: 'OFF',
  ON: 'ON',
  STANDBY: 'STANDBY'
}

export const lightbulbColorByStatus = {
  OFF: 'var(--bright)',
  ON: 'var(--seadapted)',
  STANDBY: 'var(--sandground)'
}

function ClassroomLightbulb({
  lightbulbStatus,
  isActive,
  disabled = false,
  onClick
}) {
  const isSelected = lightbulbStatus.id === lightbulbId

  const getCalculatedStatus = () => {
    if (isActive) return lightbulbStatusId.ON
    if (lightbulbStatus.status === lightbulbStatusId.ON)
      return lightbulbStatusId.STANDBY
    return lightbulbStatusId.OFF
  }

  return (
    <div
      className={styles.lightbulb}
      onClick={() => !disabled && onClick(lightbulbId)}
    >
      <div
        className={[
          styles.innerContainer,
          isSelected && getCalculatedStatus() === lightbulbStatusId.ON
            ? styles.on
            : ''
        ].join(' ')}
        data-status={disabled}
      >
        <Lightbulb
          color={
            isSelected ? lightbulbColorByStatus[getCalculatedStatus()] : ''
          }
        />
      </div>
    </div>
  )
}

export default ClassroomLightbulb
