//@ts-check
import React, { useCallback, useState } from 'react'
import { isEmpty, uniq, without } from 'ramda'
import { DAY_NAME_BY_NUMBER, DEFAULT_UNIT_HOURS } from 'utils/date'
import styles from './MonthlyCalendar.module.css'
import { noop } from 'utils'

/**
 * @typedef MonthlyCalendarProps
 * @property {{[day: string]: string[]}} values
 * @property {{[dayLabel:string]: string[]}} options
 * @property {boolean} disabled
 * @property {Function} onChange
 */

/** @param {Partial<MonthlyCalendarProps>} props*/
function MonthlyCalendar({
  values = {},
  options = {},
  disabled = false,
  onChange = noop
}) {
  return (
    <div
      className={[styles.container, disabled ? styles.disabled : ''].join(' ')}
    >
      <div className={styles.calendar}>
        {DAY_NAME_BY_NUMBER.map(({ label, day }) => {
          return (
            <HourSelector
              key={day}
              label={label}
              dayNumber={day}
              hours={options[day] || []}
              selectedOptions={values}
              onChange={(...data) => (disabled ? noop() : onChange(...data))}
            />
          )
        })}
      </div>
    </div>
  )
}

export default MonthlyCalendar

function HourSelector({
  label,
  dayNumber,
  hours = [],
  selectedOptions,
  onChange
}) {
  const selectedHours = selectedOptions[dayNumber] || []
  const disabledAll = isEmpty(hours)

  return (
    <div className={styles.hourSection}>
      <p className={[styles.hourSectionTitle].join(' ')}>
        {label.substring(0, 3)}
      </p>
      <div className={styles.hourContainer}>
        {uniq(
          DEFAULT_UNIT_HOURS.am
            .concat(DEFAULT_UNIT_HOURS.pm)
            .concat(hours)
            .sort()
        ).map((hour, index) => {
          const disabled = !hours.includes(hour)
          return (
            <p
              key={`${label}-${index}`}
              className={[
                styles.hour,
                selectedHours.includes(hour) ? styles.selectedHour : '',
                disabledAll ? styles.disabledHour : '',
                disabled ? styles.disabledHour : ''
              ].join(' ')}
              onClick={() =>
                !disabledAll && !disabled && onChange(hour, dayNumber)
              }
            >
              {hour}
            </p>
          )
        })}
      </div>
    </div>
  )
}
