export const emitIdentity = async ({ socket, data }) => {
  return new Promise((resolve, reject) => {
    socket.emit('connection:identity', data, res => {
      if (res.status !== 'OK') reject(res)
      else resolve(true)
    })
  })
}
export const reconnectSocket = ({ socket, data }) => {
  return new Promise((resolve, reject) => {
    socket.emit('reconnect-socket', data, res => {
      if (res.status !== 'OK') reject(res)
      else resolve(true)
    })
  })
}

export const requestTeacherList = async ({ socket }) => {
  return new Promise((resolve, reject) => {
    socket.emit('academy:teachers:list', {}, res => {
      if (res.status !== 'OK') reject()
      else resolve(res.data)
    })
  })
}

export const joinTeacherClass = async ({ socket, teacher }) => {
  return new Promise((resolve, reject) => {
    socket.emit('academy:teachers:join', teacher, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const leaveTeacherClass = async ({ socket, teacher }) => {
  return new Promise((resolve, reject) => {
    socket.emit('academy:teachers:leave', teacher, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const sendLogMessage = async ({ socket, message }) => {
  return new Promise((resolve, reject) => {
    socket.emit('log:message', message, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const sendPeerSignal = async ({ socket, signal, teacher }) => {
  return new Promise((resolve, reject) => {
    socket.emit('classroom:students:peer-signal', { signal, teacher }, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}
export const disconnectStudent = async socket => {
  return new Promise((resolve, reject) => {
    socket.emit('academy:students:disconnected', socket, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const sendLightbulbChange = async ({ socket, id, status, teacher }) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:students:lightbulb',
      { id, status, teacher },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendWhiteboardLine = async ({
  teacher,
  socket,
  x0,
  y0,
  x1,
  y1,
  color,
  lineWidth,
  saveContext = false
}) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:students:whiteboard-line',
      { teacher, x0, y0, x1, y1, color, lineWidth, saveContext },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendNotebookPageChange = async ({ socket, teacher, page }) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:students:notebook-page-change',
      { page, teacher },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendImageIndexChange = async ({ socket, teacher, imageIndex }) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:students:image-index-change',
      { imageIndex, teacher },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendImageLine = async ({
  teacher,
  socket,
  x0,
  y0,
  x1,
  y1,
  color,
  lineWidth,
  saveContext = false
}) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:students:image-line',
      { teacher, x0, y0, x1, y1, color, lineWidth, saveContext },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}

export const sendSelectedTabChange = async ({ socket, tabId, teacher }) => {
  return new Promise((resolve, reject) => {
    socket.emit('classroom:students:selected-tab', { tabId, teacher }, res => {
      if (res.status !== 'OK') reject()
      else resolve(true)
    })
  })
}

export const sendChatMessage = async ({ socket, teacher, message }) => {
  return new Promise((resolve, reject) => {
    socket.emit(
      'classroom:students:chat-message',
      { teacher, message },
      res => {
        if (res.status !== 'OK') reject()
        else resolve(true)
      }
    )
  })
}
