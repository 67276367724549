import * as React from 'react'

const Lightbulb = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 31 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.235 15.642a9.005 9.005 0 0 0 4.375 7.766v1.13a1.094 1.094 0 0 0-.657 1.167l.328 2.406a5.03 5.03 0 0 0 9.954 0l.328-2.406a1.093 1.093 0 0 0-.656-1.167v-1.13a9.08 9.08 0 1 0-13.672-7.766Zm11.85 12.178a2.844 2.844 0 0 1-5.616 0l-.182-1.167h5.98l-.183 1.167Zm-3.392-12.142a.584.584 0 1 1 1.168 0 .584.584 0 0 1-1.168 0Zm.584-6.89a6.89 6.89 0 0 1 3.062 13.015 1.093 1.093 0 0 0-.583 1.058v1.568H16.37V18.23a2.771 2.771 0 1 0-2.187 0v6.234h-1.386v-1.568a1.094 1.094 0 0 0-.583-1.057 6.89 6.89 0 0 1 3.063-13.053ZM16.37 4.156V1.094a1.094 1.094 0 1 0-2.187 0v3.062a1.094 1.094 0 0 0 2.188 0Z'
      fill={props.color || '#fff'}
    />
    <path
      d='M29.46 14.183h-3.063a1.094 1.094 0 0 0 0 2.187h3.063a1.094 1.094 0 1 0 0-2.187ZM1.094 14.183a1.094 1.094 0 0 0 0 2.187h3.062a1.094 1.094 0 1 0 0-2.187H1.094ZM26.069 4.484a1.094 1.094 0 0 0-1.532 0l-2.15 2.152a1.094 1.094 0 1 0 1.53 1.53l2.152-2.15a1.094 1.094 0 0 0 0-1.532ZM6.016 4.485a1.094 1.094 0 1 0-1.532 1.532l2.151 2.15a1.093 1.093 0 1 0 1.532-1.53L6.016 4.484Z'
      fill={props.color || '#fff'}
    />
  </svg>
)

export default Lightbulb
