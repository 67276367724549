import { makeCustomQueryAndProject } from 'utils/queries'
import config from 'config'
import { BASE_URL, GET, makeError, processResponse } from './index'

export const findSubjectsForExternal = (query = {}, project = []) => {
  return fetch(
    BASE_URL +
      makeCustomQueryAndProject(query, project, 'classfy/services/subjects'),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${config.externalServicesToken}`
      }
    }
  )
    .then(async response => {
      return await processResponse(response)
    })
    .catch(e => {
      Sentry.captureException(e)
      throw makeError(e)
    })
}

export const findSubjects = (query = {}, project = []) => {
  return GET({
    url: makeCustomQueryAndProject(query, project, 'students/subjects/list')
  })
}
