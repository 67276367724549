import * as React from 'react'
const Check = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 32 32'
    fill='none'
    {...props}
  >
    <title>{'checkmark'}</title>
    <path fill={props.color || '#fff'} d='M27 4 12 19l-7-7-5 5 12 12L32 9z' />

    <path
      stroke={props.color || '#fff'}
      strokeWidth={0.5}
      d='M27 4 12 19l-7-7-5 5 12 12L32 9z'
    />
  </svg>
)
export default Check
