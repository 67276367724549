//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import BrightClassfySvg from 'assets/logos/main/BrightClassfy'
import NoWorries from 'assets/svg/NoWorries'
import { executeAutoLogin } from 'api/auth'
import { useNotificationActions } from 'context/NotificationProvider'
import { useAuth } from 'context/AuthProvider'
import RingsSpinner from 'components/spinners/RingsSpinner'
import { H2 } from 'components/typography'
import Button from 'components/buttons/Button'
import DotsSpinner from 'components/spinners/DotsSpinner'
import styles from './AutoLogin.module.css'

function AutoLogin() {
  const [step, setStep] = useState(0)
  const [isExecuting, setIsExecuting] = useState(true)
  const { setErrorMessage } = useNotificationActions()
  const params = useParams()
  const { student, saveAuthentication } = useAuth()

  const execute = useCallback(() => {
    setIsExecuting(true)
    setStep(0)
    executeAutoLogin(params)
      .then(data => {
        if (!data || typeof data !== 'object') throw new Error('No auth data!')
        saveAuthentication(data)
        setTimeout(() => {
          setIsExecuting(false)
          setStep(1)
        }, 3000)
      })
      .catch(() =>
        setTimeout(() => {
          setIsExecuting(false)
          setErrorMessage({
            message:
              'Upps!, parece que no te identificamos. Ponte en contacto con soporte'
          })
          setStep(2)
        }, 3000)
      )
  }, [params, saveAuthentication, setErrorMessage])

  useEffect(() => {
    execute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main className={styles.main}>
      <Link to='/login'>
        <BrightClassfySvg className={styles.classfyLogo} />
      </Link>
      <div className={styles.container}>
        <div className={styles.images}>
          <NoWorries
            className={styles.backgroundSvg}
            color='var(--sandground)'
          />
          <NoWorries className={styles.mainSvg} />
          {isExecuting && (
            <div className={styles.spinner}>
              <RingsSpinner />
            </div>
          )}
        </div>
        <div className={styles.innerContainer}>
          {step === 0 && (
            <>
              <H2 className={styles.h2}>
                Estamos verificando tu <span>identidad</span>
                <DotsSpinner />
              </H2>
            </>
          )}
          {step === 1 && (
            <>
              <H2 className={styles.h2}>
                ¡Bienvenido, <span>{student.shortName}</span>!
              </H2>
              <Link to='/'>
                <Button label='Acceder' type='accent-primary' />
              </Link>
            </>
          )}
          {step === 2 && (
            <>
              <H2 className={styles.h2}>
                Lo sentimos, parece que tu <span>enlace</span> de acceso ha{' '}
                <span>caducado</span>
              </H2>
              <Button label='Volver a intentar' onClick={execute} />
            </>
          )}
        </div>
      </div>
    </main>
  )
}

export default AutoLogin
