import * as React from 'react'

const Eraser = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M5.32 15.04a.9.9 0 0 0 .636.263h3.185a.9.9 0 0 0 .636-.263l7.96-7.95a.898.898 0 0 0 0-1.272L12.164.253a.927.927 0 0 0-1.272 0L1.34 9.793a.898.898 0 0 0 0 1.272l3.98 3.975Zm6.208-12.88 4.3 4.294-1.358 1.355-4.299-4.294 1.357-1.356Zm-2.63 2.626 4.3 4.295-4.43 4.424H6.33L3.25 10.43 8.9 4.786ZM18 17.101a.898.898 0 0 1-.9.899h-2.7a.9.9 0 0 1-.78-1.348.9.9 0 0 1 .78-.45h2.7a.9.9 0 0 1 .9.9Zm-6.3 0a.898.898 0 0 1-.9.899H.9a.9.9 0 0 1-.78-1.348.9.9 0 0 1 .78-.45h9.9a.899.899 0 0 1 .9.9Z'
      fill={props.color || '#fff'}
    />
  </svg>
)

export default Eraser
