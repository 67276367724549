import { PUT, POST } from './index'

export const updateProfile = (data = {}) =>
  PUT({ url: 'students/account/profile', data })

export const updateBillingData = (data = {}) =>
  PUT({ url: 'students/account/billing-data', data })

export const changePassword = (data = { oldPassword: '', newPassword: '' }) =>
  POST({ url: 'students/account/update-password', data })

/**
 *
 * @param {{photo: File}} data
 */
export const uploadProfilePicture = (data = {}) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })
  return POST({
    url: 'students/account/profile-picture',
    data: formData,
    useAcceptAPP_JSON: true
  })
}
