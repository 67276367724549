//@ts-check
import React, { useState } from 'react'
import Select from '../Select'
import { times } from 'ramda'

/** @param {Partial<import('../Select').SelectProps &{initialYear:number}>} props */

function YearSelect({
  name = 'year',
  value,
  label = 'Año',
  placeholder = 'Año',
  initialYear = 2020,
  onChange,
  ...rest
}) {
  const currentYear = new Date().getFullYear()

  const length = currentYear - initialYear + 1
  const years = times(i => currentYear - i, length)
  return (
    <Select
      {...rest}
      name={name}
      value={value}
      label={label}
      placeholder={placeholder}
      options={years}
      onChange={onChange}
      transformList
    />
  )
}

export default YearSelect
