//@ts-check
import React from 'react'
import styles from './styles.module.css'

// TODO: think about props when design system is done
export function H1({ children, className = '' }) {
  return <h1 className={[styles.h1, className].join(' ')}>{children}</h1>
}
export function H2({ children, className = '' }) {
  return <h2 className={[styles.h2, className].join(' ')}>{children}</h2>
}
export function H3({ children, className = '' }) {
  return <h3 className={[styles.h3, className].join(' ')}>{children}</h3>
}
export function H4({ children, className = '' }) {
  return <h4 className={[styles.h4, className].join(' ')}>{children}</h4>
}
export function H4Light({ children, className = '' }) {
  return <h4 className={[styles.h4Light, className].join(' ')}>{children}</h4>
}
export function H5({ children, className = '' }) {
  return <h5 className={[styles.h5, className].join(' ')}>{children}</h5>
}

/**
 * @typedef ParagraphProps
 * @property {React.ReactNode} children
 * @property {'body1' | 'body1Bold' | 'body2' | 'body2Bold'} [type]
 * @property {string} [className]
 *  */
/**
 * @param {ParagraphProps} props
 */
export function Paragraph({ children, type = 'body1', className = '' }) {
  return <p className={[styles[type], className].join(' ')}>{children}</p>
}
export function Label({ children, className = '' }) {
  return <span className={[styles.label, className].join(' ')}>{children}</span>
}
