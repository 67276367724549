// @ts-check
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { Outlet } from 'react-router-dom'
import { gapi } from 'gapi-script'
import { fetchMe } from 'api/auth'
import { isAnAdult } from 'utils/date'
import { AccessTypes } from 'utils/constants'
import { clearLocalStorageExcept } from 'utils'
import useCustomNavigate from 'hooks/useCustomNavigate'
import CONFIG from 'config'

const AuthContext = createContext(null)

function makePictureUrl(url) {
  if (!url) return ''
  return url.split('?v')[0] + `?v=${Date.now()}`
}

function makeCustomAttrs(student) {
  student.isAnAdult = isAnAdult(student.dateOfBirth)
  let name = student?.name?.split(' ')?.[0]
  student.shortName = name
    ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
    : '???'
  student.isNewUser = student?.accessType === AccessTypes.TRIAL
  student.profileUrl = makePictureUrl(student.profileUrl)
  student.profileThumbnailUrl = makePictureUrl(student.profileThumbnailUrl)
  return student
}

const JWT_ERROR = 'jwtError'

function AuthProvider() {
  const [isFetchingMe, setIsFetchingMe] = useState(true)
  const [student, setStudent] = useState({})
  const navigate = useCustomNavigate()

  const isAuthenticated = !!localStorage.getItem(CONFIG.accessTokenKey)

  const handleLogout = useCallback(() => {
    localStorage.removeItem(CONFIG.accessTokenKey)
    gapi.auth2?.getAuthInstance()?.signOut()
    clearLocalStorageExcept([
      CONFIG.hasMediaPermissionsKey,
      CONFIG.isVideoTourViewedKey
    ])
    setStudent({})
    navigate('/login')
  }, [navigate])

  const handleFetchMe = useCallback(() => {
    setIsFetchingMe(true)
    fetchMe({ full: true })
      .then(student => {
        if (!student.active) return handleLogout()
        makeCustomAttrs(student)
        return student
      })
      .then(setStudent)
      .catch(e => {
        console.error('Error fetching me: ', e)
        if (e.code === JWT_ERROR && isAuthenticated) handleLogout()
      })
      .finally(() => setIsFetchingMe(false))
  }, [handleLogout, isAuthenticated])

  const saveAuthentication = useCallback(({ token, student }) => {
    setStudent(makeCustomAttrs(student))
    localStorage.setItem(CONFIG.accessTokenKey, token)
  }, [])

  useEffect(() => {
    handleFetchMe()
  }, [handleFetchMe])
  if (isFetchingMe) return null
  return (
    <AuthContext.Provider
      // @ts-ignore
      value={{
        student,
        isFetchingMe,
        isAuthenticated,
        handleFetchMe,
        setStudent: student =>
          setStudent(currentStudent =>
            makeCustomAttrs({ ...currentStudent, ...student })
          ),
        saveAuthentication,
        handleLogout
      }}
    >
      <Outlet />
    </AuthContext.Provider>
  )
}

export default AuthProvider

/**
 *
 * @returns {{
 * student: object
 * isFetchingMe: boolean
 * isAuthenticated: boolean
 * handleFetchMe: ()=> Promise<object>
 * setStudent: (student: object)=> void
 * saveAuthentication: ({token, student}: {token:string, student:object})=> void
 * handleLogout: ()=> void
 * }}
 */
// @ts-ignore
export const useAuth = () => useContext(AuthContext)
