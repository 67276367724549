//@ts-check
import Back from 'assets/icons/Back'
import EraseAll from 'assets/icons/EraseAll'
import Eraser from 'assets/icons/Eraser'
import Forward from 'assets/icons/Forward'
import Pencil from 'assets/icons/Pencil'
import Type from 'assets/icons/Type'
import React from 'react'
import styles from './Toolbar.module.css'

export const classroomToolbarIds = {
  draw: 'draw',
  type: 'type',
  erase: 'erase',
  pop: 'pop',
  push: 'push',
  eraseAll: 'eraseAll'
}
const OPTIONS = [
  { id: classroomToolbarIds.draw, label: 'Dibujar', Icon: Pencil },
  { id: classroomToolbarIds.type, label: 'Escribir', Icon: Type },
  { id: classroomToolbarIds.erase, label: 'Borrar', Icon: Eraser }
  // { id: classroomToolbarIds.pop, label: 'Deshacer', Icon: Back },
  // { id: classroomToolbarIds.push, label: 'Rehacer', Icon: Forward },
  // {
  //   id: classroomToolbarIds.eraseAll,
  //   label: 'Limpiar todo',
  //   Icon: EraseAll
  // }
]
/**
 * @typedef ClassroomToolbarProps
 * @property {'draw' | 'type' | 'erase' | 'pop' | 'push' | 'eraseAll'} selectedOption
 * @property {string[]} [optionIdsToHide]
 * @property {(id: string)=>void} onClick
 */

/**
 *
 * @param {ClassroomToolbarProps} props
 */

function ClassroomToolbar({
  selectedOption = 'draw',
  optionIdsToHide = [],
  onClick = () => 'draw'
}) {
  return (
    <div className={styles.toolbar}>
      {OPTIONS.filter(({ id }) => !optionIdsToHide.includes(id)).map(
        ({ id, label, Icon }) => (
          <div
            key={id}
            className={[
              styles.iconContainer,
              id !== selectedOption ? styles.unfocused : ''
            ].join(' ')}
            onClick={() => onClick(id)}
            title={label}
          >
            <Icon color='var(--dark-color)' />
          </div>
        )
      )}
    </div>
  )
}

export default ClassroomToolbar
