//@ts-check
import React, { useCallback } from 'react'
import { Navigate } from 'react-router-dom'
import useWebRTC from 'hooks/useWebRTC'
import useCustomNavigate from 'hooks/useCustomNavigate'
import { useNotificationActions } from 'context/NotificationProvider'
import PrivateRoute from 'components/PrivateRoute'
import AppLayout from 'components/layout'
import styles from './Layout.module.css'

function AcademyLayout() {
  const { setErrorMessage } = useNotificationActions()
  const navigate = useCustomNavigate()
  const handleError = useCallback(
    (message = '') => {
      console.error('[handleError] - Error executing WebRTC:', message)
      setErrorMessage({ message })
      navigate('/')
    },
    [navigate, setErrorMessage]
  )
  const context = useWebRTC(handleError)

  if (!context.activeTeacher) return <Navigate to='/' />

  return context.location.pathname === '/academy' ? (
    <AppLayout context={context} />
  ) : (
    <main className={styles.main}>
      <PrivateRoute context={context} />
    </main>
  )
}

export default AcademyLayout
