//@ts-check
import { useCallback, useEffect, useState } from 'react'
import { assoc, assocPath } from 'ramda'
import { findSubjects, findSubjectsForExternal } from 'api/subjects'

function makeSubjectLabel(subject) {
  let name = subject.name
  if (subject.sublevel) {
    name += ` (${subject.level} - ${subject.sublevel})`
    return name
  }
  return (name += ` (${subject.level})`)
}

/**
 *
 * @param {{fetchOnMount?: boolean, useExternalApi?: boolean}} [options]
 * @returns {{subjects: Array, subjectsById: object, isFetching: boolean, fetchSubjects: (query?: object)=>void}}
 */
export default function useSubjects(options) {
  const currentOptions = {
    fetchOnMount: true,
    useExternalApi: false,
    ...(options || {})
  }
  const { fetchOnMount, useExternalApi } = currentOptions
  const [{ subjects, subjectsById, isFetching }, setState] = useState({
    subjects: [],
    subjectsById: {},
    isFetching: false
  })

  const fetchSubjects = useCallback(
    (query = {}) => {
      setState(assoc('isFetching', true))
      const fn = useExternalApi ? findSubjectsForExternal : findSubjects
      fn(query)
        .then(subjects => {
          setState(state => ({
            ...state,
            subjects: subjects.map(s => {
              s.label = makeSubjectLabel(s)
              s.value = s.id
              return s
            }),
            subjectsById: subjects.reduce(
              (acc, subject) => assocPath([subject.id], subject, acc),
              {}
            ),
            isFetching: false
          }))
        })
        .catch(e => {
          console.error('Error fetching subjects:', e)
          setState(assoc('isFetching', false))
        })
    },
    [useExternalApi]
  )

  useEffect(() => {
    fetchOnMount && fetchSubjects()
  }, [fetchOnMount, fetchSubjects])
  return {
    subjects,
    subjectsById,
    isFetching,
    fetchSubjects
  }
}
