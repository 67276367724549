import { useState, useEffect } from 'react'
import { gapi } from 'gapi-script'
import { noop } from 'utils'
import { useNotificationActions } from 'context/NotificationProvider'
import config from 'config'

const CALENDAR_ID = 'primary'

export default function useGoogleCalendar({
  onListenEventAdded = noop,
  onIsSignedIn = noop
}) {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useNotificationActions()

  useEffect(() => {
    handleClientLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClientLoad = () => {
    gapi.load('client:auth2', initClient)
  }

  const initClient = () => {
    gapi.client
      .init({
        apiKey: config.google.apiKey,
        clientId: config.google.clientId,
        discoveryDocs: config.google.discoveryDocs.calendar,
        scope: config.google.scopes.calendar,
        prompt: 'select_account'
      })
      .then(() => {
        const authInstance = gapi.auth2.getAuthInstance()
        authInstance.isSignedIn.listen(updateSigninStatus)
        updateSigninStatus(authInstance.isSignedIn.get())
      })
      .catch(e => console.error('[google]: Error init calendar client: %o', e))
  }

  const updateSigninStatus = isSignedIn => setIsSignedIn(isSignedIn)

  const handleAuth = () => {
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(() => onIsSignedIn(true))
      .catch(e => {
        console.error('[google]: Error signing in: %o', e)
        setErrorMessage({
          message: 'No hemos podido obtener los permisos de tu calendario'
        })
      })
  }

  const handleSignout = () => {
    gapi.auth2.getAuthInstance().signOut()
  }

  /**
   * An object with "start" and "end" like required fields, see more:
   * @link https://developers.google.com/calendar/api/v3/reference/events?hl=es-419
   * {
   *   summary: string, -> title to show, for us required!
   *   start: {
   *     date: date, -> all day (use dateToStringV2)
   *     dateTime: datetime, -> date and hour
   *     timeZone: string -> optional (Europe/Madrid)
   *   },
   *   end: {
   *     date: date,
   *     dateTime: datetime,
   *     timeZone: string
   *   }
   * }
   */
  const handleAddEvent = (event = {}) => {
    gapi.client.calendar.events
      .insert({
        calendarId: CALENDAR_ID,
        resource: event
      })
      .then(data => {
        setSuccessMessage({
          message: 'Hemos añadido un evento a tu calendario'
        })
        onListenEventAdded(true)
      })
      .catch(e => {
        console.error('[google]: Error adding event, %o', e)
        setErrorMessage({
          message: '¡Ups! No hemos podido añadir el evento a tu calendario'
        })
        onListenEventAdded(false)
      })
  }

  return {
    isSignedIn,
    handleAuth,
    handleSignout,
    handleAddEvent
  }
}
