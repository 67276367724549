//@ts-check
import React from 'react'
import useGoogleCalendar from 'hooks/useGoogleCalendar'
import Button from './Button'

function GoogleEventsAggregator({
  summary = 'Classfy - clase de prueba',
  description = '',
  startDateTime,
  endDateTime,
  onListenEventAdded,
  onIsSignedIn
}) {
  const { isSignedIn, handleAuth, handleAddEvent } = useGoogleCalendar({
    onListenEventAdded,
    onIsSignedIn
  })
  return (
    <div>
      {isSignedIn ? (
        <div>
          <Button
            size='tiny'
            onClick={() =>
              handleAddEvent({
                summary,
                description,
                start: {
                  dateTime: startDateTime,
                  timeZone: 'Europe/Madrid'
                },
                end: {
                  dateTime: endDateTime,
                  timeZone: 'Europe/Madrid'
                },
                reminders: {
                  useDefault: false,
                  overrides: [
                    { method: 'email', minutes: 10 },
                    { method: 'popup', minutes: 10 }
                  ]
                }
              })
            }
          >
            Añadir a calendario
          </Button>
        </div>
      ) : (
        <Button size='tiny' onClick={handleAuth}>
          Sí, autorizar
        </Button>
      )}
    </div>
  )
}

export default GoogleEventsAggregator
