import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import PasswordInput from 'components/inputs/PasswordInput'
import { H2, Label, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import useFormManager from 'hooks/useFormManager'
import { useNotificationActions } from 'context/NotificationProvider'
import { changePassword } from 'api/account'
import Alert from 'assets/icons/Alert'
import styles from './ChangePassword.module.css'

const REQUIRED_FIELDS = ['password']

function ChangePassword() {
  const [isSaving, setIsSaving] = useState(false)

  const {
    handleFormChange,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields,
    form
  } = useFormManager(REQUIRED_FIELDS, {
    password: '',
    newPassword: '',
    repeatPassword: ''
  })
  const { setSuccessMessage, setErrorMessage } = useNotificationActions()

  const isInvalidLength =
    form.newPassword.length < 8 || form.newPassword.length > 20

  const isInvalidFormat = !form.newPassword.match(
    /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,20})/
  )

  const handleSave = useCallback(() => {
    const { password, newPassword, repeatPassword } = form
    const isEmpty = !password || !newPassword || !repeatPassword
    if (isEmpty) {
      setErrorMessage({ message: 'Hay campos vacíos.' })
      return
    }
    if (isInvalidLength) {
      setErrorMessage({
        message:
          'La nueva contraseña debe tener una longitud entre 8 y 20 caracteres.'
      })
      return
    }

    if (isInvalidFormat) {
      setErrorMessage({
        message:
          'La nueva contraseña debe contener al menos una minúscula, una mayúscula, un número y un carácter especial.'
      })
      return
    }
    const passwordsDoNotMatch = newPassword !== repeatPassword
    if (passwordsDoNotMatch) {
      setErrorMessage({ message: 'Las contraseñas no coinciden.' })
      return
    }
    setIsSaving(true)
    changePassword({
      oldPassword: form.password,
      newPassword: form.newPassword
    })
      .then(() => {
        setSuccessMessage({ title: 'Contraseña cambiada con éxito' })
      })
      .catch(error => {
        console.error('Error al cambiar la contraseña:', error)
        setErrorMessage({
          message:
            'Error al cambiar la contraseña. Por favor, inténtalo de nuevo.'
        })
      })
      .finally(() => setIsSaving(false))
  }, [
    form,
    isInvalidFormat,
    isInvalidLength,
    setErrorMessage,
    setSuccessMessage
  ])

  return (
    <section className={styles.section}>
      <H2>Cambia la contraseña de tu cuenta 🔑</H2>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <PasswordInput
            name='password'
            placeholder='Contraseña actual'
            value={form.password}
            onChange={handleFormChange}
            {...getErrorPropsByRequiredField('password')}
          />
          <div className={styles.passwordRequirementsContainer}>
            <Alert color='var(--seadapted)' className={styles.alertIcon} />
            <Paragraph className={styles.passwordRequirementsMessage}>
              La nueva contraseña tiene que incluir al menos una minúscula, una
              mayúscula, un número y un carácter especial. La longitud mínima es
              de <strong>8 caracteres.</strong>
            </Paragraph>
          </div>
          <PasswordInput
            name='newPassword'
            placeholder='Nueva contraseña'
            value={form.newPassword}
            error={isInvalidLength || isInvalidFormat}
            onChange={handleFormChange}
          />
          <PasswordInput
            name='repeatPassword'
            placeholder='Verificar nueva contraseña'
            value={form.repeatPassword}
            error={isInvalidLength || isInvalidFormat}
            onChange={handleFormChange}
          />
          <Link to='/forgot-password'>
            <Label className={styles.forgotPasswordLabel}>
              ¿Has olvidado tu contraseña?
            </Label>
          </Link>
          <div className={styles.confirmButton}>
            <Link to='/account'>
              <Button type='secondary' label='Cancelar' size='small' />
            </Link>
            <Button
              label='Cambiar contraseña'
              size='small'
              loading={isSaving}
              disabled={hasPendingRequiredFields || isSaving}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChangePassword
