//@ts-check
import React, { useEffect } from 'react'
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom'
import {
  useNotification,
  useNotificationActions
} from 'context/NotificationProvider'
import AuthProvider from 'context/AuthProvider'
import Layout from 'components/layout'
import AcademyLayout from 'components/academy/Layout'
import Notification from 'components/notifications/Notification'
import Login from 'pages/Login'
import AutoLogin from 'pages/AutoLogin'
import ForgotPassword from 'pages/ForgotPassword'
import Registration from 'pages/Registration'
import Home from 'pages/Home'
import NewlyRegistered from 'pages/NewlyRegistered'
import Rating from 'pages/Rating'
import Academy from 'pages/Academy'
import Classroom from 'pages/Classroom'
import VideoTour from 'pages/VideoTour'
import MediaPermissions from 'pages/MediaPermissions'
import Subscriptions from 'pages/Subscriptions'
import SubscriptionDetails from 'pages/Subscriptions/SubscriptionDetails'
import SubscriptionCreate from 'pages/Subscriptions/Create'
import Notifications from 'pages/Notifications'
import UploadPhoto from 'pages/UploadPhoto'
import Gallery from 'pages/Gallery'
import AccountLayout from 'pages/Account/Layout'
import Account from 'pages/Account'
import PersonalData from 'pages/Account/PersonalData'
import PaymentMethods from 'pages/Account/PaymentMethods'
import Billing from 'pages/Account/Billing'
import ContactData from 'pages/Account/ContactData'
import ServiceTerms from 'pages/Account/ServiceTerms'
import ChangePassword from 'pages/Account/ChangePassword'
import Attendances from 'pages/Attendances'
import MediaCheck from 'pages/MediaCheck'
import Proposals from 'pages/Proposals'
import ProposalDetails from 'pages/Proposals/ProposalDetails'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route Component={AuthProvider}>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='newly-registered' element={<NewlyRegistered />} />
        <Route path='rating/:attendanceId' element={<Rating />} />
        <Route path='gallery' element={<Gallery />} />

        <Route path='account' element={<AccountLayout />}>
          <Route index element={<Account />} />
          <Route path='personal-data' element={<PersonalData />} />
          <Route path='payment-methods' element={<PaymentMethods />} />
          <Route path='billing' element={<Billing />} />
          <Route path='contact-data' element={<ContactData />} />
          <Route path='service-terms' element={<ServiceTerms />} />
          <Route path='change-password' element={<ChangePassword />} />
        </Route>
        <Route path='billing' element={<p>BILLING</p>} />
        <Route path='subscriptions' element={<Subscriptions />} />
        <Route path='subscriptions/create' element={<SubscriptionCreate />} />
        <Route
          path='subscriptions/:subscriptionId'
          element={<SubscriptionDetails />}
        />
        <Route path='proposals' element={<Proposals />} />
        <Route path='proposals/:budgetId' element={<ProposalDetails />} />
        <Route path='notifications' element={<Notifications />} />
        <Route path='attendances' element={<Attendances />} />
        <Route path='media-check' element={<MediaCheck />} />
      </Route>
      <Route path='academy' element={<AcademyLayout />}>
        <Route index element={<Academy />} />
        <Route path='video-tour' element={<VideoTour />} />
        <Route path='media-permissions' element={<MediaPermissions />} />
        <Route path='classroom' element={<Classroom />} />
      </Route>
      <Route path='/login' element={<Login />} />
      <Route path='/auto-login/:token' element={<AutoLogin />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/register' element={<Registration />} />
      <Route path='/upload-photo/:studentId' element={<UploadPhoto />} />

      <Route path='*' element={<Navigate to='/' replace />} />
    </Route>
  )
)

function App() {
  const { title, message, type, children, ms } = useNotification()
  const { clear } = useNotificationActions()

  useEffect(
    () => document.addEventListener('contextmenu', e => e.preventDefault()),
    []
  )

  return (
    <>
      {!!ms && (
        <Notification
          title={title}
          message={message}
          type={type}
          children={children}
          onClose={clear}
        />
      )}
      <RouterProvider router={router} />
    </>
  )
}

export default App
