//@ts-check
import React, { useState } from 'react'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js'
import { omit } from 'ramda'
import { getClientSecret } from 'api/billing'
import Button from 'components/buttons/Button'
import { H3 } from 'components/typography'
import Modal from 'components/modals/Modal'
import styles from './CardSetupForm.module.css'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontSize: '16px',
      '::placeholder': {
        color: '#8080f866'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
}

function CardSetupModal({ onClose, onSave, onError }) {
  const stripe = useStripe()
  const elements = useElements()
  const errorMessage = 'Ha habido un error al guardar los datos de tarjeta'
  const [isSaving, setIsSaving] = useState(false)
  const [cardholder, setCardHolder] = useState('')

  const processError = e => {
    console.error('Submit error: ', e)
    setIsSaving(false)
    onError(e.message || errorMessage)
  }

  const processSuccess = async data => {
    try {
      const res = await stripe.confirmCardSetup(data, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: { name: cardholder }
        }
      })
      if (res.error) {
        throw omit(['payment_method', 'setup_intent'], res.error)
      }
      onSave(res)
      clearFields()
    } catch (error) {
      processError(error)
    }
  }

  const clearFields = () => {
    const elementsArray = [CardNumberElement, CardExpiryElement, CardCvcElement]
    elementsArray.forEach(element => {
      // @ts-ignore
      elements.getElement(element).clear()
    })
    setCardHolder('')
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (!stripe || !elements || !cardholder) return
    setIsSaving(true)
    try {
      const clientSecret = await getClientSecret()
      await processSuccess(clientSecret)
    } catch (error) {
      processError(error)
    } finally {
      setIsSaving(false)
    }
  }

  const handleTextFieldChange = event => setCardHolder(event.target.value)

  return (
    <Modal showActions={false}>
      <div className={styles.container}>
        <H3>Añadir tarjeta para futuros pagos</H3>
        <form onSubmit={handleSubmit}>
          <div className={styles.cardInputs}>
            <CardNumberElement
              className={styles.cardInput}
              options={CARD_ELEMENT_OPTIONS}
            />
            <input
              placeholder='Titular de la tarjeta'
              type='text'
              value={cardholder}
              onChange={handleTextFieldChange}
              className={styles.cardholderInput}
            />
            <CardExpiryElement
              className={styles.cardInput}
              options={CARD_ELEMENT_OPTIONS}
            />
            <CardCvcElement
              className={styles.cardInput}
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        </form>
        <div className={styles.formActions}>
          <Button size='tiny' type='secondary' onClick={onClose}>
            Cerrar
          </Button>
          <Button
            size='tiny'
            onClick={handleSubmit}
            disabled={!stripe || !elements || !cardholder || isSaving}
            loading={isSaving}
          >
            Guardar tarjeta
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CardSetupModal
