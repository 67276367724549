import * as React from 'react'

const Back = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 21 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M21 10.761c0 3.996-3.512 7.236-7.828 7.236H1.412c-.584 0-1.05-.432-1.05-.972s.466-.972 1.05-.972h11.76c2.041 0 3.931-1.008 4.958-2.64a4.98 4.98 0 0 0 0-5.292c-1.027-1.632-2.905-2.64-4.958-2.64H3.605l2.018 1.86a.901.901 0 0 1 .269.936c-.094.336-.385.6-.747.696a1.131 1.131 0 0 1-1.027-.264L.303 5.181C.292 5.17.28 5.145.268 5.133c-.023-.012-.035-.036-.046-.048a1.13 1.13 0 0 1-.14-.216v-.012a.813.813 0 0 1-.07-.24c0-.024-.012-.048-.012-.06 0-.084 0-.168.012-.252a.779.779 0 0 1 .093-.228c.012-.036.023-.048.035-.072.047-.072.105-.144.163-.204L4.118.273c.409-.372 1.074-.36 1.482.012a.926.926 0 0 1 .012 1.368l-2.019 1.86h9.567c4.328.012 7.84 3.24 7.84 7.248Z'
      fill={props.color || '#fff'}
    />
  </svg>
)

export default Back
