//@ts-check
import React from 'react'
import Download from 'assets/icons/Download'
import styles from './Download.module.css'

/**
 * @typedef DownloadButtonProps
 * @property {string} label
 * @property {boolean} disabled
 * @property {()=> void} onClick
 * @property {React.CSSProperties} style
 *
 */

/** @param {Partial<DownloadButtonProps>} props */
function DownloadButton({ label = 'Descargar', disabled = false, onClick }) {
  return (
    <div
      className={[styles.downloadButton, disabled ? styles.disabled : ''].join(
        ' '
      )}
      onClick={() => !disabled && onClick()}
    >
      <Download className={styles.icon} />
    </div>
  )
}

export default DownloadButton
