//@ts-check
import React, { useCallback, useMemo, useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import config from 'config'
import { register } from 'api/auth'
import {
  SubjectLevels,
  SubjectSublevels,
  SubjectSubtypes
} from 'utils/constants'
import useCustomNavigate from 'hooks/useCustomNavigate'
import { useAuth } from 'context/AuthProvider'
import { useNotificationActions } from 'context/NotificationProvider'
import Vector from 'assets/icons/Vector'
import BrightClassfy from 'assets/logos/main/BrightClassfy'
import Svg from 'assets/svg'
import Button from 'components/buttons/Button'
import AcademicBackgroundSelect, {
  AcademicBackgroundOptions
} from 'components/selects/AcademicBackgroundSelect'
import SubjectSelect from 'components/selects/SubjectSelect'
import { H1, H2, H4, H5, Paragraph } from 'components/typography'
import styles from './Registration.module.css'

const { EasyAndCoolSvg, HeySvg, WelcomeSvg, NeedHelpSvg, FeelingGoodSvg } = Svg

function Registration() {
  const navigate = useCustomNavigate()
  const { isAuthenticated } = useAuth()
  const { setErrorMessage } = useNotificationActions()
  const [isSaving, setIsSaving] = useState(false)
  const [subjectsById, setSubjectsById] = useState({})
  const [step, setStep] = useState(0)
  const [form, setForm] = useState({
    name: '',
    email: '',
    academicBackground: '',
    subjectIds: [],
    description: undefined
  })

  const handleFormChange = useCallback(e => {
    const { name, value } = e.target
    setForm(state => ({ ...state, [name]: value }))
  }, [])

  const handleSubmit = useCallback(() => {
    setIsSaving(true)
    register({
      name: form.name,
      email: form.email,
      trial: {
        academicBackground: form.academicBackground,
        subjectIds: form.subjectIds,
        description: form.description?.trim()
          ? form.description.trim()
          : undefined
      }
    })
      .then(data => {
        localStorage.setItem(config.accessTokenKey, data.token)
        setStep(step => step + 1)
      })
      .catch(e => {
        console.error('Error registering: ', e)
        setErrorMessage({
          title: 'Error en el registro',
          message: e.message,
          ms: 10000
        })
      })
      .finally(() => setIsSaving(false))
  }, [form, setErrorMessage])

  const extractSubjectsById = useCallback(
    subjectsById => setSubjectsById(subjectsById),
    []
  )

  const handleBook = useCallback(() => {
    navigate('/newly-registered')
  }, [navigate])
  if (isAuthenticated) return <Navigate to='/' />
  return (
    <main className={styles.main}>
      <Link to='/login'>
        <BrightClassfy className={styles.classfyLogo} />
      </Link>
      <div className={styles.container}>
        {step === 0 && (
          <InitialStep onClick={() => setStep(step => step + 1)} />
        )}
        {step === 1 && (
          <Step1
            form={form}
            onChange={handleFormChange}
            onClick={() => setStep(step => step + 1)}
          />
        )}
        {step === 2 && (
          <Step2
            form={form}
            onChange={handleFormChange}
            onClick={() => setStep(step => step + 1)}
            onBack={() => setStep(step => step - 1)}
          />
        )}
        {step === 3 && (
          <Step3
            form={form}
            extractSubjectsById={extractSubjectsById}
            onChange={handleFormChange}
            onClick={() => setStep(step => step + 1)}
            onBack={() => setStep(step => step - 1)}
          />
        )}
        {step === 4 && (
          <Step4
            isSaving={isSaving}
            form={form}
            subjectsById={subjectsById}
            onBack={() => setStep(step => step - 1)}
            onSubmit={handleSubmit}
          />
        )}
        {step === 5 && <Step5 form={form} onClick={handleBook} />}
      </div>
    </main>
  )
}

function InitialStep({ onClick }) {
  return (
    <>
      <div className={styles.images}>
        <EasyAndCoolSvg
          className={styles.backgroundSvg}
          color='var(--sandground)'
        />
        <EasyAndCoolSvg className={styles.mainSvg} />
      </div>
      <div className={styles.innerContainer}>
        <Paragraph className={styles.text}>
          <span>Te lo queremos poner fácil.</span>
          <br />
          <span>Cuéntanos </span>
          <span className={styles.accent}>qué estás buscando</span>
          <br />
          <span className={styles.accent}> </span>
          <span>y te organizamos </span>
          <span>una clase de prueba </span>
          <span className={styles.accent}>gratuita </span>
          <br />
          <span>para valorar tus necesidades</span>
        </Paragraph>
        <Button
          label='Registrarse'
          size='medium'
          type='secondary'
          onClick={onClick}
        />
      </div>
    </>
  )
}

function Step1({ form, onChange, onClick }) {
  return (
    <>
      <div className={styles.images}>
        <HeySvg className={styles.backgroundSvg} color='var(--sandground)' />
        <HeySvg className={styles.mainSvg} />
      </div>
      <div className={styles.innerContainer}>
        <input
          className={styles.commonInput}
          name='name'
          type='text'
          placeholder='Nombre completo'
          value={form.name}
          onChange={onChange}
        />
        <div className={styles.actions}>
          <div />
          <Button
            label='Siguiente'
            size='medium'
            type='secondary'
            onClick={onClick}
            disabled={!form.name}
          />
        </div>
      </div>
    </>
  )
}

function Step2({ form, onChange, onClick, onBack }) {
  return (
    <>
      <div className={styles.images}>
        <WelcomeSvg
          className={styles.backgroundSvg}
          color='var(--sandground)'
        />
        <WelcomeSvg className={styles.mainSvg} />
      </div>
      <div className={styles.innerContainer}>
        <p className={styles.studentName}>{form.name}</p>
        <input
          className={styles.commonInput}
          name='email'
          type='text'
          placeholder='e-mail'
          value={form.email}
          onChange={onChange}
        />
        <div className={styles.actions}>
          <Button
            label='Volver'
            size='medium'
            type='tertiary'
            onClick={onBack}
          />
          <Button
            label='Siguiente'
            size='medium'
            type='secondary'
            onClick={onClick}
            disabled={!form.email}
          />
        </div>
      </div>
    </>
  )
}

function Step3({ form, extractSubjectsById, onChange, onClick, onBack }) {
  const isOthers = form.academicBackground === AcademicBackgroundOptions.OTROS
  const academicBackgroundChange = e => {
    onChange(e)
    onChange({ target: { name: 'subjectIds', value: [] } })
  }
  const isDisabled = useMemo(() => {
    if (isOthers) return !form.description
    return !form.academicBackground || !form.subjectIds.length
  }, [form, isOthers])
  return (
    <>
      <div className={styles.images}>
        <NeedHelpSvg
          className={styles.backgroundSvg}
          color='var(--sandground)'
        />
        <NeedHelpSvg className={styles.mainSvg} />
      </div>
      <div className={styles.innerContainer}>
        <p className={styles.studentName}>{form.name}</p>
        <div className={styles.selectWrapper}>
          <AcademicBackgroundSelect
            value={form.academicBackground}
            showLabel={false}
            onChange={academicBackgroundChange}
          />
          {!isOthers && (
            <SubjectSelect
              extractSubjectsById={extractSubjectsById}
              name='subjectIds'
              value={form.subjectIds}
              placeholder='Asignatura/s de interés'
              showLabel={false}
              filter={subjects =>
                filterSubjectsByBackground(form.academicBackground, subjects)
              }
              onChange={onChange}
              disabled={!form.academicBackground}
              useExternalApi
              showLength
              multiple
            />
          )}
          <textarea
            className={styles.textarea}
            name='description'
            placeholder={
              isOthers
                ? 'Especifícanos en detalle la asignatura/s que estás buscando.\nAñade tu número de teléfono si es posible para ponernos en contacto contigo'
                : 'Cuéntanos si tienes alguna duda específica sobre alguna asignatura'
            }
            value={form.description}
            rows={4}
            onChange={onChange}
          />
        </div>
        <div className={styles.actions}>
          <Button
            label='Volver'
            size='medium'
            type='tertiary'
            onClick={onBack}
          />
          <Button
            label='Enviar'
            size='medium'
            type='secondary'
            onClick={onClick}
            disabled={isDisabled}
          />
        </div>
      </div>
    </>
  )
}

function Step4({ isSaving, form, subjectsById, onBack, onSubmit }) {
  return (
    <div className={styles.step4}>
      <H2 className={styles.step4H2}>
        ¡Ya no queda nada para tu clase gratuita!
      </H2>
      <p className={styles.step4Text}>
        Revisa tus datos antes de agendar tu clase <br />&{' '}
        <strong>brain on </strong>
      </p>
      <div className={styles.resume}>
        <div className={styles.header}>
          <div className={styles.academicBackground}>
            <span>{form.academicBackground}</span>
          </div>
        </div>
        <H4 className={styles.resumeText}>{form.name}</H4>
        <Vector className={styles.vector} color='var(--seadapted)' />
        <H5 className={styles.resumeText}>{form.email}</H5>
        <div className={styles.subjects}>
          {form.subjectIds?.map((subjectId, index) => {
            const subject = subjectsById[subjectId]
            if (!subject) {
              console.warn('check subject:', subjectId)
              return null
            }
            return (
              <div key={index} className={styles.subject}>
                <span className={styles.subjectName}> {subject.name}</span>
              </div>
            )
          })}
        </div>
      </div>
      <Button
        label={isSaving ? 'Enviando...' : "Todo OK, let's do it"}
        type='secondary'
        size='medium'
        onClick={onSubmit}
      />
      <Button
        label='Volver, hay un fallo'
        type='tertiary'
        size='medium'
        onClick={onBack}
      />
    </div>
  )
}

function Step5({ form, onClick }) {
  return (
    <>
      <div className={styles.images}>
        <FeelingGoodSvg
          className={styles.backgroundSvg}
          color='var(--sandground)'
        />
        <FeelingGoodSvg className={styles.mainSvg} />
      </div>
      <div className={styles.step5InnerContainer}>
        <div className={styles.goodbyeTextSection}>
          <H1 className={styles.mainGoodbyeText}>
            <span className={styles.accentText}>¡Muchas gracias </span>
            por registrarte!
          </H1>
          <H2 className={styles.secondaryGoodbyeText}>
            {form.name.split(' ')[0]} entra y empieza tu experiencia en
          </H2>
          <H2 className={styles.accentText}>Classfy</H2>
        </div>
        <Button
          label='Acceder'
          size='medium'
          type='secondary'
          onClick={onClick}
        />
      </div>
    </>
  )
}

function filterSubjectsByBackground(academicBackground, subjects) {
  switch (academicBackground) {
    case AcademicBackgroundOptions.PRIMARIA:
      return subjects.filter(s => s.level === SubjectLevels.PRIMARIA)
    case AcademicBackgroundOptions.SECUNDARIA:
      return subjects.filter(
        s =>
          [SubjectLevels.SECUNDARIA, SubjectLevels.IDIOMAS].includes(s.level) ||
          (s.level === SubjectLevels.SECUNDARIA &&
            s.sublevel === SubjectSublevels.Secundaria.INTERNACIONAL) ||
          (s.level === SubjectLevels.OTRAS &&
            s.subtype === SubjectSubtypes.Letras.LETRAS)
      )
    case AcademicBackgroundOptions.BACHILLERATO:
      return subjects.filter(
        s =>
          [
            SubjectLevels.SECUNDARIA,
            SubjectLevels.BACHILLERATO,
            SubjectLevels.IDIOMAS
          ].includes(s.level) ||
          (s.level === SubjectLevels.SECUNDARIA &&
            s.sublevel === SubjectSublevels.Secundaria.INTERNACIONAL) ||
          (s.level === SubjectLevels.BACHILLERATO &&
            s.sublevel === SubjectSublevels.Bachillerato.INTERNACIONAL) ||
          (s.level === SubjectLevels.OTRAS &&
            s.subtype === SubjectSubtypes.Letras.LETRAS)
      )
    case AcademicBackgroundOptions.UNIVERSIDAD:
      return subjects.filter(
        s =>
          [SubjectLevels.UNIVERSIDAD, SubjectLevels.IDIOMAS].includes(
            s.level
          ) ||
          (s.level === SubjectLevels.OTRAS &&
            s.subtype === SubjectSubtypes.Letras.LETRAS)
      )
    case AcademicBackgroundOptions.SELECTIVIDAD_EVAU:
    case AcademicBackgroundOptions.PCE:
    case AcademicBackgroundOptions.ACCESO_MAYORES_25:
    case AcademicBackgroundOptions.ACCESO_MAYORES_45:
      return subjects.filter(
        s =>
          [SubjectLevels.BACHILLERATO, SubjectLevels.IDIOMAS].includes(
            s.level
          ) ||
          (s.level === SubjectLevels.BACHILLERATO &&
            s.sublevel === SubjectSublevels.Bachillerato.INTERNACIONAL) ||
          (s.level === SubjectLevels.OTRAS &&
            s.subtype === SubjectSubtypes.Letras.LETRAS)
      )

    case AcademicBackgroundOptions.ACCESO_GRADO_SUPERIOR:
      return subjects.filter(
        s =>
          [
            SubjectLevels.SECUNDARIA,
            SubjectLevels.BACHILLERATO,
            SubjectLevels.IDIOMAS
          ].includes(s.level) ||
          (s.level === SubjectLevels.SECUNDARIA &&
            s.sublevel === SubjectSublevels.Secundaria.INTERNACIONAL) ||
          (s.level === SubjectLevels.BACHILLERATO &&
            s.sublevel === SubjectSublevels.Bachillerato.INTERNACIONAL) ||
          (s.level === SubjectLevels.OTRAS &&
            s.subtype === SubjectSubtypes.Letras.LETRAS)
      )
    case AcademicBackgroundOptions.ACCESO_GRADO_MEDIO:
    case AcademicBackgroundOptions.MODULO_GRADO_MEDIO:
      return subjects.filter(
        s =>
          [SubjectLevels.SECUNDARIA, SubjectLevels.IDIOMAS].includes(s.level) ||
          (s.level === SubjectLevels.SECUNDARIA &&
            s.sublevel === SubjectSublevels.Secundaria.INTERNACIONAL) ||
          (s.level === SubjectLevels.OTRAS &&
            s.subtype === SubjectSubtypes.Letras.LETRAS)
      )
    case AcademicBackgroundOptions.MODULO_GRADO_SUPERIOR:
      return subjects.filter(
        s =>
          [
            SubjectLevels.SECUNDARIA,
            SubjectLevels.BACHILLERATO,
            SubjectLevels.FP,
            SubjectLevels.IDIOMAS
          ].includes(s.level) ||
          (s.level === SubjectLevels.SECUNDARIA &&
            s.sublevel === SubjectSublevels.Secundaria.INTERNACIONAL) ||
          (s.level === SubjectLevels.BACHILLERATO &&
            s.sublevel === SubjectSublevels.Bachillerato.INTERNACIONAL) ||
          (s.level === SubjectLevels.OTRAS &&
            s.subtype === SubjectSubtypes.Letras.LETRAS)
      )
    case AcademicBackgroundOptions.IDIOMAS:
      return subjects.filter(
        s =>
          (s.level === SubjectLevels.OTRAS &&
            s.subtype === SubjectSubtypes.Letras.IDIOMAS) ||
          s.level == SubjectLevels.IDIOMAS
      )
    default:
      return []
  }
}

export default Registration
