import * as React from 'react'

const Bin = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 30 34'
    fill='none'
    {...props}
  >
    <path
      fill={props.color || '#fff'}
      d='M27.722 3.753h-8.471V2.67A1.672 1.672 0 0 0 17.583 1h-6.066a1.67 1.67 0 0 0-1.668 1.673v1.082H2.278A1.276 1.276 0 0 0 1 5.035v3.158a1.281 1.281 0 0 0 1.278 1.28h.948v19.728a3.809 3.809 0 0 0 1.113 2.685A3.794 3.794 0 0 0 7.02 33h15.96c1.006 0 1.97-.402 2.682-1.114a3.808 3.808 0 0 0 1.112-2.688V9.471h.948A1.276 1.276 0 0 0 29 8.19V5.033a1.283 1.283 0 0 0-1.278-1.28ZM11.163 2.67a.359.359 0 0 1 .355-.355h6.063a.36.36 0 0 1 .355.355v1.082h-6.772V2.67Zm14.295 26.526a2.481 2.481 0 0 1-2.479 2.483H7.02a2.48 2.48 0 0 1-2.478-2.483V9.471h20.916v19.726Zm2.226-21.045H2.316V5.07h25.367v3.082ZM9.072 27.936a2.083 2.083 0 0 0 2.079-2.084V14.961a2.087 2.087 0 0 0-2.081-2.112 2.08 2.08 0 0 0-2.08 2.112v10.891a2.089 2.089 0 0 0 2.082 2.084Zm-.767-12.975a.765.765 0 1 1 1.53 0v10.891a.765.765 0 1 1-1.53 0V14.961ZM15 27.936a2.081 2.081 0 0 0 2.08-2.084V14.961a2.078 2.078 0 0 0-3.121-1.805 2.086 2.086 0 0 0-1.04 1.805v10.891A2.088 2.088 0 0 0 15 27.936Zm-.765-12.975a.765.765 0 1 1 1.53 0v10.891a.765.765 0 1 1-1.53 0V14.961Zm6.694 12.975a2.082 2.082 0 0 0 2.08-2.084V14.96a2.079 2.079 0 1 0-4.161 0v10.89a2.09 2.09 0 0 0 2.08 2.085Zm-.765-12.975a.765.765 0 1 1 1.53 0v10.891a.765.765 0 1 1-1.53 0V14.961Z'
    />
    <path
      stroke={props.color || '#fff'}
      strokeWidth={0.5}
      d='M20.93 27.935h-.001m0 0a2.082 2.082 0 0 0 2.08-2.083V14.96a2.079 2.079 0 1 0-4.161 0v10.89a2.09 2.09 0 0 0 2.08 2.085Zm6.793-24.182h-8.471V2.67A1.672 1.672 0 0 0 17.583 1h-6.066a1.67 1.67 0 0 0-1.668 1.673v1.082H2.278A1.276 1.276 0 0 0 1 5.035v3.158a1.281 1.281 0 0 0 1.278 1.28h.948v19.728a3.809 3.809 0 0 0 1.113 2.685A3.794 3.794 0 0 0 7.02 33h15.96c1.006 0 1.97-.402 2.682-1.114a3.808 3.808 0 0 0 1.112-2.688V9.471h.948A1.276 1.276 0 0 0 29 8.19V5.033a1.283 1.283 0 0 0-1.278-1.28ZM11.163 2.67a.359.359 0 0 1 .355-.355h6.063a.36.36 0 0 1 .355.355v1.082h-6.772V2.67Zm14.295 26.526a2.481 2.481 0 0 1-2.479 2.483H7.02a2.48 2.48 0 0 1-2.478-2.483V9.471h20.916v19.726Zm2.226-21.045H2.316V5.07h25.367v3.082ZM9.072 27.936a2.083 2.083 0 0 0 2.079-2.084V14.961a2.087 2.087 0 0 0-2.081-2.112 2.08 2.08 0 0 0-2.08 2.112v10.891a2.089 2.089 0 0 0 2.082 2.084Zm-.767-12.975a.765.765 0 1 1 1.53 0v10.891a.765.765 0 1 1-1.53 0V14.961ZM15 27.936a2.081 2.081 0 0 0 2.08-2.084V14.961a2.078 2.078 0 0 0-3.121-1.805 2.086 2.086 0 0 0-1.04 1.805v10.891A2.088 2.088 0 0 0 15 27.936Zm-.765-12.975a.765.765 0 1 1 1.53 0v10.891a.765.765 0 1 1-1.53 0V14.961Zm5.93 0a.765.765 0 1 1 1.529 0v10.891a.765.765 0 1 1-1.53 0V14.961Z'
    />
  </svg>
)
export default Bin
