//@ts-check
import React, { useEffect, useState } from 'react'
// @ts-ignore
import sampleAudio from 'assets/audio/sampleAudio.mp3'
import getLocalStream, { isSupported } from 'utils/getLocalStream'
import Stepper from 'components/stepper'
import { H3, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import VideoSource from 'components/academy/classroom/VideoSource'
import Check from 'assets/icons/Check'
import styles from './MediaCheck.module.css'

function MediaCheck() {
  const [activeStep, setActiveStep] = useState(0)
  const [isValidNavigator, setValidNavigator] = useState(false)
  const [stream, setStream] = useState(null)

  const handleCompleteStep = step => {
    setActiveStep(step)
    if (step != 2) {
      stream?.getTracks().forEach(track => track.stop())
      setStream(null)
    }
  }
  useEffect(() => {
    const allowsWebcam = isSupported()
    const allowsRtc = typeof RTCPeerConnection !== 'undefined'
    setValidNavigator(allowsWebcam && allowsRtc)
  }, [])

  const handleWebcamTest = () => {
    getLocalStream({ video: { width: 240 }, audio: true })
      .then(setStream)
      .catch(err => alert(`Vaya! ${err}.`))
  }

  const CompatibilityMessage = () => (
    <strong>
      {isValidNavigator
        ? 'es totalmente compatible'
        : 'no está lo suficientemente actualizado'}
    </strong>
  )

  return (
    <section>
      <Stepper
        steps={[
          {
            title: 'Navegador',
            component: (
              <div className={styles.commonContainer}>
                <Paragraph>
                  Es importante que tu navegador de Internet esté actualizado.
                  No solo tendrás las últimas funcionalidades, sino que además,
                  tendrás los últimos parches de seguridad y estarás más a salvo
                  de virus, hackers...
                </Paragraph>
                <Paragraph>
                  Cualquiera de los principales navegadores de hoy en día,
                  suficientemente actualizado, debería permitirte utilizar
                  nuestro servicio sin problemas (<strong>Chrome</strong>,{' '}
                  <strong>Edge</strong>, <strong>Safari</strong> y{' '}
                  <strong>Firefox</strong>).
                </Paragraph>
                <Paragraph>
                  Tu navegador actual, <CompatibilityMessage />.
                </Paragraph>
                <Paragraph>
                  Acuérdate de mantener siempre tu navegador actualizado para
                  tener las últimas actualizaciones de seguridad y la mejor
                  funcionalidad.
                </Paragraph>
                <Button
                  size='small'
                  className={styles.alignedItem}
                  type='secondary'
                  onClick={() => handleCompleteStep(activeStep + 1)}
                >
                  <Check
                    color='var(--seadapted)'
                    className={styles.checkIcon}
                  />{' '}
                  Navegador compatible
                </Button>
              </div>
            )
          },
          {
            title: 'Sonido',
            component: (
              <div className={styles.commonContainer}>
                <Paragraph>
                  Es importante que puedas oír a tu profesor durante la clase.
                  Vamos a verificar que tienes el sonido configurado
                  adecuadamente.
                </Paragraph>
                <Paragraph>
                  Pulsando el botón se empezará a reproducir un audio
                </Paragraph>
                <audio className={styles.audioSource} controls>
                  <source src={sampleAudio} />
                </audio>
                <Paragraph>
                  Comprueba que lo puedes escuchar adecuadamente.
                </Paragraph>
                <Button
                  size='small'
                  className={styles.alignedItem}
                  type='secondary'
                  onClick={() => handleCompleteStep(activeStep + 1)}
                >
                  <Check
                    color='var(--seadapted)'
                    className={styles.checkIcon}
                  />{' '}
                  Sonido correcto
                </Button>
                <H3>Posibles problemas y soluciones</H3>
                <Paragraph>
                  Si tienes problemas en <code>Windows</code> revisa la
                  siguiente
                  <code>
                    <a
                      target='_blank'
                      href='https://support.microsoft.com/es-es/windows/solucionar-problemas-de-sonido-o-audio-en-windows-73025246-b61c-40fb-671a-2535c7cd56c8'
                      rel='noreferrer'
                    >
                      {' guía '}
                    </a>
                  </code>
                  y verifica que no tienes problemas de sonido
                </Paragraph>
                <Paragraph>
                  Si tienes problemas en <code>Mac</code> revisa la siguiente
                  <code>
                    <a
                      target='_blank'
                      href='https://support.apple.com/es-es/HT203186'
                      rel='noreferrer'
                    >
                      {' guía '}
                    </a>
                  </code>
                  y verifica que no tienes problemas de sonido
                </Paragraph>
              </div>
            )
          },
          {
            title: 'Video y audio',
            component: (
              <div className={styles.commonContainer}>
                <Paragraph>
                  Finalmente vamos a comprobar que vamos a poder verte y oirte.
                </Paragraph>
                <Paragraph>
                  Esto es solo un test de cámara, no vas a entrar a hablar con
                  nadie. Es simplemente para que compruebes que todo funciona
                  correctamente.
                </Paragraph>
                {stream && (
                  <div className={styles.alignedItem}>
                    <VideoSource stream={stream} size='large' />
                  </div>
                )}
                <Button
                  size='small'
                  type='warning'
                  className={styles.alignedItem}
                  onClick={handleWebcamTest}
                >
                  Probar
                </Button>
                <Paragraph>
                  Deberías verte en pantalla y, quizás, oírte. Algunos
                  navegadores quitan el audio para evitar el eco, para verificar
                  el audio, prueba a conectar unos auriculares o un altavoz como
                  salida de audio. No necesitarás usarlos en clase, ya que no
                  hace falta que te escuches a ti mismo. Es importante que
                  verifiques que esto funcione bien, ya que tu profesor va a
                  necesitar hablar contigo durante la clase y la comunicación
                  tiene que ser rápida y fluida.
                </Paragraph>
                <Button
                  size='small'
                  className={styles.alignedItem}
                  type='secondary'
                  onClick={() => handleCompleteStep(activeStep + 1)}
                >
                  <Check
                    color='var(--seadapted)'
                    className={styles.checkIcon}
                  />{' '}
                  Funciona
                </Button>
                <H3>Posibles problemas y soluciones</H3>
                <Paragraph>
                  Si tienes problemas en <code>Windows</code> revisa la
                  siguiente
                  <code>
                    <a
                      target='_blank'
                      href='https://support.microsoft.com/es-es/windows/solucionar-problemas-con-el-micr%C3%B3fono-5f230348-106d-bfa4-1db5-336f35576011'
                      rel='noreferrer'
                    >
                      {' guía '}
                    </a>
                  </code>
                  para el micrófono o la siguiente{' '}
                  <code>
                    <a
                      target='_blank'
                      href='https://support.microsoft.com/es-es/windows/la-c%C3%A1mara-no-funciona-en-windows-32adb016-b29c-a928-0073-53d31da0dad5'
                      rel='noreferrer'
                    >
                      {' guía '}
                    </a>
                  </code>{' '}
                  para la cámara
                </Paragraph>
                <Paragraph>
                  Si tienes problemas en <code>Mac</code> revisa la siguiente
                  <code>
                    <a
                      target='_blank'
                      href='https://classfy-documents.s3.eu-west-1.amazonaws.com/ayuda/videollamada/mac-soluciones-micro.pdf'
                      rel='noreferrer'
                    >
                      {'  guía '}
                    </a>
                  </code>
                  para el micrófono o la siguiente
                  <code>
                    <a
                      target='_blank'
                      href='https://support.apple.com/es-es/HT211130'
                      rel='noreferrer'
                    >
                      {' guía '}
                    </a>
                  </code>{' '}
                  para la cámara
                </Paragraph>
                <Paragraph>
                  <span role='img' aria-label='warning'>
                    ⚠️
                  </span>{' '}
                  Actualmente (Abril 2020) <strong>Safari</strong> hay un error
                  que no permite que 2 pestañas de Internet estén utilizando la
                  cámara a la vez. Si estás utilizando Safari, y te sale un
                  mensaje de error que te indica{' '}
                  <strong>NotReadableError</strong> - comprueba que no tienes
                  ninguna otra pestaña abierta utilizando la cámara.
                </Paragraph>
              </div>
            )
          }
        ]}
        activeStep={activeStep}
        onChange={handleCompleteStep}
      />
      {activeStep > 2 && (
        <div className={styles.commonContainer}>
          <Paragraph>
            ¡Genial! Has comprobado que todo funciona correctamente. No vas a
            tener ningún problema técnico para empezar con las clases. Asegurate
            de estar en un entorno tranquilo, y tener todo el material que
            necesitas antes de comenzar.
          </Paragraph>
          <Paragraph>El equipo docente de classfy te espera</Paragraph>
        </div>
      )}
    </section>
  )
}

export default MediaCheck
