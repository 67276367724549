import React, { useEffect, useRef, useState } from 'react'
import { Paragraph } from 'components/typography'
import TextArea from 'components/inputs/TextArea'
import Button from 'components/buttons/Button'
import styles from './Chat.module.css'

function Chat({ messages = [], onSend, onCleanAlert }) {
  const [input, setInput] = useState('')
  const lastMessageRef = useRef(null)

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSend()
    }
  }
  const handleSend = () => {
    onSend(input)
    setInput('')
  }

  const handlePaste = event => {
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('text')
  }

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  useEffect(() => {
    onCleanAlert()
    return () => onCleanAlert()
  }, [onCleanAlert])

  return (
    <div className={styles.chatContainer}>
      <div className={styles.chatWindow}>
        {messages.map((msg, index) => (
          <div
            key={index}
            className={[
              styles.messageContainer,
              msg.type === 'sent' ? styles.sent : styles.received
            ].join(' ')}
            ref={index === messages.length - 1 ? lastMessageRef : null}
          >
            <div
              className={
                msg.type === 'sent'
                  ? styles.messageText
                  : styles.messageTextReceived
              }
            >
              <Paragraph type='body2'>{msg.text}</Paragraph>
            </div>
            <div className={styles.timestamp}>{msg.timestamp}</div>
          </div>
        ))}
      </div>
      <div className={styles.textAreaContainer}>
        <TextArea
          placeholder='Escribe tu mensaje...'
          value={input}
          name='textarea'
          rows={1}
          onKeyDown={handleKeyDown}
          onChange={e => setInput(e.target.value)}
          onPaste={handlePaste}
          autoFocus
        />
        <Button
          label='Enviar'
          size='tiny'
          onClick={handleSend}
          className={styles.sendButton}
        />
      </div>
    </div>
  )
}

export default Chat
