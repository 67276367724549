//@ts-check
import React from 'react'
import useSpecialRequest from 'hooks/useSpecialRequest'
import { H2, Paragraph } from 'components/typography'
import PhoneInput from 'components/inputs/PhoneInput'
import TextArea from 'components/inputs/TextArea'
import { noop } from 'utils'
import Modal from './Modal'
import styles from './HelpModal.module.css'

function HelpModal({ onClose = noop }) {
  const {
    form,
    okDisabled,
    isSaving,
    handleFormChange,
    handlePhoneNumberChange,
    handleSave
  } = useSpecialRequest({ onClose })
  return (
    <Modal
      onCancel={onClose}
      onOk={handleSave}
      okDisabled={okDisabled}
      okText={isSaving ? 'Guardando...' : undefined}
    >
      <div className={styles.modal}>
        <H2 className={styles.h2}>Solicitud de ayuda</H2>
        <Paragraph>
          Estamos para ayudarte en lo que necesites. Rellena el siguiente
          espacio y nos pondremos en contacto contigo por WhatsApp o llamada
          telefónica
        </Paragraph>
        <PhoneInput
          value={form.phoneNumber?.fullPhoneNumber}
          onPhoneNumberChange={handlePhoneNumberChange}
        />
        <TextArea
          placeholder='Descríbenos tu duda...'
          value={form.description}
          onChange={handleFormChange}
        />
      </div>
    </Modal>
  )
}

export default HelpModal
