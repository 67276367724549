//@ts-check
import { useCallback, useState } from 'react'

/**
 *
 * @param {string} itemName
 * @param {any} initialValue
 * @returns {{item:any, saveItem:(newItem: any)=>void, getParsedItem:()=>any}}
 */
function useLocalStorage(itemName, initialValue) {
  const getParsedItem = useCallback(() => {
    const localStorageItem = localStorage.getItem(itemName)
    let parsedItem = initialValue
    if (!localStorageItem) {
      localStorage.setItem(itemName, JSON.stringify(initialValue))
    } else {
      parsedItem = JSON.parse(localStorageItem)
    }
    return parsedItem
  }, [initialValue, itemName])

  const [item, setItem] = useState(getParsedItem())

  const saveItem = useCallback(
    newItem => {
      localStorage.setItem(itemName, JSON.stringify(newItem))
      setItem(newItem)
    },
    [itemName]
  )

  return { item, saveItem, getParsedItem }
}
export default useLocalStorage
