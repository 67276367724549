//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { add, assoc, assocPath, dissocPath, isEmpty } from 'ramda'
import { dateToString } from 'utils/date'
import ChevronBottom from 'assets/icons/ChevronBottom'
import ChevronLeft from 'assets/icons/ChevronLeft'
import ChevronRight from 'assets/icons/ChevronRight'
import { findNotifications, removeNotifications } from 'api/notifications'
import { H2, Label, Paragraph } from 'components/typography'
import Radio from 'components/inputs/Radio'
import Button from 'components/buttons/Button'
import styles from './Notifications.module.css'

const PAGINATION = 10

function Notifications() {
  const [state, setState] = useState({
    isFetching: false,
    notifications: [],
    selected: {},
    open: {},
    index: 0
  })
  let fake = state.notifications
  const endIndex = Math.min(state.index + PAGINATION, fake.length)
  const finalNotifications = fake.slice(state.index, endIndex)

  const handleCheck = id =>
    setState(prevState => {
      let path = ['selected', id]
      if (state.selected[id]) return dissocPath(path, prevState)
      return assocPath(path, true, prevState)
    })

  const handleOpen = id =>
    setState(prevState => {
      let path = ['open', id]
      if (state.open[id]) return dissocPath(path, prevState)
      return assocPath(path, true, prevState)
    })

  const handleRemove = useCallback(() => {
    removeNotifications(Object.keys(state.selected))
      .then(data => setState(assoc('notifications', data)))
      .catch(e => {
        console.error('Error removing notifications: ', e)
      })
  }, [state.selected])

  const handleNext = () =>
    setState(prevState =>
      assoc('index', add(PAGINATION, prevState.index), prevState)
    )
  const handlePrev = () =>
    setState(prevState =>
      assoc('index', Math.max(prevState.index - PAGINATION, 0), prevState)
    )

  useEffect(() => {
    setState(assoc('isFetching', true))
    findNotifications()
      .then(notifications => setState(assoc('notifications', notifications)))
      .catch(e => {
        console.error('Error fetching notifications: ', e)
      })
      .finally(() => setState(assoc('isFetching', false)))
  }, [])

  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <H2>Mis notificaciones 🧐</H2>
        {!isEmpty(state.selected) && (
          <Button label='Eliminar' size='small' onClick={handleRemove} />
        )}
      </div>
      {isEmpty(state.notifications) && (
        <Paragraph>No tienes notificaciones pendientes</Paragraph>
      )}
      {finalNotifications.map(notification => {
        const isOpen = !!state.open[notification.id]
        const isSelected = !!state.selected[notification.id]
        return (
          <details key={notification.id} className={styles.details}>
            <summary
              className={[
                styles.summary,
                isSelected ? styles.selected : ''
              ].join(' ')}
              onClick={() => handleOpen(notification.id)}
            >
              <div className={styles.summaryRow}>
                <Radio
                  checked={isSelected}
                  onCheck={() => handleCheck(notification.id)}
                />
                <Paragraph type='body1Bold' className={styles.summaryTitle}>
                  {notification.label}
                </Paragraph>
                <Paragraph type='body2' className={styles.summaryDescription}>
                  {notification.description}
                </Paragraph>
                <div className={styles.summaryLastColumn}>
                  <Label className={styles.summaryDate}>
                    {dateToString(notification.createdAt)}
                  </Label>
                  {isOpen && <ChevronBottom className={styles.summaryIcon} />}
                </div>
              </div>
            </summary>
            <div className={styles.content}>
              <Paragraph type='body2' className={styles.contentParagraph}>
                {notification.description}
              </Paragraph>
            </div>
          </details>
        )
      })}
      <div className={styles.listActions}>
        {state.index > 0 && (
          <ChevronLeft className={styles.icon} onClick={handlePrev} />
        )}
        {endIndex < state.notifications.length && (
          <ChevronRight className={styles.icon} onClick={handleNext} />
        )}
      </div>
    </section>
  )
}

export default Notifications
