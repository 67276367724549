//@ts-check
import React, { useCallback, useEffect, useRef } from 'react'
import styles from './Slider.module.css'

const noop = (number = 0) => null
function Slider({
  min = 0,
  max = 100,
  step = 1,
  value = 0,
  labelSuffix = 'h',
  disabled = false,
  showLabel = true,
  onChange = noop
}) {
  /** @type {{current:HTMLElement | any}} */
  const inputRef = useRef(null)
  /** @type {{current:HTMLElement | any}} */
  const spanRef = useRef(null)
  const handleChange = useCallback(
    e => {
      if (disabled) return
      const { value } = e.target
      const eventValue = Number(value)
      if (eventValue < min || eventValue > max) return
      inputRef.current.style.setProperty('--value', eventValue)
      onChange(eventValue)
    },
    [max, min, onChange, disabled]
  )
  useEffect(() => {
    const input = inputRef.current
    if (!input) return
    input.style.setProperty('--value', value)
    input.style.setProperty('--min', input.min == '' ? min : input.min)
    input.style.setProperty('--max', input.max == '' ? max : input.max)
  }, [max, min, value])
  useEffect(() => {
    const input = inputRef.current
    const span = spanRef.current
    if (!input || !span) return
    const ratio = (input.value - input.min * 100) / (input.max - input.min)
    span.style.setProperty('left', `calc(0.5 * 1em + ${ratio} * (100% - 1em)`)
  }, [value])
  return (
    <div
      className={[styles.zoomSlider, disabled ? styles.disabled : ''].join(' ')}
    >
      <div
        className={styles.zoomIn}
        onClick={() => handleChange({ target: { value: value - step } })}
      >
        -
      </div>
      <div className={styles.inputContainer}>
        {showLabel && <span ref={spanRef}>{`${value}${labelSuffix}`}</span>}
        <input
          ref={inputRef}
          id='sliderInput'
          name='sliderInput'
          className={[styles.slider, disabled ? styles.disabled : ''].join(' ')}
          type='range'
          onChange={handleChange}
          min={min}
          max={max}
          step={step}
          value={value}
          disabled={disabled}
        />
      </div>
      <div
        className={styles.zoomOut}
        onClick={() => handleChange({ target: { value: value + step } })}
      >
        +
      </div>
    </div>
  )
}

export default Slider
