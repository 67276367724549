import { POST } from './index'

/**
 *
 * @param {{fullname:string, dni:string, file:File, signedBy:string}} data
 *
 */
export const createServiceTerms = (data = {}) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })
  return POST({
    url: 'students/service-terms/',
    data: formData,
    useAcceptAPP_JSON: true
  })
}
