//@ts-check
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import BrightClassfySvg from 'assets/logos/main/BrightClassfy'
import NoWorries from 'assets/svg/NoWorries'
import { recoverPassword } from 'api/auth'
import { useNotificationActions } from 'context/NotificationProvider'
import Button from 'components/buttons/Button'
import { H2 } from 'components/typography'
import styles from './ForgotPassword.module.css'

function ForgotPassword() {
  const [step, setStep] = useState(0)
  const [email, setEmail] = useState('')
  const [isRecovering, setIsRecovering] = useState(false)
  const { setErrorMessage } = useNotificationActions()

  const handleRecover = useCallback(() => {
    setIsRecovering(true)
    recoverPassword({ email })
      .then(() => setStep(1))
      .catch(e => setErrorMessage({ message: e.message }))
      .finally(() => setIsRecovering(false))
  }, [email, setErrorMessage])
  return (
    <main className={styles.main}>
      <Link to='/login'>
        <BrightClassfySvg className={styles.classfyLogo} />
      </Link>
      <div className={styles.container}>
        <div className={styles.images}>
          <NoWorries
            className={styles.backgroundSvg}
            color='var(--sandground)'
          />
          <NoWorries className={styles.mainSvg} />
        </div>
        <div className={styles.innerContainer}>
          {step ? (
            <H2 className={styles.h2}>
              Te hemos enviado un email con tu <br />
              <span>nueva contraseña</span>. <br />
              Incluye un enlace de <span>acceso rápido</span>.
            </H2>
          ) : (
            <>
              <input
                className={styles.email}
                name='email'
                type='text'
                placeholder='e-mail'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <Button
                label='Enviar'
                type='secondary'
                onClick={handleRecover}
                disabled={!email}
                loading={isRecovering}
              />
            </>
          )}
        </div>
      </div>
    </main>
  )
}

export default ForgotPassword
