//@ts-check
import React, { useState, useCallback } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import BrightClassfySvg from 'assets/logos/main/BrightClassfy'
import Svg from 'assets/svg'
import Alert from 'assets/icons/Alert'
import EyeClosedIcon from 'assets/icons/EyeClosedIcon'
import EyeOpenIcon from 'assets/icons/EyeOpenIcon'
import Button from 'components/buttons/Button'
import { useAuth } from 'context/AuthProvider'
import { login } from 'api/auth'
import styles from './Login.module.css'

const { LoginSvg, UupsSvg } = Svg

function makeErrorMessage(e) {
  if (!e || !e.message) return 'Error desconocido al iniciar sesión'
  return e.message
}

const initialState = { email: '', password: '' }
function Login() {
  const [form, setForm] = useState(initialState)
  const [showPassword, setShowPassword] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { saveAuthentication, isAuthenticated } = useAuth()

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const processSuccess = useCallback(
    data => {
      setLoading(false)
      if (!data || typeof data !== 'object') throw new Error('No auth data!')
      saveAuthentication(data)
      navigate('/')
    },
    [saveAuthentication, navigate]
  )
  const processError = useCallback(e => {
    console.error('Login error: ', e)
    setLoading(false)
    setErrorMessage(makeErrorMessage(e))
    setForm(initialState)
  }, [])

  const executeLogin = useCallback(() => {
    if (isLoading) return
    setLoading(true)
    login(form).then(processSuccess).catch(processError)
  }, [form, isLoading, processError, processSuccess])

  const handleEnter = useCallback(
    e => {
      // https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
      if (e.key === 'Enter') executeLogin()
    },
    [executeLogin]
  )

  const handleFormChange = useCallback(e => {
    const { name, value } = e.target
    setForm(form => ({ ...form, [name]: value.trim() }))
    setErrorMessage('')
  }, [])

  const Img = errorMessage ? UupsSvg : LoginSvg

  if (isAuthenticated) return <Navigate to='/' />
  return (
    <main className={styles.main} onKeyDown={handleEnter}>
      <BrightClassfySvg className={styles.classfyLogo} />
      <div className={styles.container}>
        <div className={styles.images}>
          <Img className={styles.backgroundSvg} color='var(--sandground)' />
          <Img className={styles.mainSvg} />
        </div>
        <div className={styles.innerContainer}>
          <input
            className={[
              styles.email,
              errorMessage ? styles.emailError : ''
            ].join(' ')}
            name='email'
            type='text'
            placeholder='e-mail'
            value={form.email}
            onChange={handleFormChange}
          />
          <div className={styles.passwordInputContainer}>
            <input
              className={[
                styles.password,
                errorMessage ? styles.passwordError : ''
              ].join(' ')}
              name='password'
              type={showPassword ? 'text' : 'password'}
              placeholder='contraseña'
              value={form.password}
              onChange={handleFormChange}
            />
            <div className={styles.eyeIcon} onClick={togglePasswordVisibility}>
              {showPassword ? <EyeClosedIcon /> : <EyeOpenIcon />}
            </div>
          </div>
          <div className={styles.recovery}>
            <Link to='/forgot-password'>
              <span>He olvidado mi contraseña</span>
            </Link>
          </div>
          <div className={styles.actions}>
            <Button
              label='Log in'
              size='large'
              type='secondary'
              disabled={!form.email || !form.password || isLoading}
              onClick={executeLogin}
            />
            {errorMessage && (
              <div className={styles.errorMessage}>
                <Alert className={styles.alertSvg} />
                <p>{errorMessage}</p>
              </div>
            )}
          </div>
          <div className={styles.register}>
            <span>¿No tienes cuenta? </span>
            <Link to='/register'>
              <span>Regístrate aquí</span>
            </Link>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Login
