//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { assoc, assocPath, isEmpty, omit, uniq } from 'ramda'
import { Link, Navigate } from 'react-router-dom'
import { dateToString, makeCustomDate } from 'utils/date'
import { getTestAttendanceCount, saveTestAttendance } from 'api/attendances'
import {
  getAvailableTestTeachers,
  getTeacherSchedulesByDate
} from 'api/teachers'
import { AttendanceTypes } from 'utils/constants'
import { useAuth } from 'context/AuthProvider'
import { useNotificationActions } from 'context/NotificationProvider'
import GoogleEventsAggregator from 'components/buttons/GoogleEventsAggregator'
import Back from 'assets/icons/Back'
import SubjectSelect from 'components/selects/SubjectSelect'
import Calendar from 'components/calendars/Calendar'
import Button from 'components/buttons/Button'
import VerticalTecherCard from 'components/cards/teacher/VerticalTecherCard'
import Modal from 'components/modals/Modal'
import { H2, H3, H4, Paragraph } from 'components/typography'
import HelpModal from 'components/modals/HelpModal'
import { AcademicBackgroundOptions } from 'components/selects/AcademicBackgroundSelect'
import styles from './NewlyRegistered.module.css'

function NewlyRegistered() {
  const [state, setState] = useState({
    step: 0,
    subjectsById: {},
    teachers: [],
    schedules: [],
    availableTeacherHours: { am: [], pm: [] },
    showConfirmModal: false,
    showHelpModal: false,
    isSaving: false,
    isFetchingSchedules: false,
    canBookAClass: true,
    visibleCalendarMonth: new Date(),
    form: {
      subjectId: '',
      teacherId: '',
      selectedDate: new Date(new Date().setHours(0, 0, 0, 0)),
      startHour: '',
      endHour: ''
    }
  })
  const { student } = useAuth()
  const { setErrorMessage } = useNotificationActions()
  const { isNewUser } = student
  const { form } = state

  const subjectFilter = useCallback(
    (subjects = []) =>
      subjects.filter(subject =>
        student.trial?.subjectIds?.includes(subject.id)
      ),
    [student]
  )
  const extractSubjectsById = useCallback(
    subjectsById => setState(state => ({ ...state, subjectsById })),
    []
  )
  const handleFormChange = useCallback(e => {
    const { name, value } = e.target
    setState(assocPath(['form', name], value))
  }, [])

  const targetTeacherIds = useMemo(() => {
    let teacherIds = []
    if (form.subjectId)
      teacherIds = state.teachers
        .filter(teacher => teacher?.subjects?.includes(form.subjectId))
        .map(teacher => teacher.id)
    return teacherIds
  }, [form.subjectId, state.teachers])

  const checkDisabledDate = useCallback(
    date => {
      const aux = Object.values(state.schedules).map(groupedSchedulesByDate => {
        const targetDate = groupedSchedulesByDate[date.getTime()]
        if (!targetDate) return true
        return isEmpty(targetDate.hours)
      })
      // if (isEmpty(state.schedules.schedules)) {
      //   const aux = state.schedules.defaultSchedules.map(({ schedule }) => {
      //     const day = schedule.find(
      //       ({ day }) =>
      //         DAY_NAME_BY_NUMBER.find(d => d.day === date.getDay()).label ===
      //         day
      //     )
      //     return isEmpty(day.hours.concat(day.halfHours))
      //   })
      //   return !aux.includes(false)
      // }
      // const aux = Object.values(state.schedules.schedules).map(
      //   groupedSchedulesByDate => {
      //     const targetDate = groupedSchedulesByDate[date.getTime()]
      //     if (!targetDate) {
      //       const defaultAux = state.schedules.defaultSchedules.map(
      //         ({ schedule }) => {
      //           const day = schedule.find(
      //             ({ day }) =>
      //               DAY_NAME_BY_NUMBER.find(d => d.day === date.getDay())
      //                 .label === day
      //           )
      //           return isEmpty(day.hours.concat(day.halfHours))
      //         }
      //       )
      //       return !defaultAux.includes(false)
      //     }
      //     return isEmpty(targetDate.hours)
      //   }
      // )
      return !aux.includes(false)
    },
    [state.schedules]
  )

  const handleChangeVisibleMonth = e =>
    setState(prevState => {
      const newVisibleCalendarMonth = e.target.value
      let targetDate = new Date(new Date().setHours(0, 0, 0, 0))

      if (targetDate.getMonth() != newVisibleCalendarMonth.getMonth())
        targetDate = new Date(
          new Date(
            newVisibleCalendarMonth.getFullYear(),
            newVisibleCalendarMonth.getMonth(),
            1
          ).setHours(0, 0, 0, 0)
        )

      return {
        ...prevState,
        visibleCalendarMonth: newVisibleCalendarMonth,
        form: { ...prevState.form, selectedDate: targetDate }
      }
    })

  const handleSelectTeacher = useCallback(
    teacherId => {
      handleFormChange({
        target: { name: 'teacherId', value: teacherId }
      })
      setState(assoc('showConfirmModal', true))
    },
    [handleFormChange]
  )
  const handleCloseModal = () => setState(assoc('showConfirmModal', false))
  const handleSave = useCallback(() => {
    setState(assoc('isSaving', true))
    saveTestAttendance({
      ...omit(['selectedDate', 'startHour', 'endHour'], form),
      classStart: makeCustomDate(form.selectedDate, form.startHour),
      classEnd: makeCustomDate(form.selectedDate, form.endHour),
      type: AttendanceTypes.TEST
    })
      .then(() => {
        setState(state => ({
          ...state,
          showConfirmModal: false,
          step: state.step + 1
        }))
      })
      .catch(e => {
        console.error('Error saving test attendance: ', e)
        setErrorMessage({
          title: 'Error en la reserva',
          message:
            'Parece que ha habido un problema, inténtalo con otra configuración o más tarde'
        })
      })
      .finally(() => setState(assoc('isSaving', false)))
  }, [form, setErrorMessage])

  const filteredTeachers = useMemo(
    () =>
      state.teachers.filter(teacher => {
        if (!teacher.subjects.includes(form.subjectId)) return

        const teacherSchedule =
          state.schedules[teacher.id]?.[new Date(form.selectedDate).getTime()]
        if (!teacherSchedule) return

        const targetHour = `${form.startHour}-${form.endHour}`
        if (teacherSchedule.hours.includes(targetHour)) return teacher
        // const teacherSchedule =  state.schedules.schedules[teacher.id]?.[
        //     new Date(form.selectedDate).getTime()
        //   ]
        // if (!teacherSchedule) {
        //   let defaultTeacherSchedule = state.schedules.defaultSchedules.find(
        //     s => s.teacherId === teacher.id
        //   )
        //   if (!defaultTeacherSchedule) return
        //   const day = defaultTeacherSchedule.schedule.find(
        //     ({ day }) =>
        //       DAY_NAME_BY_NUMBER.find(d => d.day === form.selectedDate.getDay())
        //         .label === day
        //   )
        //   const defaultTargetHour = `${form.startHour} - ${form.endHour}`
        //   if (day.hours.concat(day.halfHours).includes(defaultTargetHour))
        //     return teacher
        // } else {
        //   const targetHour = `${form.startHour} - ${form.endHour}`
        //   if (teacherSchedule.hours.includes(targetHour)) return teacher
        // }
      }),
    [
      form.endHour,
      form.selectedDate,
      form.startHour,
      form.subjectId,
      state.schedules,
      state.teachers
    ]
  )

  const extractAvailableTeacherHours = useCallback(() => {
    const { am = [], pm = [] } = Object.values(state.schedules).reduce(
      (acc, groupedSchedulesByDate) => {
        const targetDate = groupedSchedulesByDate[form.selectedDate.getTime()]

        if (!targetDate) return acc

        const allHours = targetDate.hours
        acc.am = acc.am.concat(
          ...allHours.filter(hour => hour.split(':')[0] < 15)
        )
        acc.pm = acc.pm.concat(
          ...allHours.filter(hour => hour.split(':')[0] >= 15)
        )
        return acc
        // if (isEmpty(state.schedules.schedules)) {
        //   const value = form.selectedDate
        //   const { am = [], pm = [] } = state.schedules.defaultSchedules.reduce(
        //     (acc, { schedule }) => {
        //       const day = schedule.find(
        //         ({ day }) =>
        //           DAY_NAME_BY_NUMBER.find(d => d.day === value.getDay()).label ===
        //           day
        //       )
        //       let allHours = day.hours.concat(day.halfHours)
        //       acc.am = acc.am.concat(
        //         ...allHours.filter(hour => hour.split(':')[0] < 15)
        //       )
        //       acc.pm = acc.pm.concat(
        //         ...allHours.filter(hour => hour.split(':')[0] >= 15)
        //       )
        //       return acc
        //     },
        //     { am: [], pm: [] }
        //   )
        //   setState(state => ({
        //     ...state,
        //     availableTeacherHours: {
        //       am: uniq(am.sort()),
        //       pm: uniq(pm.sort())
        //     }
        //   }))
        //   return
        // }
        // const { am = [], pm = [] } = Object.values(
        //   state.schedules.schedules
        // ).reduce(
        //   (acc, groupedSchedulesByDate) => {
        //     const targetDate = groupedSchedulesByDate[form.selectedDate.getTime()]

        //     if (!targetDate) {
        //       const value = form.selectedDate
        //       const { am = [], pm = [] } = state.schedules.defaultSchedules.reduce(
        //         (acc, { schedule }) => {
        //           const day = schedule.find(
        //             ({ day }) =>
        //               DAY_NAME_BY_NUMBER.find(d => d.day === value.getDay())
        //                 .label === day
        //           )
        //           let allHours = day.hours.concat(day.halfHours)
        //           acc.am = acc.am.concat(
        //             ...allHours.filter(hour => hour.split(':')[0] < 15)
        //           )
        //           acc.pm = acc.pm.concat(
        //             ...allHours.filter(hour => hour.split(':')[0] >= 15)
        //           )
        //           return acc
        //         },
        //         { am: [], pm: [] }
        //       )

        //       acc.am = acc.am.concat(am)
        //       acc.pm = acc.pm.concat(pm)

        //       return acc
        //     } else {
        //       const allHours = targetDate.hours
        //       acc.am = acc.am.concat(
        //         ...allHours.filter(hour => hour.split(':')[0] < 15)
        //       )
        //       acc.pm = acc.pm.concat(
        //         ...allHours.filter(hour => hour.split(':')[0] >= 15)
        //       )
        //       return acc
        //     }
      },
      { am: [], pm: [] }
    )
    setState(state => ({
      ...state,
      availableTeacherHours: {
        am: uniq(am.sort()),
        pm: uniq(pm.sort())
      }
    }))
  }, [form.selectedDate, state.schedules])

  useEffect(() => {
    extractAvailableTeacherHours()
  }, [extractAvailableTeacherHours])

  useEffect(() => {
    handleFormChange({ target: { name: 'startHour', value: '' } })
    handleFormChange({ target: { name: 'endHour', value: '' } })
  }, [form.selectedDate, form.subjectId, handleFormChange])

  useEffect(() => {
    if (!isEmpty(student))
      getAvailableTestTeachers({
        eligible: true,
        subjects: { $in: student.trial?.subjectIds || [], useCleanedId: true }
      })
        .then(teachers => setState(state => ({ ...state, teachers })))
        .catch(e => {
          console.error('Error fetching teachers: ', e)
        })
  }, [student])

  useEffect(() => {
    setState(assoc('isFetchingSchedules', true))
    if (isEmpty(targetTeacherIds))
      return setState(state => ({
        ...state,
        isFetchingSchedules: false,
        schedules: []
      }))

    getTeacherSchedulesByDate({
      teacherIds: targetTeacherIds,
      date: state.visibleCalendarMonth
    })
      .then(schedules => {
        if (!schedules || isEmpty(schedules))
          return setState(prevState => ({
            ...prevState,
            isFetchingSchedules: false,
            schedules: []
          }))
        setState(prevState => ({
          ...prevState,
          schedules,
          isFetchingSchedules: false
        }))
      })
      .catch(e => {
        setState(assoc('isFetchingSchedules', false))
        console.error('Error fetching schedules: ', e)
      })
  }, [state.visibleCalendarMonth, targetTeacherIds])
  useEffect(() => {
    getTestAttendanceCount().then(testAttendanceCount =>
      setState(state => ({
        ...state,
        canBookAClass: isNewUser && !testAttendanceCount
      }))
    )
  }, [isNewUser])
  if (!state.canBookAClass) return <Navigate to='/' />
  return (
    <section className={styles.section}>
      {state.step === 0 && (
        <InitialState
          student={student}
          form={form}
          filter={subjectFilter}
          extractSubjectsById={extractSubjectsById}
          isFetchingSchedules={state.isFetchingSchedules}
          isSpecialCase={
            (student.trial?.subjectIds || []).length === 0 ||
            [
              AcademicBackgroundOptions.OTROS,
              AcademicBackgroundOptions.UNIVERSIDAD
            ].includes(student.trial?.academicBackground)
          }
          hours={state.availableTeacherHours}
          onChangeVisibleMonth={handleChangeVisibleMonth}
          onChangeDate={handleFormChange}
          onChange={handleFormChange}
          checkDisabledDate={checkDisabledDate}
          onNext={() => setState(state => ({ ...state, step: state.step + 1 }))}
          onHelp={() => setState(assoc('showHelpModal', true))}
        />
      )}
      {state.step === 1 && (
        <Step1
          student={student}
          subjectsById={state.subjectsById}
          teachers={filteredTeachers}
          onSelectTeacher={handleSelectTeacher}
          onBack={() => setState(state => ({ ...state, step: 0 }))}
        />
      )}
      {state.step === 2 && (
        <Step2
          form={form}
          student={student}
          subjectsById={state.subjectsById}
          teacher={state.teachers.find(({ id }) => id === form.teacherId)}
        />
      )}
      {state.showConfirmModal && (
        <Modal
          onCancel={handleCloseModal}
          onOk={handleSave}
          okDisabled={state.isSaving}
          okText={state.isSaving ? 'Guardando...' : undefined}
        >
          <div className={styles.modalContent}>
            <H2 className={styles.titleModal}>Confirmación</H2>

            <Paragraph className={styles.modalParagraph}>
              Estás a punto de agendar una clase de{' '}
              <strong>{state.subjectsById[form.subjectId]?.name}</strong> el{' '}
              <strong>{dateToString(form.selectedDate)}</strong> a las{' '}
              <strong>{form.startHour}</strong> con{' '}
              <strong>
                {state.teachers.find(({ id }) => id === form.teacherId)?.name}
              </strong>
            </Paragraph>
          </div>
        </Modal>
      )}
      {state.showHelpModal && (
        <HelpModal onClose={() => setState(assoc('showHelpModal', false))} />
      )}
    </section>
  )
}

function InitialState({
  student,
  form,
  filter,
  extractSubjectsById,
  isFetchingSchedules,
  isSpecialCase,
  hours,
  checkDisabledDate,
  onChangeVisibleMonth,
  onChangeDate,
  onChange,
  onNext,
  onHelp
}) {
  return (
    <>
      <H2 className={styles.h2}>No problem {student?.shortName}!</H2>
      <H3 className={styles.h3}>{getText(form, isSpecialCase)}</H3>

      <SubjectSelect
        placeholder='Selecciona tu asignatura'
        showLabel={false}
        value={form.subjectId}
        filter={filter}
        extractSubjectsById={extractSubjectsById}
        onChange={onChange}
        customStyles={{ container: styles.customSelectContainer }}
        disabled={isSpecialCase}
      />
      <div className={styles.calendarWrapper}>
        <Calendar
          disabled={!form.subjectId || isFetchingSchedules}
          selectedDate={form.selectedDate}
          startHour={form.startHour}
          endHour={form.endHour}
          hours={hours}
          onChangeDate={onChangeDate}
          onChangeHour={onChange}
          onChangeVisibleMonth={onChangeVisibleMonth}
          customCheckDisabled={checkDisabledDate}
          disabledPast
        />
      </div>
      <div className={styles.toolbar}>
        <Button label='Ayuda' size='small' type='warning' onClick={onHelp} />
        <Button
          label='Agendar clase'
          size='small'
          disabled={!form.subjectId || !form.selectedDate || !form.startHour}
          onClick={onNext}
        />
      </div>
    </>
  )
}

function Step1({ student, teachers, subjectsById, onSelectTeacher, onBack }) {
  return (
    <>
      <div className={styles.step1TitleSection}>
        <Back
          color='var(--sandground)'
          className={styles.backIcon}
          onClick={onBack}
        />
        <H2 className={styles.h2}>¡Ya no queda nada {student?.shortName}!</H2>
      </div>

      <H3 className={styles.h3}>
        {' '}
        Tenemos estas opciones para tu <strong>clase de prueba.</strong>
        <br />
        <strong>Elige</strong> la que más te guste & <strong>Brain On!</strong>
      </H3>

      <div className={styles.teacherList}>
        {teachers?.map((teacher, index) => {
          return (
            <VerticalTecherCard
              key={index}
              teacher={teacher}
              subjectsById={subjectsById}
              onClick={() => onSelectTeacher(teacher.id)}
              buttonLabel='Agendar clase'
            />
          )
        })}
      </div>
    </>
  )
}

function Step2({ student, form, subjectsById, teacher = {} }) {
  const [isCalendarEventAdded, setIsCalendarEventAdded] = useState(false)
  const [isSignedIn, setIsSignedIn] = useState(false)
  return (
    <div className={styles.step2}>
      <H2 className={styles.h2}>¡Enhorabuena {student?.shortName}!</H2>
      <H4 className={styles.h4}>
        El día <strong>{dateToString(form.selectedDate)}</strong>, a las{' '}
        <strong>{form.startHour}h</strong>, tienes tu clase de{' '}
        <strong>{subjectsById[form.subjectId]?.name}</strong> de prueba con tu
        profe <strong>{teacher.name}.</strong>
      </H4>
      <div className={styles.googleCalendarContainer}>
        {!isCalendarEventAdded ? (
          <>
            {!isSignedIn ? (
              <Paragraph className={styles.paragraph}>
                ¿Quieres añadirlo a tu{' '}
                <strong>
                  <span className={styles.g}>G</span>
                  <span className={styles.o1}>o</span>
                  <span className={styles.o2}>o</span>
                  <span className={styles.g}>g</span>
                  <span className={styles.l}>l</span>
                  <span className={styles.e}>e</span>{' '}
                </strong>
                Calendar? 🗓️
              </Paragraph>
            ) : (
              <Paragraph>
                Una vez autorizado, haz click para completar la acción. 😎
              </Paragraph>
            )}
            <GoogleEventsAggregator
              description={`El día ${dateToString(
                form.selectedDate
              )} tendrás tu clase de prueba de ${
                subjectsById[form.subjectId]?.name
              } con tu profe ${teacher.name}.`}
              startDateTime={makeCustomDate(form.selectedDate, form.startHour)}
              endDateTime={makeCustomDate(form.selectedDate, form.endHour)}
              onListenEventAdded={setIsCalendarEventAdded}
              onIsSignedIn={setIsSignedIn}
            />
          </>
        ) : (
          <Paragraph>
            Hemos agregado con éxito tu{' '}
            <strong>
              {' '}
              <span className={styles.testClass}>clase de prueba</span>
            </strong>{' '}
            a tu calendario. 👏
          </Paragraph>
        )}
      </div>
      <div className={styles.step2SelectedTeacher}>
        <Link to='/'>
          <VerticalTecherCard
            teacher={teacher}
            subjectsById={subjectsById}
            date={form.selectedDate}
            startHour={form.startHour}
            buttonLabel='Ir al home'
          />
        </Link>
      </div>
    </div>
  )
}

function getText(form, isSpecialCase) {
  if (isSpecialCase)
    return (
      <>
        Nuestro <strong>equipo docente</strong> revisará tus necesidades y te
        contactaremos lo antes posible.
        <br />
        Estate atento al buzón de entrada de tu <strong>email</strong>.
        <br />
        Si quieres que te <strong>llamemos</strong> y contarnos más detalles,
        pulsa el botón de <strong>"Ayuda"</strong> y rellena los datos .
      </>
    )
  if (!form.subjectId)
    return (
      <>
        Elige tu <strong>asignatura</strong>, dinos qué día y hora te viene
        mejor
        <br />
        para agendar tu <strong>clase de prueba</strong>
      </>
    )
  return (
    <>
      Dinos qué día y hora te viene mejor
      <br />y agendamos tu <strong>clase de prueba</strong>
    </>
  )
}

export default NewlyRegistered
