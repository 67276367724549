//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useNotificationActions } from 'context/NotificationProvider'
import { changeStatusToReview, getBudgetById } from 'api/budgets'
import { H2, H3, H4, H5, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import Modal from 'components/modals/Modal'
import RingsSpinner from 'components/spinners/RingsSpinner'
import Back from 'assets/icons/Back'
import useSubjects from 'hooks/useSubjects'
import { SubscriptionCodeLabels, SubscriptionCodes } from 'utils/constants'
import styles from './ProposalDetails.module.css'

const buttonTextByStatus = {
  pending: 'Aceptar',
  reviewing: 'Revisando',
  accepted: 'Aceptado'
}

function ProposalDetails() {
  const { budgetId } = useParams()

  const [budgetDetails, setBudgetDetails] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isConfirm, setIsConfirm] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useNotificationActions()

  const { subjectsById, isFetching, fetchSubjects } = useSubjects({
    fetchOnMount: false
  })

  const fetchData = useCallback(() => {
    getBudgetById(budgetId)
      .then(response => {
        setBudgetDetails(response)
        setLoading(false)
      })
      .catch(error => {
        setErrorMessage({ message: error.message })
        console.error('Error al obtener detalles de la propuesta:', error)
      })
      .finally(() => setLoading(false))
  }, [budgetId, setErrorMessage])

  const handleReview = useCallback(() => {
    setIsSaving(true)
    changeStatusToReview(budgetId)
      .then(() => {
        fetchData()
        setSuccessMessage({
          title: 'Éxito',
          message: 'El presupuesto ha sido enviado para revisión exitosamente.'
        })
      })
      .catch(error => {
        setErrorMessage({ message: error.message })
        console.error('Failed to change status:', error)
      })
      .finally(() => {
        setIsSaving(false)
        setIsConfirm(false)
      })
  }, [budgetId, fetchData, setErrorMessage, setSuccessMessage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if (budgetDetails.selectedSubjectIds?.length > 0)
      fetchSubjects({
        _id: { $in: budgetDetails.selectedSubjectIds },
        active: '_both_'
      })
  }, [budgetDetails, fetchSubjects])

  return (
    <section className={styles.section}>
      <H2>
        <Link to='/proposals'>
          <Back color='var(--sandground)' className={styles.backIcon} />
        </Link>
        Tu propuesta
      </H2>

      {loading || isFetching ? (
        <RingsSpinner />
      ) : (
        <div className={styles.card}>
          <div className={styles.subscriptionDetails}>
            <H4 className={styles.proposalName}>
              {SubscriptionCodeLabels[budgetDetails.config?.code]}
            </H4>
            <Paragraph>{budgetDetails.config?.hours}/horas</Paragraph>
          </div>

          <div className={styles.subjectsContainer}>
            {budgetDetails.selectedSubjectIds?.map(
              (selectedSubjectId, index) => {
                return (
                  <div className={styles.subject} key={index}>
                    <Paragraph>
                      {subjectsById[selectedSubjectId]?.label}
                    </Paragraph>

                    <Paragraph>
                      {makeText(budgetDetails.config, selectedSubjectId)}
                    </Paragraph>
                  </div>
                )
              }
            )}
          </div>
          <div className={styles.divider} />
          <div className={styles.totalContainer}>
            <Paragraph className={styles.totalLabel}>Total </Paragraph>
            <Paragraph className={styles.totalPrice}>
              {budgetDetails.totalPrice}€/mes
            </Paragraph>
          </div>

          <Button
            label={buttonTextByStatus[budgetDetails.status] || 'Aceptar'}
            size='small'
            className={styles.acceptButton}
            onClick={() => setIsConfirm(true)}
            disabled={
              !budgetDetails || isSaving || budgetDetails.status !== 'pending'
            }
          />

          <div className={styles.conditionsContainer}>
            <H5>Condiciones</H5>
            {budgetDetails?.config?.conditions?.map((condition, index) => (
              <Paragraph key={index} className={styles.conditionText}>
                * {condition}
              </Paragraph>
            ))}
          </div>
          <Paragraph className={styles.dateText}>
            * Este presupuesto ha sido configurado para el{' '}
            <strong>
              {('0' + (budgetDetails?.config?.projection?.month + 1)).slice(-2)}
              /{budgetDetails?.config?.projection?.year}
            </strong>
          </Paragraph>
          {isConfirm && (
            <Modal
              onCancel={() => setIsConfirm(false)}
              onOk={handleReview}
              isLoading={isSaving}
            >
              <H3>Aceptar propuesta</H3>
              <div className={styles.confirmContainer}>
                <Paragraph>
                  <strong>¿Estás seguro de que deseas continuar?</strong> <br />
                  Al confirmar, esta propuesta pasará a ser{' '}
                  <strong>revisada</strong> por <strong>un admin</strong> quien
                  se comunicará contigo para ultimar los detalles.
                </Paragraph>
              </div>
            </Modal>
          )}
        </div>
      )}
    </section>
  )
}

export default ProposalDetails

function makeText(config = {}, selectedSubjectId) {
  switch (config?.code) {
    case SubscriptionCodes.NORMAL:
      return makeNormalText(config.projection, selectedSubjectId)
    case SubscriptionCodes.MORNINGS:
      return makeMorningsText(config.projection, selectedSubjectId)
    case SubscriptionCodes.OTHERS:
    case SubscriptionCodes.PACK:
      return '-'
    default: {
      console.warn('Unknown code: ' + config?.code)
      return ''
    }
  }
}

function makeNormalText(projection = {}, selectedSubjectId) {
  const hour = projection.week[selectedSubjectId]
  return `${hour}h/semana`
}
function makeMorningsText(projection = {}, selectedSubjectId) {
  const hour = projection.hoursBySubjectId[selectedSubjectId]
  return `${hour}h/mes`
}
