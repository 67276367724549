//@ts-check
import React, { useCallback } from 'react'
import ReactDatePicker from 'react-datepicker'
import es from 'date-fns/locale/es'
import styles from './DatePicker.module.css'
import './DatePicker.css'

function DatePicker({
  name,
  value = null,
  placeholder = 'Selecciona una fecha',
  isClearable = true,
  disabled = false,
  showTimeSelect = false,
  showTimeSelectOnly = false,
  error = false,
  required = false,
  onChange
}) {
  const handleChange = useCallback(
    date => {
      //TODO: "use only hour" needs change value to date
      onChange({ target: { name, value: date } })
    },
    [name, onChange]
  )

  return (
    <ReactDatePicker
      wrapperClassName={styles.wrapper}
      clearButtonClassName={styles.clear}
      className={[styles.picker, error ? styles.error : ''].join(' ')}
      locale={es}
      selected={processDate(value)}
      placeholderText={placeholder}
      isClearable={isClearable}
      disabled={disabled}
      required={required}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      timeCaption='Hora'
      todayButton={!showTimeSelectOnly && 'Hoy'}
      dateFormat={
        showTimeSelectOnly
          ? 'HH:mm aa'
          : showTimeSelect
          ? 'dd/MM/yyyy - HH:mm aa'
          : 'dd/MM/yyyy'
      }
      timeFormat='HH:mm aa'
      showPopperArrow={false}
      onChange={handleChange}
      showYearDropdown
    />
  )
}

export default DatePicker

function processDate(date) {
  const targetDate = Date.parse(date)
  if (!isNaN(targetDate)) return new Date(targetDate)
  console.warn('No es una fecha válida: ', date)
  return null
}
