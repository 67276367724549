//@ts-check
import { useCallback, useEffect, useState } from 'react'
import { noop } from 'utils'
import { TicketTypes } from 'utils/constants'
import { useNotificationActions } from 'context/NotificationProvider'
import { useAuth } from 'context/AuthProvider'
import { createSpecialRequestTicket } from 'api/ticketing'
import useFormManager from './useFormManager'

const COMMON_REQUIRED_FIELDS = ['phoneNumber.cleanedPhone', 'description']
const BUDGET_REQUIRED_FIELDS = ['phoneNumber.cleanedPhone']

export default function useSpecialRequest({
  type = TicketTypes.APP_HELP,
  onClose = noop
}) {
  const [isSaving, setIsSaving] = useState(false)
  const { setErrorMessage, setSuccessMessage } = useNotificationActions()
  const {
    form,
    hasPendingRequiredFields,
    handleFormChange,
    handlePhoneNumberChange,
    clearForm,
    clearRequiredFields
  } = useFormManager(COMMON_REQUIRED_FIELDS)
  const { student } = useAuth()

  const handleSave = useCallback(() => {
    setIsSaving(true)
    createSpecialRequestTicket({
      subject: form.phoneNumber.fullPhoneNumber,
      thread: [{ content: form.description, createdBy: student.id }],
      createdBy: student.id,
      type
    })
      .then(() => {
        setSuccessMessage({})
        onClose()
        clearForm()
      })
      .catch(e => setErrorMessage({ message: e.message }))
      .finally(() => setIsSaving(false))
  }, [
    form,
    setErrorMessage,
    setSuccessMessage,
    student,
    onClose,
    clearForm,
    type
  ])
  useEffect(() => {
    switch (type) {
      case TicketTypes.BUDGET: {
        clearRequiredFields(BUDGET_REQUIRED_FIELDS)
        break
      }
      default:
        break
    }
  }, [clearRequiredFields, type])
  return {
    form,
    okDisabled: hasPendingRequiredFields || isSaving,
    isSaving,
    handleFormChange,
    handlePhoneNumberChange,
    handleSave
  }
}
