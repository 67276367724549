import { GET, POST, PUT } from './'

export const findBudgets = ({ useCreatedByMe = false }) =>
  GET({ url: `students/budgets?useCreatedByMe=${useCreatedByMe}` })

export const findAgoraProposals = ({ useCreatedByMe = false }) =>
  GET({
    url: `students/budgets/agora-proposals?useCreatedByMe=${useCreatedByMe}`
  })

export const getBudgetById = id => GET({ url: `students/budgets/${id}` })

export const createAgoraProposal = (
  month = new Date().getMonth(),
  year = new Date().getFullYear()
) =>
  POST({ url: `students/budgets/create-agora-proposal`, data: { month, year } })

export const changeStatusToReview = id =>
  PUT({ url: `students/budgets/ready-to-review/${id}` })
