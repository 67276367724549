import * as React from 'react'
const EyeClosedIcon = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 32 32'
    fill={props.fill || 'none'}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M29.561.439a1.5 1.5 0 0 0-2.121 0l-6.318 6.318A17.626 17.626 0 0 0 16 6C9.021 6 2.972 10.064 0 16a18.323 18.323 0 0 0 5.407 6.472L.439 27.44a1.5 1.5 0 1 0 2.122 2.121l27-27a1.5 1.5 0 0 0 0-2.121zM13 10a3 3 0 0 1 2.841 2.037l-3.804 3.804A3 3 0 0 1 13 10zm-9.559 6a15.223 15.223 0 0 1 5.041-4.925A7.985 7.985 0 0 0 8 13.812c0 1.715.54 3.304 1.459 4.607l-1.904 1.904A15.219 15.219 0 0 1 3.441 16zM24 13.813c0-.849-.133-1.667-.378-2.434L13.566 21.435a8 8 0 0 0 10.435-7.622z'
      fill={props.color || '#8080F8'}
    />
    <path
      d='M25.938 9.062 23.77 11.23l.118.074a15.212 15.212 0 0 1 4.67 4.697 15.223 15.223 0 0 1-4.67 4.697 14.642 14.642 0 0 1-11.45 1.864l-2.403 2.403a17.587 17.587 0 0 0 5.964 1.036c6.978 0 13.027-4.064 16-10a18.292 18.292 0 0 0-6.062-6.938z'
      fill={props.color || '#8080F8'}
      strokeWidth={3}
    />
  </svg>
)
export default EyeClosedIcon
