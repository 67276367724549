//@ts-check
import React, { useState, useLayoutEffect, useCallback } from 'react'
import { drawLine } from '../../../utils/canvas'
import styles from './Signature.module.css'

const brush = { color: '#1a1a1a', lineWidth: 2 }
function Signature({ onSave, canvas }) {
  const [isDrawing, setDrawing] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isCanvasReady, setCanvasReady] = useState(false)

  const handleGenerateSignature = useCallback(() => {
    if (!canvas.current) return
    const faux_canvas = document.createElement('canvas')
    faux_canvas.width = canvas.current.width
    faux_canvas.height = canvas.current.height
    const faux_context = faux_canvas.getContext('2d', {
      willReadFrequently: true
    })
    faux_context.fillStyle = '#fff'
    faux_context.fillRect(0, 0, canvas.current.width, canvas.current.height)
    faux_context.drawImage(canvas.current, 0, 0)
    const data = faux_canvas.toDataURL('image/jpeg')
    onSave(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSave])

  const throttle = useCallback((callback, delay) => {
    let previousCall = new Date().getTime()
    return function () {
      const time = new Date().getTime()
      if (time - previousCall >= delay) {
        previousCall = time
        callback.apply(null, arguments)
      }
    }
  }, [])

  const handleMouseDown = useCallback(e => {
    if (!canvas.current) return
    const { clientY, clientX } = e
    setDrawing(true)
    const { x, y } = canvas.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMouseMove = useCallback(
    e => {
      if (!isDrawing) return
      const { clientY, clientX } = e
      const { width, height, x, y } = canvas.current.getBoundingClientRect()
      const endX = clientX - x
      const endY = clientY - y
      drawLine(
        canvas,
        position.x,
        position.y,
        endX,
        endY,
        brush.color,
        brush.lineWidth
      )

      setPosition({ x: endX, y: endY })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDrawing, position]
  )

  const handleMouseLeave = useCallback(
    e => {
      if (!isDrawing) return
      const { clientY, clientX } = e
      const { width, height, x, y } = canvas.current.getBoundingClientRect()
      const endX = clientX - x
      const endY = clientY - y
      drawLine(
        canvas,
        position.x,
        position.y,
        endX,
        endY,
        brush.color,
        brush.lineWidth
      )
      setDrawing(false)
      handleGenerateSignature()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleGenerateSignature, isDrawing, position.x, position.y]
  )

  const handleTouchDown = useCallback(e => {
    if (!canvas.current) return
    const { clientY, clientX } = e.touches[0]
    setDrawing(true)
    const { x, y } = canvas.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTouchMove = useCallback(
    e => {
      if (!isDrawing) return
      const { clientY, clientX } = e.touches[0]
      const { width, height, x, y } = canvas.current.getBoundingClientRect()
      const endX = clientX - x
      const endY = clientY - y
      drawLine(
        canvas,
        position.x,
        position.y,
        endX,
        endY,
        brush.color,
        brush.lineWidth
      )
      setPosition({ x: endX, y: endY })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDrawing, position]
  )

  const handleTouchLeave = useCallback(() => {
    if (!isDrawing) return
    setDrawing(false)
    handleGenerateSignature()
  }, [handleGenerateSignature, isDrawing])

  useLayoutEffect(() => {
    const cancelCanvasTouch = e =>
      e.target === canvas.current && e.preventDefault()

    document.body.addEventListener('touchstart', cancelCanvasTouch)
    document.body.addEventListener('touchend', cancelCanvasTouch)
    document.body.addEventListener('touchmove', cancelCanvasTouch)

    return () => {
      document.body.removeEventListener('touchstart', cancelCanvasTouch)
      document.body.removeEventListener('touchend', cancelCanvasTouch)
      document.body.removeEventListener('touchmove', cancelCanvasTouch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    if (isCanvasReady) return
    const { width, height } = canvas.current.getBoundingClientRect()
    if (width === 0 || height === 0) return
    canvas.current.width = width
    canvas.current.height = height
    setCanvasReady(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCanvasReady])

  useLayoutEffect(() => {
    const resize = () => {
      canvas.current.width = canvas.current.getBoundingClientRect().width
      canvas.current.height = canvas.current.getBoundingClientRect().height
    }
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.Pizarra} data-status='active'>
      <canvas
        className={styles.PizarraCanvas}
        ref={canvas}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseLeave}
        onMouseOut={handleMouseLeave}
        onMouseMove={throttle(handleMouseMove, 10)}
        onTouchStart={handleTouchDown}
        onTouchEnd={handleTouchLeave}
        onTouchCancel={handleTouchLeave}
        onTouchMove={throttle(handleTouchMove, 10)}
      />
    </div>
  )
}
export default Signature
