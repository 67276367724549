import * as Sentry from '@sentry/react'
import CONFIG from 'config'
import { getAuthenticationToken } from 'utils'

export const BASE_URL = `${CONFIG.apiURL}/api/`
const COMMON_ERROR_MESSAGE = 'Desafortunadamente algo ha ido mal...'
const NOT_ACTIVE = 'student.account.notActive'

export function makeError(error = {}) {
  const customError = new Error(error?.userMessage ?? COMMON_ERROR_MESSAGE)
  customError.code = error?.code
  customError.originalMessage = error?.message
  customError.userMessage = error?.userMessage
  customError.statusCode = error?.statusCode
  customError.isLogin = error?.isLogin
  return customError
}

export const processResponse = async response => {
  const JSONResponse = await response.json()
  if (response.ok) return JSONResponse.data
  const error = makeError(JSONResponse.data)
  if (error.code === NOT_ACTIVE) {
    localStorage.removeItem(CONFIG.accessTokenKey)
    !error.isLogin && window.location.reload()
  }
  throw error
}
function makeHeaders(useAuth = false, useAcceptAPP_JSON = false) {
  return useAuth
    ? {
        ...(useAcceptAPP_JSON
          ? { Accept: 'application/json' }
          : { 'Content-Type': 'application/json' }),
        Authorization: `Bearer ${getAuthenticationToken()}`
      }
    : {
        ...(useAcceptAPP_JSON
          ? { Accept: 'application/json' }
          : { 'Content-Type': 'application/json' })
      }
}
export function GET({ url = '', useAuth = true }) {
  return fetch(BASE_URL + url, {
    method: 'GET',
    headers: makeHeaders(useAuth)
  })
    .then(async response => {
      return await processResponse(response)
    })
    .catch(e => {
      Sentry.captureException(e)
      throw makeError(e)
    })
}

/**
 * @typedef CommonMethodParams
 * @property {string} url
 * @property {object} data
 * @property {boolean} [useAuth]
 * @property {boolean} [useAcceptAPP_JSON]
 */

/**
 * @typedef {(params: CommonMethodParams) => Promise<any>} CommonMethod
 */

function commonMethod(params) {
  const {
    url = '',
    data = {},
    useAuth = true,
    useAcceptAPP_JSON = false
  } = params
  return method =>
    fetch(BASE_URL + url, {
      method,
      headers: makeHeaders(useAuth, useAcceptAPP_JSON),
      body: useAcceptAPP_JSON ? data : JSON.stringify(data)
    })
      .then(async response => {
        return await processResponse(response)
      })
      .catch(e => {
        Sentry.captureException(e)
        throw makeError(e)
      })
}

/** @type {CommonMethod} */
export const POST = params => commonMethod(params)('POST')
/** @type {CommonMethod} */
export const PUT = params => commonMethod(params)('PUT')
/** @type {CommonMethod} */
export const DELETE = params => commonMethod(params)('DELETE')
