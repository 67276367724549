//@ts-check
import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from 'context/AuthProvider'

function PrivateRoute({ context = null }) {
  const { isAuthenticated } = useAuth()
  const location = useLocation()
  if (!isAuthenticated)
    return <Navigate to='/login' state={{ from: location }} replace />

  return <Outlet context={context} />
}

export default PrivateRoute
