//@ts-check
import { isEmpty } from 'ramda'

/**
 *
 * @param {object} query
 * @returns {string}
 */
export const makeQueryString = (query = {}) =>
  Object.keys(query)
    .reduce((acc, key) => {
      if (query[key].$in) acc.push(`${key}=${JSON.stringify(query[key])}`)
      else acc.push(`${key}=${query[key]}`)
      return acc
    }, [])
    .join('&')

/**
 *
 * @param {object} query
 * @param {string[]} project
 * @param {string} url
 * @returns {string}
 */
export const makeCustomQueryAndProject = (
  query = {},
  project = [],
  url = ''
) => {
  let finalUrl = url
  const hasQuery = query && !isEmpty(query)
  if (hasQuery) finalUrl += '?' + makeQueryString(query)
  if (project && !isEmpty(project))
    finalUrl += hasQuery
      ? `&project=${project.join(',')}`
      : `?project=${project.join(',')}`

  return finalUrl
}
