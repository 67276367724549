//@ts-check
import React, { useCallback, useState } from 'react'
import { Link, Navigate, useOutletContext } from 'react-router-dom'
import { isEmpty } from 'ramda'
import { updateProfile } from 'api/account'
import Button from 'components/buttons/Button'
import TextInput from 'components/inputs/TextInput'
import { H2, H3 } from 'components/typography'
import PhoneInput from 'components/inputs/PhoneInput'
import TutorSelect from 'components/selects/TutorSelect'
import useFormManager from 'hooks/useFormManager'
import { useAuth } from 'context/AuthProvider'
import { useNotificationActions } from 'context/NotificationProvider'
import styles from './ContactData.module.css'

const REQUIRED_FIELDS = [
  'name',
  'email',
  'dni',
  'phoneNumber.cleanedPhone',
  'role'
]

function ContactData() {
  const [isSaving, setIsSaving] = useState(false)
  const [isSavingSecondTutor, setIsSavingSecondTutor] = useState(false)
  const { student, setStudent } = useAuth()
  const {
    handleFormChange,
    handlePhoneNumberChange,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields,
    form
  } = useFormManager(REQUIRED_FIELDS, student.firstTutor)
  const {
    handleFormChange: handleSecondTutorChange,
    handlePhoneNumberChange: handlePhoneNumberSecondTutorChange,
    getErrorPropsByRequiredField: getErrorPropsByRequiredFieldSecondTutor,
    hasPendingRequiredFields: hasPendingRequiredFieldsSecondTutor,
    form: secondTutorForm
  } = useFormManager(REQUIRED_FIELDS, student.secondTutor)
  const { setSuccessMessage, setErrorMessage } = useNotificationActions()
  // @ts-ignore
  const { redirectToPersonalData } = useOutletContext()

  const handleSaveFirstTutor = useCallback(() => {
    setIsSaving(true)
    updateProfile({ ...student, firstTutor: form })
      .then(updatedStudent => {
        setSuccessMessage({
          title: 'Datos de contacto actualizado',
          message: 'Contacto 1 guardado correctamente '
        })
        setStudent(updatedStudent)
      })
      .catch(e => {
        setErrorMessage({ message: e.message })
        console.error('Error updating contact data: ', e)
      })
      .finally(() => setIsSaving(false))
  }, [form, setErrorMessage, setStudent, setSuccessMessage, student])

  const handleSaveSecondTutor = useCallback(() => {
    setIsSavingSecondTutor(true)
    updateProfile({ ...student, secondTutor: secondTutorForm })
      .then(updatedStudent => {
        setSuccessMessage({
          title: 'Datos de contacto actualizado',
          message: 'Contacto 2 guardado correctamente '
        })
        setStudent(updatedStudent)
      })
      .catch(e => {
        setErrorMessage({ message: e.message })
        console.error('Error updating contact data: ', e)
      })
      .finally(() => setIsSavingSecondTutor(false))
  }, [secondTutorForm, setErrorMessage, setStudent, setSuccessMessage, student])

  if (redirectToPersonalData || !student.dateOfBirth || student.isAnAdult)
    return <Navigate to='/account/personal-data' />

  return (
    <section className={styles.section}>
      <H2 className={styles.titleContactData}>Datos de contacto 📲</H2>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <H3 className={styles.titleCard}>Contacto 1</H3>
          <div className={styles.cardContent}>
            <div className={styles.inputContainer}>
              <TutorSelect
                name='role'
                placeholder='Relación con el alumno'
                value={form?.role}
                showLabel={false}
                onChange={handleFormChange}
                {...getErrorPropsByRequiredField('role')}
              />
            </div>
            <div className={styles.inputContainer}>
              <TextInput
                name='name'
                placeholder='Nombre completo'
                value={form?.name}
                onChange={handleFormChange}
                {...getErrorPropsByRequiredField('name')}
              />
            </div>
            <div className={styles.inputContainer}>
              <TextInput
                name='email'
                placeholder='Correo electrónico'
                value={form?.email}
                onChange={handleFormChange}
                {...getErrorPropsByRequiredField('email')}
              />
            </div>
            <div className={styles.inputContainer}>
              <TextInput
                name='dni'
                placeholder='DNI'
                value={form?.dni}
                onChange={handleFormChange}
                {...getErrorPropsByRequiredField('dni')}
              />
            </div>
            <div className={styles.inputContainer}>
              <PhoneInput
                value={form?.phoneNumber?.fullPhoneNumber}
                onPhoneNumberChange={handlePhoneNumberChange}
                {...getErrorPropsByRequiredField('phoneNumber.cleanedPhone')}
              />
            </div>

            <div className={styles.actions}>
              <Link to='/account'>
                <Button label='Cancelar' size='small' type='secondary' />
              </Link>
              <Button
                label='Guardar'
                size='small'
                loading={isSaving}
                disabled={
                  hasPendingRequiredFields || isSaving || isSavingSecondTutor
                }
                onClick={handleSaveFirstTutor}
              />
            </div>
          </div>
        </div>
        <div className={styles.card}>
          <H3 className={styles.titleCard}>Contacto 2</H3>
          <div className={styles.cardContent}>
            <div className={styles.inputContainer}>
              <TutorSelect
                name='role'
                placeholder='Relación con el alumno'
                value={secondTutorForm?.role}
                showLabel={false}
                onChange={handleSecondTutorChange}
                {...getErrorPropsByRequiredFieldSecondTutor('role')}
              />
            </div>
            <div className={styles.inputContainer}>
              <TextInput
                name='name'
                placeholder='Nombre completo'
                value={secondTutorForm?.name}
                onChange={handleSecondTutorChange}
                {...getErrorPropsByRequiredFieldSecondTutor('name')}
              />
            </div>
            <div className={styles.inputContainer}>
              <TextInput
                name='email'
                placeholder='Correo electrónico'
                value={secondTutorForm?.email}
                onChange={handleSecondTutorChange}
                {...getErrorPropsByRequiredFieldSecondTutor('email')}
              />
            </div>
            <div className={styles.inputContainer}>
              <TextInput
                name='dni'
                placeholder='DNI'
                value={secondTutorForm?.dni}
                onChange={handleSecondTutorChange}
                {...getErrorPropsByRequiredFieldSecondTutor('dni')}
              />
            </div>
            <div className={styles.inputContainer}>
              <PhoneInput
                value={secondTutorForm?.phoneNumber?.fullPhoneNumber}
                onPhoneNumberChange={handlePhoneNumberSecondTutorChange}
                {...getErrorPropsByRequiredFieldSecondTutor(
                  '.phoneNumber.cleanedPhone'
                )}
              />
            </div>

            <div className={styles.actions}>
              <Link to='/account'>
                <Button size='small' label='Cancelar' type='secondary' />
              </Link>
              <Button
                label='Guardar'
                size='small'
                loading={isSavingSecondTutor}
                disabled={
                  hasPendingRequiredFieldsSecondTutor ||
                  isSavingSecondTutor ||
                  !student.firstTutor ||
                  isEmpty(student.firstTutor)
                }
                onClick={handleSaveSecondTutor}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactData
