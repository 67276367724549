import * as React from 'react'

const Gear = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill={props.color || '#8080F8'}
      fillRule='evenodd'
      d='M4.621 9.98c-.193.4-.363.81-.51 1.23a2 2 0 0 0 .926 2.41 1.572 1.572 0 0 1 0 2.76 2 2 0 0 0-.926 2.411c.147.42.317.83.51 1.23a2 2 0 0 0 2.358 1.048 1.572 1.572 0 0 1 1.952 1.952A2 2 0 0 0 9.98 25.38c.4.194.81.364 1.23.51a2 2 0 0 0 2.41-.925 1.572 1.572 0 0 1 2.76 0 2 2 0 0 0 2.411.925c.42-.146.83-.316 1.23-.51a2 2 0 0 0 1.048-2.358 1.572 1.572 0 0 1 1.952-1.952 2 2 0 0 0 2.358-1.049c.194-.399.364-.81.51-1.229a2 2 0 0 0-.925-2.41 1.572 1.572 0 0 1 0-2.761 2 2 0 0 0 .925-2.41c-.146-.42-.316-.83-.51-1.23a2 2 0 0 0-2.358-1.049A1.572 1.572 0 0 1 21.07 6.98a2 2 0 0 0-1.049-2.358c-.399-.193-.81-.363-1.229-.51a2 2 0 0 0-2.41.926 1.572 1.572 0 0 1-2.761 0 2 2 0 0 0-2.41-.926c-.42.147-.83.317-1.23.51A2 2 0 0 0 8.931 6.98 1.572 1.572 0 0 1 6.98 8.931 2 2 0 0 0 4.621 9.98ZM7.855 15c0-1.35-.75-2.525-1.855-3.133.121-.348.262-.687.421-1.015a3.572 3.572 0 0 0 4.431-4.43c.328-.16.667-.3 1.015-.422a3.572 3.572 0 0 0 6.267 0c.348.121.686.262 1.015.421a3.572 3.572 0 0 0 4.43 4.431c.16.328.3.667.421 1.015a3.572 3.572 0 0 0 0 6.267 9.467 9.467 0 0 1-.42 1.015 3.572 3.572 0 0 0-4.431 4.43c-.329.16-.668.3-1.015.421a3.572 3.572 0 0 0-6.267 0 9.466 9.466 0 0 1-1.015-.42 3.572 3.572 0 0 0-4.43-4.431A9.469 9.469 0 0 1 6 18.134 3.572 3.572 0 0 0 7.855 15ZM17 15a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z'
      clipRule='evenodd'
    />
    <mask
      id='Gear'
      width={23}
      height={23}
      x={4}
      y={4}
      maskUnits='userSpaceOnUse'
      style={{
        maskType: 'luminance'
      }}
    >
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M4.621 9.98c-.193.4-.363.81-.51 1.23a2 2 0 0 0 .926 2.41 1.572 1.572 0 0 1 0 2.76 2 2 0 0 0-.926 2.411c.147.42.317.83.51 1.23a2 2 0 0 0 2.358 1.048 1.572 1.572 0 0 1 1.952 1.952A2 2 0 0 0 9.98 25.38c.4.194.81.364 1.23.51a2 2 0 0 0 2.41-.925 1.572 1.572 0 0 1 2.76 0 2 2 0 0 0 2.411.925c.42-.146.83-.316 1.23-.51a2 2 0 0 0 1.048-2.358 1.572 1.572 0 0 1 1.952-1.952 2 2 0 0 0 2.358-1.049c.194-.399.364-.81.51-1.229a2 2 0 0 0-.925-2.41 1.572 1.572 0 0 1 0-2.761 2 2 0 0 0 .925-2.41c-.146-.42-.316-.83-.51-1.23a2 2 0 0 0-2.358-1.049A1.572 1.572 0 0 1 21.07 6.98a2 2 0 0 0-1.049-2.358c-.399-.193-.81-.363-1.229-.51a2 2 0 0 0-2.41.926 1.572 1.572 0 0 1-2.761 0 2 2 0 0 0-2.41-.926c-.42.147-.83.317-1.23.51A2 2 0 0 0 8.931 6.98 1.572 1.572 0 0 1 6.98 8.931 2 2 0 0 0 4.621 9.98ZM7.855 15c0-1.35-.75-2.525-1.855-3.133.121-.348.262-.687.421-1.015a3.572 3.572 0 0 0 4.431-4.43c.328-.16.667-.3 1.015-.422a3.572 3.572 0 0 0 6.267 0c.348.121.686.262 1.015.421a3.572 3.572 0 0 0 4.43 4.431c.16.328.3.667.421 1.015a3.572 3.572 0 0 0 0 6.267 9.467 9.467 0 0 1-.42 1.015 3.572 3.572 0 0 0-4.431 4.43c-.329.16-.668.3-1.015.421a3.572 3.572 0 0 0-6.267 0 9.466 9.466 0 0 1-1.015-.42 3.572 3.572 0 0 0-4.43-4.431A9.469 9.469 0 0 1 6 18.134 3.572 3.572 0 0 0 7.855 15ZM17 15a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z'
        clipRule='evenodd'
      />
    </mask>
    <g mask='url(#Gear)'>
      <path d='M0 0h30v30H0z' />
    </g>
  </svg>
)

export default Gear
