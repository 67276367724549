//@ts-check
import React, { useEffect, useRef, useState } from 'react'
import getLocalStream, { getConstraints } from 'utils/getLocalStream'
import Gear from 'assets/icons/Gear'
import Modal from 'components/modals/Modal'
import { H3 } from 'components/typography'
import Select from 'components/selects/Select'
import Button from 'components/buttons/Button'
import VideoSource from 'components/academy/classroom/VideoSource'
import styles from './MediaSetup.module.css'

function MediaSetup({ fabIconColor = 'var(--dark-grey-40)', onSave }) {
  const ref = useRef(null)
  const [audioInputs, setAudioInputs] = useState([])
  const [audioOutputs, setAudioOutputs] = useState([])
  const [videoInputs, setVideoInputs] = useState([])
  const [showSetup, setShowSetup] = useState(false)
  const [selectedMedia, setSelectedMedia] = useState({
    audioInputId: '',
    audioOutputId: '',
    videoInputId: ''
  })

  const attachSinkId = sinkId => {
    if (typeof ref.current.sinkId !== 'undefined') {
      ref.current.setSinkId(sinkId).catch(error => {
        let errorMessage = error
        if (error.name === 'SecurityError') {
          errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`
        }
        console.error(errorMessage)
      })
    } else {
      console.warn('Browser does not support output device selection.')
    }
  }

  const handleChange = e =>
    setSelectedMedia(state => {
      const newState = { ...state, [e.target.name]: e.target.value }
      if (e.target.name === 'audioOutputId') attachSinkId(e.target.value)
      start(newState)
      return newState
    })

  const gotDevices = deviceInfos => {
    setAudioInputs([])
    setAudioOutputs([])
    setVideoInputs([])
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i]
      let option = { id: deviceInfo.deviceId, value: deviceInfo.deviceId }
      if (deviceInfo.kind === 'audioinput') {
        option.label =
          deviceInfo.label || `microphone ${audioInputs.length + 1}`
        setAudioInputs(state => [...state, option])
      } else if (deviceInfo.kind === 'audiooutput') {
        option.label = deviceInfo.label || `speaker ${audioOutputs.length + 1}`
        setAudioOutputs(state => [...state, option])
      } else if (deviceInfo.kind === 'videoinput') {
        option.label = deviceInfo.label || `camera ${videoInputs.length + 1}`
        setVideoInputs(state => [...state, option])
      } else {
        console.log('Some other kind of source/device: ', deviceInfo)
      }
    }
  }
  const gotStream = stream => {
    ref.current.srcObject = stream
    return navigator.mediaDevices.enumerateDevices()
  }
  function handleError(error) {
    console.error(
      'navigator.MediaDevices.getUserMedia error: ',
      error.message,
      error.name
    )
  }
  const start = ({ audioInputId, videoInputId }) => {
    if (ref.current.srcObject) {
      ref.current.srcObject.getTracks().forEach(track => {
        track.stop()
      })
    }

    getLocalStream(getConstraints(audioInputId, videoInputId))
      .then(gotStream)
      .then(gotDevices)
      .catch(handleError)
  }

  const handleSaveSetup = () => {
    onSave(selectedMedia)
    setShowSetup(false)
  }

  useEffect(() => {
    const videoRef = ref.current
    if (!showSetup) return
    getLocalStream(getConstraints())
      .then(gotStream)
      .then(gotDevices)
      .catch(handleError)
    return () => {
      if (videoRef.srcObject) {
        videoRef.srcObject.getTracks().forEach(track => {
          track.stop()
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSetup])

  return (
    <>
      <Gear
        className={styles.gearIcon}
        color={fabIconColor}
        onClick={() => setShowSetup(true)}
      />
      {showSetup && (
        <Modal
          onCancel={() => setShowSetup(false)}
          showActions={false}
          showCloseIcon
        >
          <div className={styles.modal}>
            <H3>Selecciona tu configuración preferida</H3>
            <VideoSource ref={ref} />
            <div className={styles.setupSection}>
              <Select
                name='audioInputId'
                label='Micrófono'
                options={audioInputs}
                value={selectedMedia.audioInputId}
                onChange={handleChange}
              />
              <Select
                name='audioOutputId'
                label='Altavoz (opcional)'
                options={audioOutputs}
                value={selectedMedia.audioOutputId}
                onChange={handleChange}
                disabled={!('sinkId' in HTMLMediaElement.prototype)}
              />
              <Select
                name='videoInputId'
                label='Cámara'
                options={videoInputs}
                value={selectedMedia.videoInputId}
                onChange={handleChange}
                menuPlacement={videoInputs.length > 2 ? 'top' : 'auto'}
              />
            </div>
            <Button
              size='tiny'
              type='secondary'
              disabled={
                !selectedMedia.audioInputId || !selectedMedia.videoInputId
              }
              onClick={handleSaveSetup}
            />
          </div>
        </Modal>
      )}
    </>
  )
}

export default MediaSetup
