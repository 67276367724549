//@ts-check
import React from 'react'
import DotsSpinner from 'components/spinners/DotsSpinner'
import buttonStyles from './Button.module.css'
/**
 * @typedef ButtonProps
 * @property {string} [label]
 * @property {'tiny' | 'small' | 'medium' | 'large'} size
 * @property {'primary' | 'secondary' |'tertiary' | 'warning' | 'accent-primary' | 'accent-secondary' | 'accent-tertiary' | 'bright' | 'ghost'} type
 * @property {boolean} loading
 * @property {boolean} disabled
 * @property {string} className
 * @property {React.MouseEventHandler<HTMLButtonElement>} onClick
 * @property {React.CSSProperties} style
 * @property {React.ReactNode} children
 *
 */

/** @param {Partial<ButtonProps>} props */
function Button({
  label = 'Aceptar',
  size = 'medium',
  type = 'primary',
  loading = false,
  disabled = false,
  className = '',
  onClick,
  style,
  children
}) {
  return (
    <button
      className={[
        className,
        buttonStyles.button,
        buttonStyles[size],
        buttonStyles[type],
        disabled
          ? buttonStyles[`${size}-${type}-disabled`]
          : buttonStyles[`${size}-${type}`]
      ].join(' ')}
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      <span className={buttonStyles.span}>
        {loading ? <DotsSpinner /> : !!children ? children : label}
      </span>
    </button>
  )
}

export default Button
