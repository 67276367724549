import { GET, POST } from './'

export const findSubscriptions = () => GET({ url: 'students/subscriptions' })

export const getSubscriptionById = id =>
  GET({ url: 'students/subscriptions/' + id })

export const createSubscription = (data = {}) =>
  POST({
    url: 'students/subscriptions',
    data
  })
