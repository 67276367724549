import { GET, POST } from 'api'
import { makeCustomQueryAndProject } from 'utils/queries'

export const getMonthlyOptionsBy = ({ subjectId, month, year }) =>
  GET({
    url: makeCustomQueryAndProject(
      { month, year, subjectId },
      [],

      'students/calendar/monthly-options-by'
    )
  })
export const makeMonthlyHoursByTeacher = (data = {}) =>
  POST({
    url: 'students/calendar/make-monthly-hours-by-teacher',
    data
  })
export const saveMonthlyOptions = (data = {}) =>
  POST({
    url: 'students/calendar/save-monthly-options',
    data
  })

export const getWeeklyOptionsBy = ({ subjectId, startDate, endDate }) =>
  GET({
    url: makeCustomQueryAndProject(
      { startDate, endDate, subjectId },
      [],
      'students/calendar/weekly-options-by'
    )
  })
export const makeWeeklyHoursByTeacher = (data = {}) =>
  POST({
    url: 'students/calendar/make-weekly-hours-by-teacher',
    data
  })
export const saveWeeklyOptions = (data = {}) =>
  POST({
    url: 'students/calendar/save-weekly-options',
    data
  })
