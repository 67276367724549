import { GET, POST } from './index'

export const login = (data = {}) =>
  POST({ url: 'auth/students/login', data, useAuth: false })

/**
 *
 * @param {{full: boolean}} data
 */
export const fetchMe = (data = {}) => {
  return GET({ url: 'students/account/me' + (data.full ? '/full' : '') })
}

export const register = (data = {}) =>
  POST({ url: 'auth/students/register', data, useAuth: false })

export const recoverPassword = (data = {}) =>
  POST({ url: 'auth/students/forgot-password', data, useAuth: false })

export const executeAutoLogin = (data = {}) =>
  POST({ url: 'auth/students/auto-login', data, useAuth: false })
