import * as React from 'react'
const ZoomIn = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill={props.color || '#fff'}
      d='M11.077 9H4.923C4.418 9 4 8.547 4 8c0-.547.418-1 .923-1h6.154c.505 0 .923.453.923 1 0 .547-.418 1-.923 1Z'
    />
    <path
      fill={props.color || '#fff'}
      d='M8 12c-.547 0-1-.418-1-.923V4.923C7 4.418 7.453 4 8 4c.547 0 1 .418 1 .923v6.154c0 .505-.453.923-1 .923Z'
    />
    <path
      fill={props.color || '#fff'}
      d='M8.105 16.21C3.638 16.21 0 12.574 0 8.106 0 3.638 3.638 0 8.105 0c4.468 0 8.106 3.638 8.106 8.105 0 4.468-3.638 8.106-8.106 8.106Zm0-14.21C4.286 2 2 4.294 2 8.105c0 3.812 2.286 6.105 6.105 6.105 3.82 0 6.105-2.293 6.105-6.105C14.21 4.294 11.925 2 8.105 2Z'
    />
    <path
      fill={props.color || '#fff'}
      d='M17.145 18a.85.85 0 0 1-.607-.252l-2.29-2.287a.862.862 0 0 1 0-1.212.864.864 0 0 1 1.214 0l2.29 2.287a.862.862 0 0 1 0 1.212.85.85 0 0 1-.607.252Z'
    />
  </svg>
)
export default ZoomIn
