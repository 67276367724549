//@ts-check
import { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

/**
 * Hook to solve rare/unnecessary re-render
 * @returns {import('react-router-dom').NavigateFunction}
 */
export default function useCustomNavigate() {
  const navigate = useNavigate()
  const navigateRef = useRef({ navigate })
  useEffect(() => {
    navigateRef.current.navigate = navigate
  }, [navigate])
  return useCallback((location, options = {}) => {
    navigateRef.current.navigate(location, options)
  }, [])
}
