//@ts-check
import React from 'react'
import BrainOn from 'assets/icons/BrainOn'
import Doubt from 'assets/icons/Doubt'
import Learning from 'assets/icons/Learning'
import Understood from 'assets/icons/Understood'
import { lightbulbStatusId } from './Lightbulb'
import styles from './EmoticonsBar.module.css'

export const EmoticonIds = {
  learning: 'learning',
  doubt: 'doubt',
  brainOn: 'brainOn',
  understood: 'understood'
}

const OPTIONS = [
  { id: EmoticonIds.learning, label: 'Aprendiendo', Icon: Learning },
  { id: EmoticonIds.doubt, label: 'Tengo una duda', Icon: Doubt },
  { id: EmoticonIds.brainOn, label: 'Brain On', Icon: BrainOn },
  { id: EmoticonIds.understood, label: 'Entendido', Icon: Understood }
]

const noop = (id = '') => {}
function EmoticonsBar({
  lightbulbStatus,
  isActive,
  disabled = false,
  onClick = noop
}) {
  const getCalculatedStatus = () => {
    if (isActive || lightbulbStatus.status === lightbulbStatusId.ON)
      return lightbulbStatusId.ON
    return lightbulbStatusId.OFF
  }

  return (
    <div className={styles.emoticonsBar}>
      {OPTIONS.map(({ id, label, Icon }) => {
        const isSelected =
          lightbulbStatus.id === id &&
          getCalculatedStatus() === lightbulbStatusId.ON

        return (
          <div key={id} className={styles.iconContainer} title={label}>
            <Icon
              color={isSelected ? 'var(--error-color)' : ''}
              className={[
                styles.icon,
                isSelected ? styles.on : '',
                disabled ? styles.disabled : ''
              ].join(' ')}
              onClick={() => {
                if (!disabled) {
                  onClick(id)
                }
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

export default EmoticonsBar
