//@ts-check
import { useEffect, useState } from 'react'
import { svgByBgdType } from 'utils/canvas'

export default function useBackground({ socket, canvas }) {
  const classroomBgdToolbarIds = {
    emptyNotebook: 'emptyNotebook',
    gridNotebook: 'gridNotebook',
    dottedNotebook: 'dottedNotebook',
    linedNotebook: 'linedNotebook',
    linedNotebookVerticalLine: 'linedNotebookVerticalLine'
  }
  const [backgroundType, setBackgroundType] = useState(
    classroomBgdToolbarIds.emptyNotebook
  )
  const [numberOfLines, setNumberOfLines] = useState(1)
  const [numberOfColumns, setNumberOfColumns] = useState(1)

  const getStyle = () => {
    const svg = svgByBgdType[backgroundType](
      canvas,
      numberOfLines,
      numberOfColumns
    )
    const svgUrl = `data:image/svg+xml;base64,${btoa(svg)}`
    return {
      backgroundImage: `url(${svgUrl})`
    }
  }

  useEffect(() => {
    if (!socket || !canvas || !canvas.current) return
    socket.on(
      'classroom:teachers:whiteboard-type',
      ({ type, numberOfLines, numberOfColumns }) => {
        setBackgroundType(type)
        setNumberOfLines(numberOfLines)
        setNumberOfColumns(numberOfColumns)
      }
    )
    return () => {
      socket.off('classroom:teachers:whiteboard-type')
    }
  }, [backgroundType, canvas, socket])

  return {
    backgroundType,
    bgdStyle: getStyle(),
    numberOfLines,
    numberOfColumns
  }
}
