//@ts-check
import React, { useState } from 'react'
import { isEmpty } from 'ramda'
import {
  DAY_NAME_BY_NUMBER,
  DEFAULT_UNIT_HOURS,
  addDaysTo,
  dateToString,
  extractRangeHoursAndMinutes,
  formatDate,
  getFullWeekByStartOfWeek,
  getStartOfWeek,
  getWeekNumber,
  isPast,
  isSameDay
} from 'utils/date'
import { noop } from 'utils'
import ChevronLeft from 'assets/icons/ChevronLeft'
import ChevronRight from 'assets/icons/ChevronRight'
import Tag from 'components/tags/Tag'
import styles from './WeeklyCalendar.module.css'

/**
 * @typedef WeeklyCalendarProps
 * @property {Date} startDate
 * @property {{[date:string]: string[]}} values
 * @property {{[date:string]: string[]}} options
 * @property {boolean} disabled
 * @property {boolean} disabledPast
 * @property {Function} onChange
 */

/** @param {Partial<WeeklyCalendarProps>} props*/
function WeeklyCalendar({
  startDate = new Date(),
  values = {},
  options = {},
  disabled = false,
  disabledPast = false,
  onChange = noop
}) {
  const [selectedDate, setSelectedDate] = useState(getStartOfWeek(startDate))

  const handleWeekChange = (amount = 0) => {
    if (disabled) return
    setSelectedDate(addDaysTo(getStartOfWeek(selectedDate), amount))
  }

  const handleGoToCurrentWeek = () => {
    if (disabled) return
    setSelectedDate(getStartOfWeek(new Date()))
  }

  return (
    <div
      className={[styles.container, disabled ? styles.disabled : ''].join(' ')}
    >
      <div className={styles.actionsContainer}>
        <Actions
          date={selectedDate}
          disabled={disabled}
          disabledPast={disabledPast ? isPast(selectedDate, new Date()) : false}
          onChange={handleWeekChange}
        />
        <div onClick={handleGoToCurrentWeek}>
          <Tag
            label={'Hoy, ' + dateToString(new Date())}
            className={disabled ? styles.chevronDisabled : ''}
          />
        </div>
      </div>
      <div className={styles.calendar}>
        {getFullWeekByStartOfWeek(selectedDate).map((targetDate, i) => {
          const disabled = isPast(targetDate, new Date())
          const hours = disabled
            ? []
            : options[new Date(targetDate).getTime()] || []
          return (
            <HourSelector
              key={i}
              date={targetDate}
              hours={hours}
              disabledPast={disabledPast}
              selectedWeekOptions={values}
              onChange={onChange}
            />
          )
        })}
      </div>
    </div>
  )
}

function HourSelector({
  date,
  hours = [],
  disabledPast,
  selectedWeekOptions,
  onChange
}) {
  const target = new Date(date)
  const isToday = isSameDay(target, new Date())
  const targetDay = target.getDay() - 1
  const dayNumber = targetDay < 0 ? DAY_NAME_BY_NUMBER.length - 1 : targetDay
  const label = DAY_NAME_BY_NUMBER[dayNumber].label
  const selectedHours = selectedWeekOptions[target.getTime()] || []
  const disabledAll = isEmpty(hours)

  return (
    <div className={styles.hourSection}>
      <p
        className={[
          styles.hourSectionTitle,
          isToday ? styles.hourSectionTitleToday : ''
        ].join(' ')}
      >
        {`${label.substring(0, 3)} ${new Date(date).getDate()}`}
      </p>
      <div className={styles.hourContainer}>
        {DEFAULT_UNIT_HOURS.am
          .concat(DEFAULT_UNIT_HOURS.pm)
          .map((hour, index) => {
            const disabled =
              !hours.includes(hour) || isDisabledHour(hour, disabledPast, date)
            return (
              <p
                key={index}
                className={[
                  styles.hour,
                  selectedHours.includes(hour) ? styles.selectedHour : '',
                  disabledAll ? styles.disabledHour : '',
                  disabled ? styles.disabledHour : ''
                ].join(' ')}
                onClick={() =>
                  !disabledAll && !disabled && onChange(hour, target.getTime())
                }
              >
                {hour}
              </p>
            )
          })}
      </div>
    </div>
  )
}

function Actions({ date, disabled, disabledPast, onChange }) {
  const label =
    'Semana ' +
    getWeekNumber(date) +
    ' | ' +
    formatDate({
      date: new Date(date),
      stringFormat: 'MMMM, yyyy'
    })
  return (
    <div className={styles.actions}>
      <ChevronLeft
        className={[
          styles.chevron,
          disabled || disabledPast ? styles.chevronDisabled : ''
        ].join(' ')}
        onClick={() => !disabledPast && onChange(-7)}
      />
      <p className={styles.title}>{label}</p>
      <ChevronRight
        className={[
          styles.chevron,
          disabled ? styles.chevronDisabled : ''
        ].join(' ')}
        onClick={() => onChange(7)}
      />
    </div>
  )
}

export default WeeklyCalendar

function isDisabledHour(stringHour, disabledPast, date) {
  if (!disabledPast) return false
  const { minHour, minMinutes } = extractRangeHoursAndMinutes(stringHour)
  return new Date(date).setHours(minHour, minMinutes, 0, 0) < Date.now()
}
