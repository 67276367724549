//@ts-check
import React from 'react'
import BrainFart from 'assets/svg/BrainFart'
import Button from 'components/buttons/Button'
import { H2, Paragraph } from 'components/typography'
import styles from './ClassroomReconnecting.module.css'

/**
 * @typedef CustomClassroomReconnectingStyles
 * @property {string} container
 */
/**
 *
 * @param {object} params
 * @param {CustomClassroomReconnectingStyles} [params.classNames]
 */
function ClassroomReconnecting({ classNames }) {
  return (
    <div className={[styles.container, classNames?.container ?? ''].join(' ')}>
      <div className={styles.images}>
        <BrainFart className={styles.backgroundSvg} color='var(--sandground)' />
        <BrainFart className={styles.mainSvg} />
      </div>
      <H2>
        <span className={styles.sandground}>
          UUPS!! HA HABIDO UN ERROR 💩 EN LA CONEXIÓN.
        </span>
        <br />
        NO TE MUEVAS EN BREVE VOLVEREMOS A CONECTAR.
      </H2>
      <Paragraph type='body1Bold'>
        Si en unos segundos no vuelve a conectar prueba con un clásico,
        desconecta y vuelve a conectar, nunca falla 😝
      </Paragraph>
      <Button
        label='Volver a iniciar'
        onClick={() => window.location.reload()}
      />
    </div>
  )
}

export default ClassroomReconnecting
