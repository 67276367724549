// @ts-check
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useOutletContext } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  detachPaymentMethod,
  listPaymentMethods,
  setPaymentMethodFavourite
} from 'api/billing'
import Back from 'assets/icons/Back'
import config from 'config'
import Modal from 'components/modals/Modal'
import { H2, H3, Paragraph } from 'components/typography'
import { useNotificationActions } from 'context/NotificationProvider'
import CardSetupModal from './CardSetupModal'
import CreditCard from './CreditCard'
import styles from './PaymentMethods.module.css'

const stripePromise = loadStripe(config.stripe.token)

function PaymentMethods() {
  const [sync, setSync] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [preferredCard, setPreferredCard] = useState('')
  const [paymentMethods, setPaymentMethods] = useState([])
  const [isConfirmDeletionModalOpen, setConfirmDeletionModalOpen] =
    useState(false)
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null)
  const { setSuccessMessage, setErrorMessage } = useNotificationActions()
  // @ts-ignore
  const { redirectToPersonalData } = useOutletContext()

  const openModal = () => setShowModal(true)

  const closeModal = () => setShowModal(false)

  const processError = errorMessage =>
    setErrorMessage({
      message: errorMessage
    })

  const handleSavedCard = async savedCard => {
    setSync(true)
    if (!preferredCard) {
      try {
        const createdCardId = savedCard.setupIntent.payment_method
        await setPaymentMethodFavourite(createdCardId)
      } catch (e) {
        console.error('Error setting favourite: ', e)
        processError(
          'Ha habido un problema definiendo esa tarjeta como favorita'
        )
      }
    }
    closeModal()
    setSuccessMessage({ message: 'Tarjeta guardada correctamente' })
    setSync(false)
  }

  const handleFavouriteClick = paymentMethodId => {
    if (paymentMethodId !== preferredCard) {
      setPaymentMethodFavourite(paymentMethodId)
        .then(() => {
          setPreferredCard(paymentMethodId)
          setSync(false)
        })
        .catch(err => {
          console.error('Error favourite click:', err)
          processError(
            'Ha habido un problema definiendo esa tarjeta como favorita'
          )
        })
    }
  }

  const handleDeleteClick = paymentMethodId => {
    setSelectedPaymentMethodId(paymentMethodId)
    setConfirmDeletionModalOpen(true)
  }

  const handleConfirmDelete = () => {
    setConfirmDeletionModalOpen(false)
    detachPaymentMethod(selectedPaymentMethodId)
      .then(() => {
        setSync(false)
      })
      .catch(e => {
        console.error('Error deleting:', e)
        processError('Ha surgido un problema borrando la tarjeta')
      })
  }

  // @ts-ignore
  useEffect(() => {
    if (sync) return
    let isCurrent = true
    listPaymentMethods()
      .then(data => {
        if (!isCurrent) return
        setPaymentMethods(
          data.cards.sort((a, b) => {
            if (a.id === data.preferredCard) {
              return -1
            } else if (b.id === data.preferredCard) {
              return 1
            }
            return 0
          })
        )
        setPreferredCard(data.preferredCard)
        setSync(true)
      })
      .catch(() =>
        processError('Ha habido un problema listando los métodos de pago')
      )
    return () => (isCurrent = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sync])

  if (redirectToPersonalData) return <Navigate to='/account/personal-data' />

  return (
    <section className={styles.paymentMethodsSection}>
      <H2 className={styles.title}>
        <Link to='/account'>
          <Back color='var(--sandground)' className={styles.backIcon} />
        </Link>
        Mis tarjetas
      </H2>
      <div className={styles.largeRectangle} onClick={openModal}>
        <div className={styles.smallRectangle}>
          <div className={styles.circle}>+</div>
        </div>
        <Paragraph className={styles.addPaymentText}>
          Añadir un método de pago
        </Paragraph>
      </div>

      <Elements stripe={stripePromise}>
        {showModal && (
          <CardSetupModal
            onClose={closeModal}
            onSave={handleSavedCard}
            onError={processError}
          />
        )}
      </Elements>
      {paymentMethods.length === 0 ? (
        <Paragraph className={styles.noPaymentMethodMessage}>
          No se ha añadido ningún método de pago
        </Paragraph>
      ) : (
        <div className={styles.creditCardContainer}>
          {paymentMethods.map(paymentMethod => (
            <CreditCard
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              favourite={paymentMethod.id === preferredCard}
              onFavourite={handleFavouriteClick}
              onDelete={() => handleDeleteClick(paymentMethod.id)}
            />
          ))}
        </div>
      )}
      {isConfirmDeletionModalOpen && (
        <Modal
          onCancel={() => setConfirmDeletionModalOpen(false)}
          onOk={handleConfirmDelete}
        >
          <div className={styles.confirmDeleteModalContainer}>
            <H3 className={styles.deleteModalTitle}>Eliminar método de pago</H3>
            <div className={styles.deleteModalDescription}>
              <Paragraph>
                Estás a punto de eliminar la tarjeta{' '}
                <strong>
                  **** **** ****
                  {
                    paymentMethods.find(
                      method => method.id === selectedPaymentMethodId
                    )?.card?.last4
                  }
                </strong>
                <br /> Si la eliminas no podrás utilizarla para realizar pagos.{' '}
                <br /> ¿Seguro que la quieres eliminar?
              </Paragraph>
            </div>
          </div>
        </Modal>
      )}
    </section>
  )
}

export default PaymentMethods
