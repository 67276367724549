//@ts-check
import React, { useCallback, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import useCustomNavigate from 'hooks/useCustomNavigate'
import useLocalStorage from 'hooks/useLocalStorage'
import { useNotificationActions } from 'context/NotificationProvider'
import config from 'config'
import Phone from 'assets/svg/Phone'
import BrightClassfy from 'assets/logos/main/BrightClassfy'
import { H2, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import HelpModal from 'components/modals/HelpModal'
import styles from './MediaPermissions.module.css'

function MediaPermissions() {
  /**@type {import('hooks/useWebRTC').WebRTCState} */
  const { initUserMedia } = useOutletContext()
  const { saveItem } = useLocalStorage(config.hasMediaPermissionsKey, false)
  const navigate = useCustomNavigate()
  const { setErrorMessage } = useNotificationActions()
  const [showHelpModal, setShowHelpModal] = useState(false)

  const handleClick = useCallback(async () => {
    try {
      const localStream = await initUserMedia()
      saveItem(true)
      localStream.getTracks().forEach(track => {
        track.stop()
      })
      navigate('/academy')
    } catch (error) {
      console.error('Error granting access: ', error)
      setErrorMessage({ message: 'Ha ocurrido un error otorgando permisos' })
      setShowHelpModal(true)
    }
  }, [initUserMedia, navigate, saveItem, setErrorMessage])

  return (
    <div>
      <Link to='/'>
        <BrightClassfy className={styles.classfyLogo} />
      </Link>
      <section className={styles.section}>
        <div className={styles.images}>
          <Phone className={styles.backgroundSvg} color='var(--sandground)' />
          <Phone className={styles.mainSvg} />
        </div>
        <H2 className={styles.h2}>
          Antes de nada habrá que{' '}
          <span className={styles.seadapted}>verse</span> y <br />
          <span className={styles.sandground}>escucharse</span> 😝
        </H2>
        <Paragraph className={styles.description}>
          Configura tu cámara y tu micrófono para que tu clase sea una
          experiencia sin cortes
        </Paragraph>
        <Button label='Dar acceso' type='secondary' onClick={handleClick} />
        <Paragraph className={styles.hint}>
          Si no tienes claro cómo configurarlo{' '}
          <span onClick={() => setShowHelpModal(true)}>picha aquí</span>
        </Paragraph>
        {showHelpModal && <HelpModal onClose={() => setShowHelpModal(false)} />}
      </section>
    </div>
  )
}

export default MediaPermissions
