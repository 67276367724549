import React from 'react'
import styles from './DotsSpinner.module.css'

function DotsSpinner() {
  return (
    <div className={styles.spinner}>
      <span className={styles.dot}>·</span>
      <span className={styles.dot}>·</span>
      <span className={styles.dot}>·</span>
    </div>
  )
}

export default DotsSpinner
