//@ts-check
import { useEffect } from 'react'
import { useBlocker } from 'react-router-dom'

export default function useConfirmNavigation(blockNavigation = true) {
  useEffect(() => {
    const handleBeforeUnload = e => {
      if (blockNavigation) {
        e.preventDefault()
        // Included for legacy support, e.g. Chrome/Edge < 119
        e.returnValue = true
        return true
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [blockNavigation])

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      blockNavigation && currentLocation.pathname !== nextLocation.pathname
  )

  return { blocker }
}
