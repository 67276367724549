//@ts-check
import React, { useCallback, useState } from 'react'
import { Link, Navigate, useOutletContext } from 'react-router-dom'
import Button from 'components/buttons/Button'
import TextInput from 'components/inputs/TextInput'
import { H2 } from 'components/typography'
import DatePicker from 'components/inputs/DatePicker'
import { updateBillingData } from 'api/account'
import { useNotificationActions } from 'context/NotificationProvider'
import { useAuth } from 'context/AuthProvider'
import useFormManager from 'hooks/useFormManager'
import useCustomNavigate from 'hooks/useCustomNavigate'
import styles from './Billing.module.css'

const REQUIRED_FIELDS = ['name', 'email', 'dni', 'dateOfBirth']
function Billing() {
  const [isSaving, setIsSaving] = useState(false)
  const { student, setStudent } = useAuth()
  // @ts-ignore
  const { redirectToPersonalData } = useOutletContext()

  const {
    handleFormChange,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields,
    form
  } = useFormManager(REQUIRED_FIELDS, student.billing)
  const navigate = useCustomNavigate()
  const { setSuccessMessage, setErrorMessage } = useNotificationActions()

  const handleSubmitBillingData = useCallback(() => {
    // @ts-ignore
    if (!form.dateOfBirth || isNaN(Date.parse(form.dateOfBirth)))
      return setErrorMessage({ message: 'Introduzca una fecha correcta' })

    setIsSaving(true)
    updateBillingData(form)
      .then(updatedStudent => {
        setSuccessMessage({
          message: 'Datos de facturazión actualizados correctamente'
        })
        setStudent(updatedStudent)
        navigate('/account')
      })
      .catch(e => {
        console.error('Error updating billing data: ', e)
        setErrorMessage({ message: e.message })
      })
      .finally(() => setIsSaving(false))
  }, [form, navigate, setErrorMessage, setStudent, setSuccessMessage])

  if (redirectToPersonalData) return <Navigate to='/account/personal-data' />

  return (
    <section className={styles.section}>
      <H2>Datos de facturación 📄</H2>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <TextInput
            name='name'
            placeholder='Nombre completo'
            value={form.name}
            onChange={handleFormChange}
            {...getErrorPropsByRequiredField('name')}
          />
          <TextInput
            name='email'
            placeholder='Correo electrónico'
            value={form.email}
            onChange={handleFormChange}
            {...getErrorPropsByRequiredField('email')}
          />
          <div className={styles.personalInfoContainer}>
            <TextInput
              name='dni'
              placeholder='DNI'
              value={form.dni}
              onChange={handleFormChange}
              {...getErrorPropsByRequiredField('dni')}
            />
            <div className={styles.datePicker}>
              <DatePicker
                name='dateOfBirth'
                placeholder='Fecha de nacimiento'
                value={form.dateOfBirth}
                onChange={handleFormChange}
                {...getErrorPropsByRequiredField('dateOfBirth')}
              />
            </div>
          </div>

          <div className={styles.actions}>
            <Link to='/account'>
              <Button type='secondary' size='small' label='Cancelar' />
            </Link>
            <Button
              size='small'
              label='Guardar'
              loading={isSaving}
              disabled={hasPendingRequiredFields || isSaving}
              onClick={handleSubmitBillingData}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Billing
