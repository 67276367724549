//@ts-check
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { sendWhiteboardLine } from 'api/sockets'
import { clear, drawLine, handleDownload } from 'utils/canvas'
import useBackground from './hooks/useBackground'
import DownloadButton from 'components/buttons/Download'
import ClassroomToolbar, { classroomToolbarIds } from './Toolbar'
import { classroomTabIds } from './Tabs'
import styles from './Blackboard.module.css'

const stateByTool = {
  [classroomToolbarIds.draw]: { color: '#9400D3', lineWidth: 2 },
  [classroomToolbarIds.erase]: { color: 'transparent', lineWidth: 30 }
}

function ClassroomBlackboard({
  socket,
  selectedTool,
  onToolChange,
  zoom,
  isActive,
  isVisible,
  teacher,
  canvasContext,
  onSaveCanvasContext,
  onClearCanvasContext,
  onZoomChange
}) {
  const [tool, setTool] = useState(stateByTool[classroomToolbarIds.draw])
  const [isDrawing, setDrawing] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isCanvasReady, setIsCanvasReady] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const canvasRef = useRef(null)

  const { backgroundType, bgdStyle, numberOfLines, numberOfColumns } =
    useBackground({ socket, canvas: canvasRef })

  const handleSaveContext = useCallback(() => {
    canvasRef.current &&
      canvasRef.current.width &&
      canvasRef.current.height &&
      onSaveCanvasContext(
        [
          canvasRef.current
            .getContext('2d', { willReadFrequently: true })
            .getImageData(
              0,
              0,
              canvasRef.current.width,
              canvasRef.current.height
            )
        ],
        classroomTabIds.blackboard
      )
  }, [onSaveCanvasContext])
  const handleRestoreCanvasContext = useCallback(() => {
    canvasContext?.map(imageData =>
      canvasRef.current
        .getContext('2d', { willReadFrequently: true })
        .putImageData(imageData, 0, 0)
    )
  }, [canvasContext])
  const handleClearCanvasContext = useCallback(() => {
    onClearCanvasContext(classroomTabIds.blackboard)
    clear(canvasRef)
  }, [onClearCanvasContext])

  const throttle = (callback, delay) => {
    let previousCall = new Date().getTime()
    return function () {
      const time = new Date().getTime()
      if (time - previousCall >= delay) {
        previousCall = time
        callback.apply(null, arguments)
      }
    }
  }

  const handleMouseDown = e => {
    if (!isActive) return
    const { clientY, clientX } = e
    setDrawing(true)
    const { x, y } = canvasRef.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
  }
  const handleMouseMove = e => {
    if (!isDrawing) return
    const { clientY, clientX } = e
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendWhiteboardLine({
      socket,
      teacher,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth
    })
    setPosition({ x: endX, y: endY })
  }
  const handleMouseLeave = e => {
    if (!isDrawing) return
    const { clientY, clientX } = e
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendWhiteboardLine({
      socket,
      teacher,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth,
      saveContext: true
    })
    setDrawing(false)
  }

  const handleTouchDown = e => {
    if (!isActive) return
    const { clientY, clientX } = e.touches[0]
    setDrawing(true)
    const { x, y } = canvasRef.current.getBoundingClientRect()
    setPosition({ x: clientX - x, y: clientY - y })
  }
  const handleTouchMove = e => {
    if (!isDrawing) return
    const { clientY, clientX } = e.touches[0]
    const { width, height, x, y } = canvasRef.current.getBoundingClientRect()
    const endX = clientX - x
    const endY = clientY - y
    drawLine(
      canvasRef,
      position.x,
      position.y,
      endX,
      endY,
      tool.color,
      tool.lineWidth
    )
    sendWhiteboardLine({
      socket,
      teacher,
      x0: position.x / width,
      y0: position.y / height,
      x1: endX / width,
      y1: endY / height,
      color: tool.color,
      lineWidth: tool.lineWidth
    })
    setPosition({ x: endX, y: endY })
  }

  const handleTouchLeave = () => {
    if (!isDrawing) return
    setDrawing(false)
  }

  useEffect(() => {
    if (!socket || !canvasRef || !canvasRef.current) return
    socket.on(
      'classroom:teachers:whiteboard-line',
      ({ x0, y0, x1, y1, color, lineWidth, saveContext }) => {
        const { width, height } = canvasRef.current
        const startX = x0 * width
        const startY = y0 * height
        const endX = x1 * width
        const endY = y1 * height
        drawLine(canvasRef, startX, startY, endX, endY, color, lineWidth)
        saveContext && handleSaveContext()
      }
    )
    socket.on('classroom:teachers:whiteboard-clear', () =>
      handleClearCanvasContext()
    )

    return () => {
      if (!socket) return
      socket.off('classroom:teachers:whiteboard-line')
      socket.off('classroom:teachers:whiteboard-clear')
    }
  }, [handleClearCanvasContext, handleSaveContext, socket])

  useLayoutEffect(() => {
    const cancelCanvasTouch = e =>
      e.target === canvasRef.current && e.preventDefault()

    document.body.addEventListener('touchstart', cancelCanvasTouch)
    document.body.addEventListener('touchend', cancelCanvasTouch)
    document.body.addEventListener('touchmove', cancelCanvasTouch)

    return () => {
      document.body.removeEventListener('touchstart', cancelCanvasTouch)
      document.body.removeEventListener('touchend', cancelCanvasTouch)
      document.body.removeEventListener('touchmove', cancelCanvasTouch)
    }
  }, [])
  useLayoutEffect(() => {
    if (isCanvasReady) return
    canvasRef.current.width = canvasRef.current.getBoundingClientRect().width
    canvasRef.current.height = canvasRef.current.getBoundingClientRect().height
    setIsCanvasReady(true)
    setIsResizing(false)
  }, [isCanvasReady])
  useLayoutEffect(() => {
    const resize = () => {
      setIsResizing(true)
      setIsCanvasReady(false)
    }
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])
  useLayoutEffect(() => {
    if (isDrawing) return
    handleSaveContext()
  }, [handleSaveContext, isDrawing])
  useLayoutEffect(() => {
    if (isVisible && !isResizing) {
      const needResize =
        canvasRef.current.width !=
          canvasRef.current.getBoundingClientRect().width ||
        canvasRef.current.height !=
          canvasRef.current.getBoundingClientRect().height
      if (needResize) {
        canvasRef.current.width =
          canvasRef.current.getBoundingClientRect().width
        canvasRef.current.height =
          canvasRef.current.getBoundingClientRect().height
      }
      handleRestoreCanvasContext()
    }
  }, [handleRestoreCanvasContext, isResizing, isVisible])

  useLayoutEffect(() => {
    handleClearCanvasContext()
  }, [handleClearCanvasContext])
  return (
    <div className={styles.blackboard} hidden={!isVisible}>
      <canvas
        ref={canvasRef}
        className={styles.canvas}
        style={bgdStyle}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseLeave}
        onMouseOut={handleMouseLeave}
        onMouseMove={throttle(handleMouseMove, 10)}
        onTouchStart={handleTouchDown}
        onTouchEnd={handleTouchLeave}
        onTouchCancel={handleTouchLeave}
        onTouchMove={throttle(handleTouchMove, 10)}
      />
      <div className={styles.tools}>
        <ClassroomToolbar
          selectedOption={selectedTool}
          optionIdsToHide={[classroomToolbarIds.type]}
          onClick={id => {
            setTool(stateByTool[id])
            onToolChange(id)
          }}
        />
        <DownloadButton
          onClick={() =>
            handleDownload(
              canvasRef,
              backgroundType,
              numberOfLines,
              numberOfColumns
            )
          }
          disabled={canvasContext.length === 0}
        />
      </div>
    </div>
  )
}

export default ClassroomBlackboard
