import * as React from 'react'

const Star = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 39 38'
    fill={props.fill || 'none'}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.122 4.172c.749-2.304 4.007-2.304 4.756 0l1.696 5.22a5.5 5.5 0 0 0 5.23 3.801h5.49c2.422 0 3.43 3.1 1.47 4.523l-4.441 3.226a5.5 5.5 0 0 0-1.998 6.15l1.696 5.22c.748 2.304-1.888 4.219-3.847 2.795l-4.441-3.226a5.5 5.5 0 0 0-6.466 0l-4.441 3.226c-1.96 1.424-4.595-.491-3.847-2.795l1.696-5.22a5.5 5.5 0 0 0-1.998-6.15l-4.44-3.226c-1.96-1.424-.953-4.523 1.469-4.523h5.49a5.5 5.5 0 0 0 5.23-3.8l1.696-5.221Z'
      stroke={props.stroke || '#fff'}
      strokeWidth={3}
    />
  </svg>
)

export default Star
