//@ts-check
import React, { useEffect, useRef, useState } from 'react'
import { Link, Navigate, useOutletContext } from 'react-router-dom'
import DarkClassfy from 'assets/logos/main/DarkClassfy'
import ChatIcon from 'assets/icons/ChatIcon'
import { useAuth } from 'context/AuthProvider'
import useConfirmNavigation from 'hooks/useConfirmNavigation'
import useClassroom from 'hooks/useClassroom'
import ClassroomTabs, {
  classroomTabIds
} from 'components/academy/classroom/Tabs'
import { H3, Label } from 'components/typography'
import { HeaderProfile } from 'components/layout/Header'
import VideoSource from 'components/academy/classroom/VideoSource'
import EmoticonsBar from 'components/academy/classroom/EmoticonsBar'
import ClassroomBlackboard from 'components/academy/classroom/Blackboard'
import ClassroomLightbulb from 'components/academy/classroom/Lightbulb'
import ClassroomNotebook from 'components/academy/classroom/Notebook'
import ClassroomPhoto from 'components/academy/classroom/Photo'
import ClassroomVideo from 'components/academy/classroom/Video'
import ClassroomReconnecting from 'components/academy/classroom/ClassroomReconnecting'
import Modal from 'components/modals/Modal'
import Button from 'components/buttons/Button'
import Chat from 'components/chat'
import styles from './Classroom.module.css'

function Classroom() {
  const [isChatModalOpen, setIsChatModalOpen] = useState(false)
  /** @type {import('hooks/useWebRTC').WebRTCState} */
  const {
    localStream,
    selectedMedia,
    remoteStream,
    teacherData,
    activeTeacher: teacher,
    isTest,
    isWaiting,
    socket,
    identity,
    isReconnecting,
    blockNavigation
  } = useOutletContext()
  const { student } = useAuth()
  const {
    lightbulbStatus,
    isActive,
    selectedTab,
    selectedTabByTeacher,
    selectedTool,
    zoom,
    canvasBySection,
    learningVideo,
    notebookPdf,
    photo,
    photoRotationDegree,
    messages,
    handleZoomChange,
    handleTabChange,
    handleToolChange,
    handleLightbulbClick,
    handleSaveCanvasContext,
    handleClearCanvasContext,
    handleNotebookUrlChange,
    handlePdfPageChange,
    handleSendMessage,
    handleCleanNewMessage
  } = useClassroom({
    socket,
    identity,
    teacher,
    isReconnecting,
    startAsActive: isTest && isWaiting
  })
  const { blocker } = useConfirmNavigation(blockNavigation)
  const ref = useRef(null)

  const openChatModal = () => setIsChatModalOpen(true)
  const closeChatModal = () => setIsChatModalOpen(false)

  useEffect(() => {
    if (remoteStream && ref && ref.current) {
      ref.current.srcObject = remoteStream
    }
    if (!selectedMedia.audioOutputId) return
    const attachSinkId = sinkId => {
      if (typeof ref.current.sinkId !== 'undefined') {
        ref.current.setSinkId(sinkId).catch(error => {
          let errorMessage = error
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`
          }
          console.error(errorMessage)
        })
      } else {
        console.warn('Browser does not support output device selection.')
      }
    }
    attachSinkId(selectedMedia.audioOutputId)
  }, [remoteStream, selectedMedia.audioOutputId])

  const hasNewMessages = messages.some(({ isNew }) => !!isNew)

  if (!teacher || !socket) return <Navigate to='/academy' />
  return (
    <section className={styles.section}>
      <div className={styles.leftSide}>
        <Link to='/'>
          <DarkClassfy className={styles.classfyLogo} />
        </Link>
        <VideoSource
          size='large'
          ref={ref}
          disabled={!isActive}
          muted={!isActive}
        />
        <Label
          className={[
            styles.teacherName,
            !isActive ? styles.teacherNameDisabled : ''
          ].join(' ')}
        >
          {teacherData?.name?.split(' ')[0]}
        </Label>
        <div className={styles.containerChatIcon} onClick={openChatModal}>
          {hasNewMessages && <div className={styles.chatMessageAlert} />}
          <ChatIcon color='var(--error-color)' className={styles.chatIcon} />
        </div>
      </div>
      <div className={styles.innerContainer}>
        {selectedTab === classroomTabIds.none && (
          <div className={styles.reconnectingSection}>
            <ClassroomReconnecting />
          </div>
        )}
        {selectedTab !== classroomTabIds.none && (
          <ClassroomTabs
            // @ts-ignore
            teacherOption={selectedTabByTeacher}
            // @ts-ignore
            selectedOption={selectedTab}
            onClick={handleTabChange}
            disabled={selectedTab === classroomTabIds.none}
          />
        )}
        <ClassroomBlackboard
          key='blackboard'
          socket={socket}
          selectedTool={selectedTool}
          zoom={zoom}
          isActive={isActive}
          isVisible={selectedTab === classroomTabIds.blackboard}
          teacher={teacher}
          canvasContext={canvasBySection[classroomTabIds.blackboard]}
          onSaveCanvasContext={handleSaveCanvasContext}
          onClearCanvasContext={handleClearCanvasContext}
          onZoomChange={handleZoomChange}
          onToolChange={handleToolChange}
        />

        <ClassroomNotebook
          key='notebook'
          socket={socket}
          selectedTool={selectedTool}
          zoom={zoom}
          isActive={isActive}
          isVisible={selectedTab === classroomTabIds.notebook}
          teacher={teacher}
          notebookPdf={notebookPdf}
          canvasContext={canvasBySection[classroomTabIds.notebook]}
          onSaveCanvasContext={handleSaveCanvasContext}
          onClearCanvasContext={handleClearCanvasContext}
          onZoomChange={handleZoomChange}
          onToolChange={handleToolChange}
          onNotebookUrlChange={handleNotebookUrlChange}
          onPdfPageChange={handlePdfPageChange}
        />
        <ClassroomPhoto
          socket={socket}
          selectedTool={selectedTool}
          zoom={zoom}
          isActive={isActive}
          isVisible={selectedTab === classroomTabIds.photo}
          teacher={teacher}
          canvasContext={canvasBySection[classroomTabIds.photo]}
          photo={photo}
          rotationDegree={photoRotationDegree}
          onSaveCanvasContext={handleSaveCanvasContext}
          onClearCanvasContext={handleClearCanvasContext}
          onZoomChange={handleZoomChange}
          onToolChange={handleToolChange}
        />

        <ClassroomVideo
          learningVideo={learningVideo}
          isVisible={selectedTab === classroomTabIds.video}
        />
      </div>
      <div className={styles.rightSide}>
        <HeaderProfile
          student={student}
          className={styles.headerProfile}
          useGreeting={false}
        />
        <div>
          <div className={styles.lightbulbContainer}>
            <ClassroomLightbulb
              lightbulbStatus={lightbulbStatus}
              isActive={isActive}
              disabled={isReconnecting || isActive}
              onClick={handleLightbulbClick}
            />
          </div>
          <EmoticonsBar
            lightbulbStatus={lightbulbStatus}
            isActive={isActive}
            disabled={isReconnecting || isActive}
            onClick={handleLightbulbClick}
          />
        </div>
        <VideoSource
          size='small'
          color='secondary'
          stream={localStream}
          disabled={!isActive}
          muted
        />
      </div>
      {blocker.state === 'blocked' && (
        <Modal showActions={false}>
          <div className={styles.confirmNavigationModal}>
            <H3>¿Seguro que quieres salir de clase?</H3>
            <div>
              <Button
                type='secondary'
                size='tiny'
                onClick={() => blocker.reset()}
              >
                No
              </Button>
              <Button
                type='warning'
                size='tiny'
                onClick={() => blocker.proceed()}
              >
                Sí
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isChatModalOpen && (
        <Modal
          showActions={false}
          onCancel={closeChatModal}
          useFocus={false}
          showCloseIcon
        >
          <div className={styles.chatModalContainer}>
            <Chat
              messages={messages}
              onSend={handleSendMessage}
              onCleanAlert={handleCleanNewMessage}
            />
          </div>
        </Modal>
      )}
    </section>
  )
}

export default Classroom
