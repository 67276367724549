//@ts-check
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { noop } from 'utils'
import config from 'config'
import { useAuth } from 'context/AuthProvider'
import useCustomNavigate from 'hooks/useCustomNavigate'
import ChevronBottom from 'assets/icons/ChevronBottom'
import DarkClassfy from 'assets/logos/main/DarkClassfy'
import defaultImage from 'assets/images/robot.png'
import { H5 } from 'components/typography'
import styles from './Header.module.css'

export const HeaderProfile = ({
  student,
  className = '',
  useGreeting = true,
  onClick = noop
}) => (
  <div className={className} onClick={onClick}>
    {useGreeting ? (
      <>
        <p>Hola, {student?.shortName}</p>
        <img
          src={student.profileThumbnailUrl || defaultImage}
          alt='profile picture'
        />
      </>
    ) : (
      <>
        {/* <img src={fakeImg} alt='profile picture' /> */}
        <p>{student?.shortName}</p>
      </>
    )}
  </div>
)

function Header() {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const { student } = useAuth()

  return (
    <header className={styles.header}>
      <Link to='/'>
        <DarkClassfy className={styles.classfyLogo} />
      </Link>
      {config.environmentTag && <H5>{config.environmentTag}</H5>}
      <div className={styles.right} onClick={() => setShowDropdownMenu(true)}>
        <HeaderProfile className={styles.userInfo} student={student} />
        <div className={styles.menuWrapper}>
          <ChevronBottom className={styles.chevronBottom} />
          {showDropdownMenu && (
            <DropdownMenu
              onClose={e => {
                e?.stopPropagation()
                setShowDropdownMenu(false)
              }}
            />
          )}
        </div>
      </div>
    </header>
  )
}

export default Header

function DropdownMenu({ onClose }) {
  const { handleLogout } = useAuth()
  const navigate = useCustomNavigate()
  const dropdownRef = useRef(null)

  const handleClickOutside = useCallback(
    e => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])
  return (
    <div ref={dropdownRef} className={styles.dropdown}>
      <ul>
        {/* <li
          onClick={e => {
            navigate('/account')
            onClose(e)
          }}
        >
          Cuenta
        </li> */}
        <li
          onClick={e => {
            handleLogout()
            onClose(e)
          }}
        >
          Cerrar sesión
        </li>
      </ul>
    </div>
  )
}
