//@ts-check
import React, { useCallback, useRef } from 'react'
import TextInput from 'components/inputs/TextInput'
import { clear } from 'utils/canvas'
import Eraser from 'assets/icons/Eraser'
import Signature from './Signature'
import styles from './Form.module.css'

function Form({ form, onChange, getErrorPropsByRequiredField, isAnAdult }) {
  const canvas = useRef(null)
  const handleClearSignature = useCallback(() => {
    clear(canvas)
    onChange({ target: { name: 'signature', value: undefined } })
  }, [onChange])

  return (
    <section>
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.contentContainer}>
            <div className={styles.fullNameInput}>
              <TextInput
                name='fullname'
                placeholder={
                  isAnAdult ? 'Nombre completo' : 'Nombre completo tutor'
                }
                value={form.fullname}
                onChange={onChange}
                {...getErrorPropsByRequiredField('fullname')}
              />
            </div>
            <div className={styles.dniInput}>
              <TextInput
                name='dni'
                placeholder={isAnAdult ? 'DNI' : 'DNI del tutor'}
                value={form.dni}
                onChange={onChange}
                {...getErrorPropsByRequiredField('dni')}
              />
            </div>
            <div className={styles.tooltipContainer}>
              <div
                className={styles.iconWrapper}
                onClick={handleClearSignature}
              >
                <Eraser className={styles.iconEraser} />
              </div>
              <span className={styles.tooltipText}>Borrar</span>
            </div>
          </div>

          <Signature
            canvas={canvas}
            onSave={signature =>
              onChange({ target: { name: 'signature', value: signature } })
            }
          />
        </div>
      </div>
    </section>
  )
}

export default Form
