import * as React from 'react'

const Notebook = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11.04 8.832a2.207 2.207 0 1 0 0 4.416h6.623a2.208 2.208 0 1 0 0-4.416H11.04Z'
      fill='#fff'
    />
    <path
      d='M6.624 35.328H13.8a2.208 2.208 0 1 0 0-4.416H6.723a2.273 2.273 0 0 1-2.307-1.988 2.207 2.207 0 0 1 2.208-2.428h11.592a2.208 2.208 0 0 0 0-4.416H6.624a6.58 6.58 0 0 0-2.208.386V6.624a2.209 2.209 0 0 1 2.208-2.208h17.664v11.04a2.209 2.209 0 0 0 4.416 0V2.208A2.208 2.208 0 0 0 26.496 0H6.624A6.622 6.622 0 0 0 0 6.624v22.08a6.622 6.622 0 0 0 6.624 6.624ZM31.646 19.921a3.225 3.225 0 0 0-1.706.922l-8.777 8.777a4.416 4.416 0 0 0-1.291 3.12v.932a1.658 1.658 0 0 0 1.656 1.656h.933a4.415 4.415 0 0 0 3.124-1.292l8.771-8.777a3.22 3.22 0 0 0 .922-1.705 3.123 3.123 0 0 0-3.632-3.633Z'
      fill='#fff'
    />
  </svg>
)

export default Notebook
