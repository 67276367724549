import * as React from 'react'
const Sign = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 67 71'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fill={props.color || '#8080F8'}
      d='M50.126 0H14.16c-.043 0-.082.021-.125.025-.112.01-.22.03-.326.065-.057.018-.112.033-.167.059-.152.07-.296.158-.42.28h-.002c-.002.003-.004.003-.006.005L.424 13.11c-.128.128-.22.28-.29.44-.015.032-.023.064-.035.096a1.452 1.452 0 0 0-.089.446c0 .018-.01.035-.01.053V69.11c0 .813.658 1.47 1.47 1.47h48.657a1.47 1.47 0 0 0 1.47-1.47V54.417c.163-.27.322-.519.49-.807 2.067-3.525 4.222-8.027 6.09-12.668.052-.084.11-.164.146-.26.02-.055.012-.11.025-.164.317-.796.627-1.594.924-2.392 1.278-3.441 2.262-6.623 2.92-9.42a4.653 4.653 0 0 1 .984 4.822L59.39 43.71a1.47 1.47 0 0 0 2.757 1.025l3.786-10.183c1.275-3.436-.088-7.194-3.103-9.079.07-.452.132-.892.179-1.313.465-4.166-.27-6.64-2.183-7.35-1.916-.708-4.084.68-6.453 4.14-.913 1.334-1.846 2.965-2.777 4.825l.001-24.305A1.47 1.47 0 0 0 50.127 0h-.001ZM12.684 5.024v7.656H5.019l7.665-7.656ZM48.656 67.64H2.941V15.62h11.214a1.47 1.47 0 0 0 1.47-1.47V2.942h33.03v29.53c-.191.492-.383.985-.572 1.495-.297.8-.584 1.608-.865 2.419-.024.048-.065.083-.084.136-.027.07-.02.142-.034.213-1.63 4.76-2.948 9.609-3.69 13.65-.863 4.702-.874 7.562.02 9.01l-1.12 3.019a1.47 1.47 0 1 0 2.756 1.024l1.124-3.027c.759-.24 1.572-.859 2.466-1.852v9.081Zm-2.89-10.305c-.41-2.197.75-9.483 3.686-18.383l5.527 2.055c-3.589 8.655-7.469 14.93-9.213 16.327Zm14.003-37.786c.784.647 1.053 5.965-3.253 17.552-.148.397-.299.783-.448 1.172l-5.654-2.102c.141-.392.28-.783.426-1.18 4.305-11.585 7.983-15.437 8.93-15.442Z'
    />
    <path
      fill={props.color || '#8080F8'}
      d='M9.398 26.132h32.793a1.47 1.47 0 1 0 0-2.94H9.398a1.47 1.47 0 1 0 0 2.94ZM9.398 34.488h32.793a1.47 1.47 0 1 0 0-2.94l-32.793-.001a1.47 1.47 0 1 0 0 2.94ZM43.661 41.373a1.47 1.47 0 0 0-1.47-1.47H9.398a1.47 1.47 0 1 0 0 2.94h32.793c.813 0 1.47-.658 1.47-1.47ZM29.241 49.736a1.47 1.47 0 0 0-1.47-1.47H9.398a1.47 1.47 0 1 0 0 2.94H27.77a1.47 1.47 0 0 0 1.47-1.47ZM36.514 58.989c-.961-.431-2.17-.367-3.088.166-.896.523-1.453 1.29-1.944 1.968-.138.192-.277.384-.424.566-.422.525-.848.727-.976.694-.107-.03-.342-.21-.497-.328l-3.255-2.49c-2.288-1.75-4.236-2.108-5.787-1.062-.452.304-.768.68-1.022.983-.145.173-.283.337-.39.42-.54.414-1.764-.144-2.733-.87-.385-.29-1.288-.966-2.417-.64-.742.213-1.172.774-1.376 1.04l-1.803 2.337a1.47 1.47 0 0 0 2.33 1.795l1.616-2.096c2.336 1.706 4.578 1.987 6.173.766.363-.279.634-.602.853-.862.15-.178.303-.362.414-.435.274-.19 1.048-.042 2.356.959l3.255 2.49c.354.27.836.639 1.481.822.272.077.548.115.828.115 1.142 0 2.308-.634 3.243-1.795.177-.22.346-.452.514-.684.352-.485.684-.944 1.042-1.154a.59.59 0 0 1 .31-.047 1.47 1.47 0 0 0 2.822-.798c-.166-.82-.721-1.499-1.525-1.86Z'
    />
  </svg>
)
export default Sign
