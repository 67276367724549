//@ts-check
import React, { useMemo } from 'react'
import { isEmpty } from 'ramda'
import { SubscriptionCodeLabels, SubscriptionCodes } from 'utils/constants'
import { noop } from 'utils'
import { H4, Paragraph } from 'components/typography'
import Button from 'components/buttons/Button'
import Vector from 'assets/icons/Vector'
import styles from './SubscriptionCard.module.css'

function SubscriptionCard({ subscription = {}, onClick = noop }) {
  const { title, isPack, subjectsLimit, hours, total } = useMemo(() => {
    if (!subscription || isEmpty(subscription)) return {}
    const {
      selectedSubjectIds,
      subscriptionName,
      subscriptionType,
      subscriptionHours,
      subscriptionCode,
      paymentAmount
    } = subscription || {}
    const isPack = subscriptionCode === SubscriptionCodes.PACK
    return {
      title: SubscriptionCodeLabels[subscriptionCode] || '??',
      isPack,
      subjectsLimit: selectedSubjectIds?.length,
      hours: Number(subscriptionHours),
      total: [paymentAmount, isPack ? '€' : '€/mes'].join('')
    }
  }, [subscription])

  return (
    <div className={styles.card}>
      <H4 className={styles.title}>{title}</H4>
      <div className={styles.textInfo}>
        <Paragraph type='body2'>{subjectsLimit} asignaturas</Paragraph>
        <Paragraph type='body2'>{hours} horas</Paragraph>
        {!isPack && <Paragraph type='body2'>Duración mensual</Paragraph>}
      </div>
      <Vector className={styles.vector} color='var(--seadapted)' />
      <Paragraph className={styles.total}>{total}</Paragraph>
      <Button size='small' label='Gestionar' onClick={onClick} />
    </div>
  )
}

export default SubscriptionCard
