// @ts-check
import React, { useCallback, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNotificationActions } from 'context/NotificationProvider'
import { useAuth } from 'context/AuthProvider'
import { updateProfile, uploadProfilePicture } from 'api/account'
import { dateTimeToString } from 'utils/date'
import Pencil from 'assets/icons/Pencil'
import defaultImg from 'assets/images/robot.png'
import useFormManager from 'hooks/useFormManager'
import useCustomNavigate from 'hooks/useCustomNavigate'
import { H2, H3, Label, Paragraph } from 'components/typography'
import TextInput from 'components/inputs/TextInput'
import DatePicker from 'components/inputs/DatePicker'
import Button from 'components/buttons/Button'
import PhoneInput from 'components/inputs/PhoneInput'
import AcademicBackgroundSelect from 'components/selects/AcademicBackgroundSelect'
import Modal from 'components/modals/Modal'
import styles from './PersonalData.module.css'

const REQUIRED_FIELDS = [
  'name',
  'email',
  'dni',
  'phoneNumber.cleanedPhone',
  'dateOfBirth'
]

function PersonalData() {
  const [isSaving, setIsSaving] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [preview, setPreview] = useState(null)
  const [isSavingPhoto, setIsSavingPhoto] = useState(false)
  const { student, setStudent } = useAuth()
  const {
    handleFormChange,
    handlePhoneNumberChange,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields,
    form
  } = useFormManager(REQUIRED_FIELDS, student)
  const navigate = useCustomNavigate()
  const { setSuccessMessage, setErrorMessage } = useNotificationActions()

  const inputRef = useRef(null)

  const openModal = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
    setPreview(null)
  }
  const handleSave = useCallback(() => {
    setIsSaving(true)
    updateProfile(form)
      .then(updatedStudent => {
        setSuccessMessage({ title: 'Perfil actualizado' })
        setStudent(updatedStudent)
        navigate('/account')
      })
      .catch(e => {
        setErrorMessage({ message: e.message })
        console.error('Error updating profile: ', e)
      })
      .finally(() => setIsSaving(false))
  }, [form, navigate, setErrorMessage, setStudent, setSuccessMessage])

  const handleFileChange = event => {
    const file = event.target?.files[0]
    if (!file) return
    setSelectedFile(file)
    setPreview(URL.createObjectURL(file))
  }

  const handleSavingPhoto = useCallback(() => {
    setIsSavingPhoto(true)
    uploadProfilePicture({
      photo: selectedFile
    })
      .then(updatedStudent => {
        setSuccessMessage({
          title: 'Imagen subida',
          message: 'Imagen subida correctamente'
        })
        setStudent(updatedStudent)
        setSelectedFile(null)
        closeModal()
      })
      .catch(e => {
        setErrorMessage({ message: e.message })
        console.error('Error saving photo: ', e)
      })
      .finally(() => {
        setIsSavingPhoto(false)
      })
  }, [selectedFile, setSuccessMessage, setStudent, setErrorMessage])

  return (
    <section className={styles.section}>
      <H2>Datos personales 🤙</H2>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <div className={styles.containerProfileImg}>
            {student.profileUrl ? (
              <img
                src={student.profileUrl}
                alt='Foto de perfil'
                className={styles.profileImg}
              />
            ) : (
              <img
                src={defaultImg}
                alt='Foto por defecto'
                className={styles.defaultProfileImage}
              />
            )}
            <div className={styles.pencilIconContainer} onClick={openModal}>
              <Pencil
                className={styles.pencilIcon}
                color='var(--sandground)'
                stroke='var(--sandground)'
              />
            </div>
          </div>
          <Link to='/account/change-password'>
            <Label className={styles.passwordTitle}>
              Cambiar de contraseña
            </Label>
          </Link>

          <TextInput
            name='name'
            placeholder='Nombre completo'
            value={form.name}
            onChange={handleFormChange}
            {...getErrorPropsByRequiredField('name')}
          />
          <TextInput
            name='email'
            placeholder='Correo electrónico'
            value={form.email}
            onChange={handleFormChange}
            {...getErrorPropsByRequiredField('email')}
          />
          <div className={styles.personalInfoContainer}>
            <TextInput
              name='dni'
              placeholder='DNI'
              value={form.dni}
              onChange={handleFormChange}
              {...getErrorPropsByRequiredField('dni')}
            />
            <TextInput
              name='postalCode'
              placeholder='Código postal'
              value={form.postalCode}
              onChange={handleFormChange}
            />
          </div>
          <div className={styles.contactInfoContainer}>
            <div className={styles.phoneInput}>
              <PhoneInput
                value={form.phoneNumber?.fullPhoneNumber}
                onPhoneNumberChange={handlePhoneNumberChange}
                {...getErrorPropsByRequiredField('phoneNumber.cleanedPhone')}
              />
            </div>
            <div className={styles.datePicker}>
              <DatePicker
                name='dateOfBirth'
                placeholder='Fecha de nacimiento'
                value={form.dateOfBirth}
                onChange={handleFormChange}
                {...getErrorPropsByRequiredField('dateOfBirth')}
              />
            </div>
          </div>
          <div className={styles.personalInfoContainer}>
            <AcademicBackgroundSelect
              customStyles={{ container: styles.academicBackground }}
              name='academicBackground'
              placeholder='Curso'
              value={form.academicBackground}
              showLabel={false}
              onChange={handleFormChange}
            />
            <div className={styles.enrollmentContianer}>
              {form.enrolledUntil ? (
                <Label
                  className={`${styles['enrollment']} ${
                    new Date() >= new Date(form.enrolledUntil)
                      ? styles['enrollmentAlert']
                      : ''
                  }`}
                >
                  {`Fin matrícula:   ${dateTimeToString(form.enrolledUntil)}`}
                </Label>
              ) : (
                <Label className={styles.enrollment}>
                  Sin fecha de fin de matrícula
                </Label>
              )}
            </div>
          </div>
          <div className={styles.formActions}>
            <Link to='/account'>
              <Button label='Cancelar' size='small' type='secondary' />
            </Link>
            <Button
              label='Guardar'
              size='small'
              loading={isSaving}
              disabled={hasPendingRequiredFields || isSaving}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
      {showModal && (
        <Modal showActions={false} onCancel={closeModal} showCloseIcon>
          <div className={styles.modalContainer}>
            <H3>Imagen de perfil 🤳</H3>
            <Paragraph>
              !Dale a tu perfil un toque personal! Agrega tu foto de perfil con
              tan solo un click.
            </Paragraph>
            <div className={styles.profileImageContainer}>
              {preview || student.profileUrl ? (
                <div className={styles.profileImageWrapper}>
                  <img
                    src={preview || student.profileUrl}
                    alt='Foto de perfil'
                    className={styles.profileImg}
                  />
                </div>
              ) : (
                <div className={styles.defaultImageWrapper}>
                  <img
                    src={defaultImg}
                    alt='Foto por defecto'
                    className={styles.defaultProfileImage}
                  />
                </div>
              )}
            </div>
            <div className={styles.modalActions}>
              <Button
                size='tiny'
                type='secondary'
                onClick={() => inputRef.current.click()}
              >
                Seleccionar
                <input
                  ref={inputRef}
                  type='file'
                  accept='image/*'
                  name='photo'
                  onChange={handleFileChange}
                  capture
                  hidden
                />
              </Button>
              <Button
                label='Subir'
                size='tiny'
                type='primary'
                disabled={!selectedFile || isSavingPhoto}
                loading={isSavingPhoto}
                onClick={handleSavingPhoto}
              />
            </div>
          </div>
        </Modal>
      )}
    </section>
  )
}

export default PersonalData
