import React from 'react'
import styles from './RingsSpinner.module.css'

/**
 * @typedef RingsSpinnerProps
 * @property {React.CSSProperties} style
 */

/** @param {Partial<RingsSpinnerProps>} props */
function RingsSpinner({ style = {} }) {
  return <div className={styles.spinner} style={style} />
}

export default RingsSpinner
