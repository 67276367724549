import * as React from 'react'

const ChevronLeft = props => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g opacity={props.disabled ? 0.24 : undefined}>
      <path
        d='m12.414 15 6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 1 1 1.414 1.414L12.414 15Z'
        fill='#000'
      />
      <mask
        id='ChevronLeft'
        style={{
          maskType: 'alpha'
        }}
        maskUnits='userSpaceOnUse'
        x={10}
        y={7}
        width={9}
        height={16}
      >
        <path
          d='m12.414 15 6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 1 1 1.414 1.414L12.414 15Z'
          fill='#fff'
        />
      </mask>
      <g mask='url(#ChevronLeft)'>
        <path fill='#8080F8' d='M0 0h30v30H0z' />
      </g>
    </g>
  </svg>
)

export default ChevronLeft
