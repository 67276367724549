//@ts-check
import React from 'react'
import Select from '../Select'

export const AcademicBackgroundOptions = {
  PRIMARIA: 'Primaria',
  SECUNDARIA: 'Secundaria',
  BACHILLERATO: 'Bachillerato',
  SELECTIVIDAD_EVAU: 'Selectividad/EvAu',
  PCE: 'PCE (EvAu para extranjeros)',
  UNIVERSIDAD: 'Universidad',
  ACCESO_MAYORES_25: 'Acceso mayores 25 años',
  ACCESO_MAYORES_45: 'Acceso mayores 45 años',
  ACCESO_GRADO_MEDIO: 'Acceso grado medio',
  ACCESO_GRADO_SUPERIOR: 'Acceso grado superior',
  MODULO_GRADO_MEDIO: 'Módulo grado medio',
  MODULO_GRADO_SUPERIOR: 'Módulo grado superior',
  IDIOMAS: 'Idiomas',
  OTROS: 'Otros'
}
const Options = Object.values(AcademicBackgroundOptions)

/** @param {Partial<import('../Select').SelectProps>} props */
function AcademicBackgroundSelect({
  name = 'academicBackground',
  label = 'Nivel educativo',
  placeholder = 'Nivel educativo',
  options = Options,
  ...rest
}) {
  return (
    <Select
      {...rest}
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      transformList
    />
  )
}
export default AcademicBackgroundSelect
