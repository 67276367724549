//@ts-check
import React, { useEffect, useState } from 'react'
import { H2, Paragraph } from 'components/typography'
import AgoraProposal from 'components/cards/subscriptions/AgoraProposal'
import RingsSpinner from 'components/spinners/RingsSpinner'
import { findAgoraProposals } from 'api/budgets'
import { useNotificationActions } from 'context/NotificationProvider'
import useCustomNavigate from 'hooks/useCustomNavigate'
import styles from './Proposals.module.css'

function Proposals() {
  const navigate = useCustomNavigate()
  const [proposals, setProposals] = useState([])
  const [loading, setLoading] = useState(true)
  const { setErrorMessage } = useNotificationActions()

  useEffect(() => {
    findAgoraProposals({})
      .then(response => {
        setProposals(response)
        setLoading(false)
      })
      .catch(error => {
        setErrorMessage({ message: error.message })
        console.error('Error al obtener las propuestas:', error)
      })
      .finally(() => setLoading(false))
  }, [setErrorMessage])

  const handleButtonClick = budgetId => {
    navigate(budgetId)
  }
  return (
    <section className={styles.section}>
      <H2> Propuestas de suscripción 📃</H2>
      {loading ? (
        <RingsSpinner />
      ) : (
        <div className={styles.container}>
          {proposals.length === 0 ? (
            <Paragraph>No hay propuestas disponibles</Paragraph>
          ) : (
            proposals.map(budget => (
              <AgoraProposal
                key={budget.id}
                budget={budget}
                onClick={() => handleButtonClick(budget.id)}
              />
            ))
          )}
        </div>
      )}
    </section>
  )
}

export default Proposals
