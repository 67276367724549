//@ts-check
import React, { useCallback, useMemo, useState } from 'react'
import { isSameDay } from 'date-fns'
import ChevronLeft from 'assets/icons/ChevronLeft'
import ChevronRight from 'assets/icons/ChevronRight'
import { AttendanceStatusLabels, AttendanceTypeLabels } from 'utils/constants'
import {
  DAY_NAME_BY_NUMBER,
  formatDate,
  getMonthDaysInfo,
  toTimeString
} from 'utils/date'
import styles from './AttendanceCalendar.module.css'

const MAX_VISIBLE_LENGTH = 5
function getVisibleSubjectName(name = '-') {
  if (name.length < MAX_VISIBLE_LENGTH) return name
  return name.substring(0, MAX_VISIBLE_LENGTH) + '...'
}

function AttendanceCalendar({ attendances = [], disabled = false, onEdit }) {
  const [state, setState] = useState({
    selectedYear: new Date().getFullYear(),
    selectedMonth: new Date().getMonth()
  })

  const { selectedYear, selectedMonth } = state

  const weeks = useMemo(
    () => getMonthDaysInfo(selectedYear, selectedMonth),
    [selectedYear, selectedMonth]
  )

  const handleChangeMonth = useCallback(
    amount => {
      let newYear = selectedYear
      let newMonth = selectedMonth + amount
      if (newMonth === 12) {
        newMonth = 0
        newYear++
      } else if (newMonth === -1) {
        newMonth = 11
        newYear--
      }
      setState(state => ({
        ...state,
        selectedYear: newYear,
        selectedMonth: newMonth
      }))
    },
    [selectedMonth, selectedYear]
  )

  return (
    <div className={styles.container}>
      <div>
        <Actions
          label={formatDate({
            date: new Date(selectedYear, selectedMonth),
            stringFormat: 'MMMM, yyyy'
          })}
          disabled={disabled}
          onChange={handleChangeMonth}
        />
        <div className={styles.calendarWrapper}>
          <table className={styles.calendar}>
            <thead>
              <tr>
                {DAY_NAME_BY_NUMBER.map(({ label, day }) => {
                  return (
                    <th key={day} className={styles.calendarHeader}>
                      {label.substring(0, 3)}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {weeks.map((week, i) => (
                <WeekRow
                  onEdit={onEdit}
                  key={i}
                  week={week}
                  selectedMonth={selectedMonth}
                  attendances={attendances}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AttendanceCalendar

function Actions({ label, disabled, onChange }) {
  return (
    <div className={styles.actions}>
      <ChevronLeft
        className={[
          styles.chevron,
          disabled ? styles.chevronDisabled : ''
        ].join(' ')}
        onClick={() => onChange(-1)}
      />
      <p className={styles.title}>{label}</p>
      <ChevronRight
        className={[
          styles.chevron,
          disabled ? styles.chevronDisabled : ''
        ].join(' ')}
        onClick={() => onChange(1)}
      />
    </div>
  )
}

function WeekRow({ week, selectedMonth, attendances, onEdit }) {
  return (
    <tr className={styles.calendarWeek}>
      {week.map((day, i) => (
        <DayCell
          onEdit={onEdit}
          key={i}
          day={day}
          selectedMonth={selectedMonth}
          attendances={attendances}
        />
      ))}
    </tr>
  )
}

function DayCell({ day, selectedMonth, attendances, onEdit }) {
  let dayClassName = [styles.dateIdentifier]
  let cellClassName = [styles.calendarDay]
  if (day.getMonth() === selectedMonth) {
    dayClassName.push(styles.dateIdentifierActive)
    cellClassName.push(styles.calendarCurrenteMonth)
  }
  if (isSameDay(new Date(), day)) {
    dayClassName.push(styles.dateIdentifierToday)
    cellClassName.push(styles.calendarDayToday)
  }
  return (
    <td className={cellClassName.join(' ')}>
      <div className={dayClassName.join(' ')}>{day.getDate()}</div>

      {attendances.map((attendance, i) => {
        if (isSameDay(new Date(attendance.classStart), day)) {
          return (
            <div
              key={i}
              className={[
                styles.attendanceWrapper,
                styles[`--${attendance.type}`]
              ].join(' ')}
              onClick={() => onEdit(attendance.id)}
            >
              <div className={styles.attendanceInfo}>
                <Tooltip title={AttendanceStatusLabels[attendance.status]}>
                  <span
                    className={[
                      styles.attendanceCircle,
                      styles[`--${attendance.status}`]
                    ].join(' ')}
                  />
                </Tooltip>
                <Tooltip
                  title={`${attendance.subjectName || '-'} (${
                    attendance.subjectLevel || '-'
                  })`}
                >
                  <span>{getVisibleSubjectName(attendance.subjectName)}</span>
                </Tooltip>
                <Tooltip
                  title={<span>{AttendanceTypeLabels[attendance.type]}</span>}
                >
                  <span>
                    {toTimeString(attendance.classStart)} -{' '}
                    {toTimeString(attendance.classEnd)}
                  </span>
                </Tooltip>
              </div>
            </div>
          )
        }
        return null
      })}
    </td>
  )
}

function Tooltip({ title, children = null }) {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }
  return (
    <div className={styles.tooltipContainer}>
      <div
        className={styles.tooltipTrigger}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {showTooltip && <div className={styles.tooltipTitle}>{title}</div>}
    </div>
  )
}
