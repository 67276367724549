//@ts-check
import React from 'react'
import { dateToString } from 'utils/date'
import classfySignature from 'assets/images/serviceTerms/classfySignature.jpg'
import fakeSignature from 'assets/images/serviceTerms/fakeSignature.jpg'
import { H2, H4 } from 'components/typography'
import DarkClassfy from 'assets/logos/main/DarkClassfy'
import styles from './Conditions.module.css'

class Conditions extends React.Component {
  render() {
    const {
      showDecorations = true,
      studentSignature,
      tutorSignature,
      studentName,
      signedAt
    } = this.props
    return (
      <div>
        <div className={showDecorations ? styles.page1 : styles.pagePreview}>
          {showDecorations && (
            <>
              <div className={styles.containerLogo}>
                <DarkClassfy className={styles.classfyLogo} />
              </div>
              <H2 className={styles.mainTitle}>
                CONDICIONES GENERALES DE CONTRATACIÓN
              </H2>
            </>
          )}
          <H2
            className={
              showDecorations ? styles.floatingSubtitle : styles.floatingTitle
            }
          >
            {showDecorations ? 'Clases online' : 'Condiciones de contratación'}
          </H2>
          <div
            className={
              showDecorations ? styles.mainContent : styles.mainContentPreview
            }
          >
            <H4 className={styles.listTitle}>
              <strong>Matrícula:</strong>
            </H4>

            <ul className={styles.list}>
              <li className={styles.listItem}>
                El pago de la matrícula, obligatorio y no reembolsable, asciende
                a 45€ y debe realizarse el primer día de clases.
              </li>

              <li className={styles.listItem}>
                La matrícula caducará a los 2 meses desde la última clase
                recibida. No se devolverá su importe bajo ningún concepto.
              </li>
            </ul>

            <H4 className={styles.listTitle}>
              <strong>Horario:</strong>
            </H4>

            <ul className={styles.list}>
              <li className={styles.listItem}>
                La flexibilidad horaria permite a los alumnos acordar el horario
                de clases con el centro.
              </li>

              <li className={styles.listItem}>
                Para algunas modalidades de contratación, existen limitaciones
                específicas de horario:
                <ul className={styles.subList}>
                  <li className={styles.subListItem}>
                    La Tarifa Prepara Mañanas solo permite clases en horario de
                    mañanas.
                  </li>

                  <li className={styles.subListItem}>
                    La Tarifa Prepara Tardes solo permite clases en horario de
                    tardes.
                  </li>
                </ul>
              </li>

              <li className={styles.listItem}>
                Consulta nuestra política de aperturas y posibles cambios de
                horario en festivos locales o nacionales.
              </li>
            </ul>

            <H4 className={styles.listTitle}>
              <strong>En el aula:</strong>
            </H4>

            <ul className={styles.list}>
              <li className={styles.listItem}>
                Se requiere que el alumno se presente con folios en blanco y
                bolígrafo para las clases. En el caso de asignaturas de ciencias
                se solicita también calculadora.
              </li>

              <li className={styles.listItem}>
                El alumno debe seguir las indicaciones del docente y mantener un
                comportamiento respetuoso en todo momento. Conductas disruptivas
                pueden llevar a medidas disciplinarias, incluida la suspensión
                del servicio.
              </li>
            </ul>

            <H4 className={styles.listTitle}>
              <strong>Política de Cambios:</strong>
            </H4>

            <p className={styles.textDetails}>
              En caso de cambios o ausencias, el alumno debe notificar al centro
              con al menos 1 día laborable de antelación.
            </p>

            <p className={styles.textDetails}>
              En caso de avisar en el plazo establecido:
            </p>

            <ul className={styles.list}>
              <li className={styles.listItem}>
                <strong>Tarifa Refuerza:</strong>

                <ul className={styles.subList}>
                  <li className={styles.subListItem}>
                    El alumno tendrá derecho a cambiar horas abonando 2,5€ por
                    hora, sujeto a disponibilidad en el aula.
                  </li>

                  <li className={styles.subListItem}>
                    Si no hay disponibilidad en el aula habitual, las clases
                    podrán recuperarse en otro centro, coordinando previamente.
                  </li>
                </ul>
              </li>

              <li className={styles.listItem}>
                <strong>Tarifa Base / Pack horas:</strong>

                <ul className={styles.subList}>
                  <li className={styles.subListItem}>
                    Podrá mover horas a otra fecha y horario, sujeto a
                    disponibilidad de aula.
                  </li>

                  <li className={styles.subListItem}>
                    Si no hay disponibilidad en el aula habitual, las clases
                    podrán recuperarse en otro centro, coordinando previamente
                  </li>
                </ul>
              </li>
            </ul>

            <p className={styles.textDetails}>
              Fuera del plazo establecido o sin previo aviso:
            </p>

            <ul className={styles.list}>
              <li className={styles.listItem}>
                <strong>Tarifa Refuerza:</strong>

                <ul className={styles.subList}>
                  <li className={styles.subListItem}>
                    El alumno perderá las horas sin posibilidad de recuperación.
                  </li>
                </ul>
              </li>

              <li className={styles.listItem}>
                <strong>Tarifa Base / Pack horas:</strong>

                <ul className={styles.subList}>
                  <li className={styles.subListItem}>
                    El alumno perderá las horas contratadas sin posibilidad de
                    cambios ni reembolsos.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className={showDecorations ? styles.page2 : styles.pagePreview}>
          <div
            className={
              showDecorations ? styles.mainContent2 : styles.mainContent2Preview
            }
          >
            <H4 className={styles.listTitle}>
              <strong>Política de Pagos y Devoluciones:</strong>
            </H4>

            <ul className={styles.list}>
              <li className={styles.listItem}>
                Todos los servicios deben ser abonados por adelantado. El centro
                se reserva el derecho de no permitir el acceso a clases si los
                servicios contratados no han sido abonados.
              </li>

              <li className={styles.listItem}>
                Si el servicio contratado es una Tarifa, la fecha para realizar
                el pago mensual es hasta el día 5 del mes que se ofrece el
                servicio.
                <ul className={styles.subList}>
                  <li className={styles.subListItem}>
                    Las tarifas se renuevan automáticamente de forma mensual.
                    Para darse de baja se deberá notificar dicha baja con 5 días
                    de antelación; de lo contrario, se abonará el mes completo.
                  </li>
                </ul>
              </li>
              <li className={styles.listItem}>
                Si el servicio contratado es un Packs de horas que se oferta a
                lo largo del año, el pago se realizará el primer día de
                asistencia a clase.
              </li>
              <li className={styles.listItem}>
                Importes realizados a cuenta no son reembolsables. Se otorgará
                un bono por horas pagadas, válido en el siguiente mes, solo para
                el cliente original.
              </li>
            </ul>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                Las clases presenciales canceladas se podrán ofrecer en
                modalidad online, sin derecho a reembolso, tras coordinación con
                el alumno.
              </li>
            </ul>

            <H4 className={styles.listTitle}>
              <strong>Periodo de Desistimiento:</strong>
            </H4>

            <p className={styles.textDetails}>
              El cliente dispone de 14 días naturales para ejercer el derecho de
              desistimiento.
            </p>

            <ul className={styles.list}>
              <li className={styles.listItem}>
                En caso de desistimiento, las clases ya disfrutadas se
                facturarán a 20€ por hora, según los servicios prestados.
              </li>

              <li className={styles.listItem}>
                La matrícula, siendo un cargo no reembolsable, no está sujeta a
                devolución en ninguna circunstancia
              </li>
            </ul>
            <div className={styles.lineDivider}></div>

            <p className={styles.textDetails}>
              Las clases impartidas no conducen a la obtención de un título con
              valor oficial.
            </p>

            <p className={styles.textDetails}>
              Mediante el pago de la matrícula o bono, el cliente manifiesta
              entender y aceptar estas condiciones.
            </p>

            {showDecorations && (
              <>
                <p className={styles.signature}>
                  Firmado en MADRID con fecha
                  <strong> {dateToString(signedAt)}</strong>
                </p>

                <div className={styles.signArea}>
                  <div className={styles.signAreaSection}>
                    <p>{studentName}</p>
                    <img src={studentSignature || fakeSignature} />
                  </div>

                  <div className={styles.signAreaSection}>
                    <p>El representante legal</p>
                    <img src={tutorSignature || fakeSignature} />
                  </div>

                  <div className={styles.signAreaSection}>
                    <p>Classfy</p>
                    <img src={classfySignature} />
                  </div>
                </div>
                <div className={styles.footer}>
                  <p>
                    SERVICIOS ESTUDIANTILES INTEGRALES S.L.. con domicilio
                    fiscal en Avenida de Bonn 12 Bajo 4
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Conditions
