import { GET, POST, PUT } from './index'
export const findTickets = () => GET({ URL: 'students/ticketing' })
export const getTicket = id => GET({ URL: `students/ticketing/${id}` })
export const createTicket = (data = {}) =>
  POST({
    url: 'students/ticketing',
    data
  })
export const createSpecialRequestTicket = (data = {}) =>
  POST({
    url: 'students/ticketing/create-special-request',
    data
  })
export const addTicketMessage = (id, data = {}) =>
  PUT({ url: `students/ticketing/${id}`, data })
