import React from 'react'
import RateEditor from 'components/subscriptions/RateEditor'

/**
 * TODO
 * - Hay que añadir un endpoint que reciba el compromiso del alumno y pedir
 * a ágora una propuesta
 * - ¿Qué restriciones en cuanto a horas del Slider debemos poner?
 * - Hay que revisar todo el código de manejo de asistencias ya que las antiguas tarifas ya no aplican
 */
function Create() {
  return <RateEditor />
}

export default Create
